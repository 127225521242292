<template>
  <ul class="new-shipment-quotation-actions tw-flex tw-flex-col md:tw-flex-row md:tw-items-center md:tw-justify-between tw-list-none tw-m-0 tw-p-0 tw-py-3">
    <li
      v-if="getQuotation"
      class="new-shipment-quotation-actions__carbon-footprint tw-pb-2 md:tw-pb-0 tw-flex tw-items-center tw-text-sm"
      data-test="carbon-footprint"
    >
      <img
        src="@/assets/img/icons/co2.svg"
        alt=""
        width="20"
        height="20"
        class="tw-mr-2"
        data-test="icon"
      >
      <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-items-center">
        <div
          v-text="$t('new-shipment.labels.carbon_footprint')"
          class="tw-mr-1 tw-text-secondary-text"
          data-test="label"
        />
        <div class="tw-flex tw-items-center">
          <div
            v-text="$t('shipment.values.co2', {
              weight: co2
            })"
            class="tw-font-medium tw-text-secondary-lighten"
            data-test="value"
          />
          <ui-material-icon
            v-if="tooltip"
            v-b-tooltip.hover.html="tooltip"
            name="info"
            class="tw-text-base tw-align-middle tw-ml-1 tw-text-secondary-text"
            @click.native="click"
            data-test="tooltip"
          />
        </div>
      </div>
    </li>
    <li
      v-if="getQuotation"
      class="new-shipment-quotation-actions__item tw-self-end md:tw-self-center tw-pt-2 md:tw-pt-0"
    >
      <inline-file-download
        :url="fileUrl"
        data-test="download-quotation"
        @file="fileGenerated"
      >
        <template
          slot-scope="{ loading, download, error }"
        >
          <ui-button
            :disabled="loading"
            :loading="loading"
            :class="{
              'tw-cursor-wait': loading
            }"
            :title="$t('new-shipment.buttons.quotation.download')"
            id="download-quotation"
            href="#"
            type="button"
            variant="link"
            class="tw-relative tw-flex tw-items-center tw-italic"
            data-test="download-quotation-link"
            v-matomo="{
              click: {
                category: 'Quotations',
                action: 'Download Quote'
              }
            }"
            @click.prevent="download"
          >
            <transition
              name="fade"
            >
              <div
                v-if="error"
                class="tw-absolute tw-flex tw-items-center tw-left-0"
                data-test="download-quotation-error"
              >
                <i
                  id="download-file-warning"
                  class="tw-text-red-500 tw-text-3xl icon-ctk-warning"
                  aria-hidden="true"
                  data-test="download-quotation-error-icon"
                />

                <b-tooltip
                  target="download-file-warning"
                >
                  {{ $t('app.paragraphs.error_while_downloading_file') }}
                </b-tooltip>
              </div>
            </transition>

            <span
              v-text="$t('new-shipment.buttons.quotation.download')"
              data-test="download-quotation-content"
            />
          </ui-button>

          <!-- Download popover -->
          <b-popover
            v-if="popoverVisible"
            ref="popover"
            :target="'download-quotation'"
            :show.sync="popoverVisible"
            :disabled="true"
            placement="bottom"
          >
            <template #title>
              <div
                class="ctk-btn-file tw-flex tw-items-center"
              >
                <span
                  class="tw-font-normal tw-mr-2"
                  v-text="$t('new-shipment.titles.quotation.quotation_pdf')"
                />
                <ui-button
                  :title="$t('close') | capitalize"
                  class="close-popover"
                  variant="link"
                  size="sm"
                  @click="popoverVisible = false"
                >
                  <template #left-icon>
                    <ui-ctk-icon
                      name="close"
                    />
                  </template>
                </ui-button>
              </div>
            </template>

            <div class="tw-flex">
              <ui-button
                v-if="filePath"
                id="download-button"
                :href="filePath"
                download
                class="tw-w-full"
                variant="info"
                size="sm"
                target="_blank"
              >
                {{ $t('app.buttons.download') | capitalize }}
              </ui-button>
            </div>
          </b-popover>
        </template>
      </inline-file-download>
    </li>
  </ul>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent, computed, ref } from '@vue/composition-api'

  import InlineFileDownload from '@/components/InlineFileDownload/index.vue'

  import useTooltipDialog from '@/composables/useTooltipDialog'
  import { i18n } from '@/locales'

  /**
   * @module component - NewShipmentQuotationActions
   */
  export default defineComponent({
    name: 'NewShipmentQuotationActions',
    components: {
      InlineFileDownload
    },
    setup () {
      const popoverVisible = ref(false)
      /**
       * @type {import('@vue/composition-api').Ref<string|null>}
       */
      const filePath = ref(null)

      const content = i18n.t('new-shipment.paragraphs.carbon_footprint')
      const { click, haveDialog } = useTooltipDialog(null, content)

      const tooltip = computed(() => ({
        title: content,
        disabled: haveDialog.value
      }))

      return {
        tooltip,
        click,
        haveDialog,
        popoverVisible,
        filePath
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getCid'
      ]),
      ...mapGetters('shipments/new-shipment', [
        'getQuotation'
      ]),
      /**
       * @function co2
       * @returns {string}
       */
      co2 () {
        // @ts-ignore
        const { co2_estimation: co2 } = this.getQuotation
        return co2 < 1 ? '< 1' : this.$n(co2)
      },
      /**
       * @function fileUrl
       * @returns {string}
       */
      fileUrl () {
        // @ts-ignore
        return `v2/companies/${this.getCid}/quotation-requests/${this.getQuotation.uuid}/download`
      }
    },
    methods: {
      /**
       * @function fileGenerated
       * @param {string} url
       */
      fileGenerated (url) {
        this.popoverVisible = true
        this.filePath = url
      }
    }
  })
</script>

<style lang="scss" scoped>
.new-shipment-quotation-actions {
  border-top: 1px solid $divider;
}
.new-shipment-quotation-actions__carbon-footprint {
  border-bottom: 1px solid $divider;
}
@media (min-width: 770px) {
  .new-shipment-quotation-actions__carbon-footprint {
    border-style: none;
  }
}
.new-shipment-quotation-actions__item:not(:last-child) {
  border-right: 1px solid $divider;
}
</style>
