<template>
  <div class="new-shipment-quotation-price-lines">
    <div
      :class="{
        open: isOpen
      }"
      class="new-shipment-quotation-price-lines__header tw-flex tw-justify-end tw-mb-2"
      data-test="header"
    >
      <ui-button
        :class="{
          open: isOpen
        }"
        type="button"
        variant="link"
        size="sm"
        class="new-shipment-quotation-price-lines__header__button tw-italic"
        data-test="button"
        @click="isOpen = !isOpen"
      >
        <span
          data-test="title"
          v-text="$t('new-shipment.labels.quotation_detail')"
        />

        <template #right-icon>
          <ui-material-icon
            name="arrow_drop_down"
            class="!tw-text-lg"
            data-test="icon"
          />
        </template>
      </ui-button>
    </div>
    <b-collapse
      :id="'prices'"
      v-model="isOpen"
      role="tabpanel"
    >
      <slot />
    </b-collapse>
  </div>
</template>

<script>
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  /**
   * @module component - NewShipmentQuotationPriceLines
   */
  export default {
    name: 'NewShipmentQuotationPriceLines',
    props: {
      open: {
        type: Boolean,
        default: false
      }
    },
    setup (props) {
      const { state: isOpen } = useModelGetterSetter(props, 'open')

      return {
        isOpen
      }
    }
  }
</script>

<style lang="scss" scoped>

  .new-shipment-quotation-price-lines {
    &__header {
      &.open {
        border-bottom: 1px solid $info;
      }

      &:not(.open) {
        border-bottom: 1px solid white;
      }

      &__button {
        .material-icons {
          transition: transform 200ms;
        }

        &.open {
          .material-icons {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

</style>
