<template>
  <div class="inline-file-download">
    <slot
      v-bind="{
        loading: $wait.is('downloading inline file'),
        download,
        error: $err.has('error while downloading inline file')
      }"
    />
  </div>
</template>

<script>
  import axios from '@/resources/axios'

  /**
   * @module component - InlineFileDownload
   * @param {string} url - Request URL
   * @emits file
   */
  export default {
    name: 'InlineFileDownload',
    components: {
    },
    props: {
      url: {
        type: String,
        required: true
      }
    },
    methods: {
      /**
       * Request the download of the file.
       * @function download
       * @returns {Promise<any>|void}
       */
      download () {
        if (this.$wait.is('downloading inline file')) return
        this.$err.hide('error while downloading inline file')
        this.$wait.start('downloading inline file')

        return axios.get(this.url)
          .then((response) => {
            this.$emit('file', response.data.url)

            return response
          })
          .catch((/** @type {any} */ err) => {
            console.error('Error occured while download inline file', err)
            this.$err.show('error while downloading inline file')
          })
          .finally(() => {
            this.$wait.end('downloading inline file')
          })
      }
    }
  }
</script>

<style lang="scss" scoped>

  .btn-fab {
    box-shadow: none;
    height: 40px;
    width: auto;
    padding: 0;
    cursor: pointer;
    outline: none;
    background: transparent;
    border-radius: 0 !important;

    i {
      font-size: 35px;

      &.icon-ctk-warning {
        color: orangered;
      }
    }

    &.close-btn {
      background: transparent;
    }

    .close {
      width: 35px;
      height: 35px;
      font-size: 12px;
    }

    &:disabled {
      cursor: default;
    }
  }

</style>
