<template>
  <div class="new-shipment-goods">
    <h2
      v-text="$t('new-shipment.titles.goods')"
      data-test="title"
      class="new-shipment-goods__title tw-font-normal tw-mb-6"
    />

    <ValidationObserver
      ref="observer"
      slim
    >
      <template slot-scope="{ errors }">
        <form
          @submit.prevent="submitted"
        >
          <new-shipment-goods-types
            v-model="formData.goodType"
            class="mb-5"
            @input="clearFields"
          />

          <new-shipment-alert>
            <p
              v-if="formData.goodType === 'pallets'"
              data-test="standard-pallets-explanation"
              class="tw-mb-0"
              v-text="$t('new-shipment.paragraphs.goods.explanation.multipallets')"
            />

            <p
              v-if="formData.goodType === 'custom'"
              data-test="custom-pallets-explanation"
              class="tw-mb-0"
              v-text="$t('new-shipment.paragraphs.goods.explanation.custom_pallets')"
            />

            <p
              v-if="formData.goodType === 'bulk'"
              data-test="bulk-pallets-explanation"
              class="tw-mb-0"
              v-text="$t('new-shipment.paragraphs.goods.explanation.bulk_pallets')"
            />
          </new-shipment-alert>

          <template
            v-if="formData.goodType === 'pallets'"
          >
            <new-shipment-goods-pallet-group
              class="tw-mb-4"
            />
            <new-shipment-goods-pallet-total
              ref="pallet-total"
              :height.sync="formData.height"
              :weight.sync="formData.weight"
            />
          </template>
          <template
            v-else
          >
            <div class="tw-flex tw-flex-col 2sm:tw-flex-row">
              <ValidationProvider
                ref="length-provider"
                :name="$t('new-shipment.fields.goods.length')"
                :rules="'required|min_value:1|max_value:1320|numeric'"
                slim
              >
                <template slot-scope="{ invalid, validated, errors: fieldErrors }">
                  <ctk-input-text
                    id="length"
                    v-model="formData.length"
                    :error="invalid && validated || fieldErrors && fieldErrors.length > 0"
                    :label="$t('new-shipment.labels.goods.length')"
                    name="length"
                    type="tel"
                    suffix="cm"
                    class="tw-mb-4 2sm:tw-mr-4 2sm:tw-mb-0 tw-flex-1"
                    data-test="length"
                    required
                    @input="updateGuard"
                  />
                </template>
              </ValidationProvider>
              <ValidationProvider
                ref="width-provider"
                :name="$t('new-shipment.labels.goods.width')"
                :rules="'required|min_value:1|max_value:240|numeric'"
                slim
              >
                <template slot-scope="{ invalid, validated, errors: fieldErrors }">
                  <ctk-input-text
                    id="width"
                    v-model="formData.width"
                    :error="invalid && validated || fieldErrors && fieldErrors.length > 0"
                    :label="$t('new-shipment.labels.goods.width')"
                    name="width"
                    type="tel"
                    suffix="cm"
                    class="tw-mb-4 2sm:tw-mr-4 2sm:tw-mb-0 tw-flex-1"
                    data-test="width"
                    required
                    @input="updateGuard"
                  />
                </template>
              </ValidationProvider>
              <ValidationProvider
                ref="height-provider"
                :rules="'required|min_value:1|max_value:270|numeric'"
                :name="$t('new-shipment.fields.goods.height')"
                slim
              >
                <template slot-scope="{ invalid, validated, errors: fieldErrors }">
                  <ctk-input-text
                    id="height"
                    v-model="formData.height"
                    :error="invalid && validated || fieldErrors && fieldErrors.length > 0"
                    :label="$t('new-shipment.labels.goods.height_total')"
                    name="height"
                    type="tel"
                    suffix="cm"
                    class="tw-mb-4 2sm:tw-mr-4 2sm:tw-mb-0 tw-flex-1"
                    data-test="height"
                    required
                    @input="updateGuard"
                  />
                </template>
              </ValidationProvider>
              <ValidationProvider
                ref="weight-provider"
                :name="$t('app.labels.load.weight')"
                :rules="'required|min_value:50|max_value:28000|numeric'"
                slim
              >
                <template slot-scope="{ invalid, validated, errors: fieldErrors }">
                  <ctk-input-text
                    id="weight"
                    v-model="formData.weight"
                    :error="invalid && validated || fieldErrors && fieldErrors.length > 0"
                    :label="$t('new-shipment.labels.goods.weight_total')"
                    name="weight"
                    type="tel"
                    class="tw-mb-4 tw-flex-1"
                    suffix="kg"
                    data-test="weight"
                    required
                    @input="updateGuard"
                  />
                </template>
              </ValidationProvider>
            </div>
          </template>

          <new-shipment-alert
            data-test="infos-explanation"
          >
            <p class="tw-mb-0">
              {{ $t(formData.goodType === 'pallets'
                ? 'new-shipment.paragraphs.goods.description_explanation.pallets'
                : formData.goodType === 'custom'
                  ? 'new-shipment.paragraphs.goods.description_explanation.custom'
                  : 'new-shipment.paragraphs.goods.description_explanation.bulk') }}
            </p>

            <p
              v-if="formData.goodType === 'custom'"
              class="tw-mb-0 tw-mt-3 tw-p-3 tw-border tw-border-solid tw-border-white tw-rounded"
            >
              {{ $t('new-shipment.paragraphs.goods.description_explanation.custom.example') }}
            </p>
          </new-shipment-alert>

          <ValidationProvider
            ref="description-provider"
            :name="$t('new-shipment.fields.goods.description')"
            :rules="isDescriptionRequired ? 'required|max:4000' : 'max:4000'"
            slim
          >
            <template slot-scope="{ invalid, validated }">
              <ctk-input-textarea
                id="description"
                v-model="formData.description"
                :error="invalid && validated"
                :label="$t('new-shipment.labels.goods.description')"
                :required="isDescriptionRequired"
                name="description"
                data-test="description"
                @input="updateGuard"
              />
            </template>
          </ValidationProvider>

          <div
            v-if="mappedErrors(errors).length > 0"
            class="mt-3"
          >
            <div
              v-for="(error, k) in mappedErrors(errors)"
              :key="k"
              class="error-banner tw-rounded px-3 py-2 mb-2"
            >
              {{ error }}
            </div>
          </div>

          <!-- Excessive linear meter error -->
          <div
            v-if="formData.goodType === 'pallets' && getPalletsMpl > 13.2"
            class="error-banner tw-mt-3 tw-rounded px-3 py-2 mb-2"
            data-test="linear-meter-error"
          >
            {{ $t('new-shipment.paragraphs.goods.max_linear_meter') }}
          </div>

          <div
            class="new-shipment-goods__buttons tw-flex tw-flex-col-reverse 2sm:tw-flex-row 2sm:tw-justify-between tw-mt-6"
            data-test="buttons"
          >
            <div
              class="tw-mt-4 2sm:tw-mt-0"
            >
              <ui-button
                variant="link"
                class="tw-w-full 2sm:tw-w-auto"
                type="button"
                data-test="back"
                @click="back"
              >
                <template #left-icon>
                  <ui-material-icon
                    name="keyboard_arrow_left"
                  />
                </template>

                {{ $t('back') | capitalize }}
              </ui-button>
            </div>
            <ui-button
              :loading="$wait.is('requesting quotation')"
              data-test="save-button"
              variant="primary"

              type="submit"
            >
              {{ $t('new-shipment.buttons.request_quotation') }}
            </ui-button>
          </div>
        </form>
      </template>
    </ValidationObserver>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import store from '@/store'
  import { EventBus } from '@/services/EventBus'
  import { showToaster } from '@/services/Toaster'

  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'
  import CtkInputTextarea from '@/components/CtkInputs/CtkInputTextarea/index.vue'
  import NewShipmentGoodsTypes from './components/NewShipmentGoodsTypes/index.vue'
  import NewShipmentGoodsPalletGroup from './components/NewShipmentGoodsPalletGroup/index.vue'
  import NewShipmentGoodsPalletTotal from './components/NewShipmentGoodsPalletTotal/index.vue'
  import NewShipmentAlert from '@/views/Shippers/NewShipment/components/NewShipmentAlert/index.vue'

  /**
   * @module view - NewShipmentGoods
   */
  export default defineComponent({
    name: 'NewShipmentGoods',
    components: {
      NewShipmentGoodsTypes,
      NewShipmentGoodsPalletGroup,
      NewShipmentGoodsPalletTotal,
      CtkInputText,
      CtkInputTextarea,
      NewShipmentAlert
    },
    data () {
      return {
        previousRoute: null,
        formData: {
          goodType: 'pallets',
          length: null,
          width: null,
          height: null,
          weight: null,
          description: null
        }
      }
    },
    beforeRouteEnter (to, from, next) {
      /**
       * Check if the user has completed his addresses
       */
      if (!store.getters['shipments/new-shipment/isAddressesCompleted']) {
        const isPickupCompleted = !!store.getters['shipments/new-shipment/getPickupAddress']

        next({
          name: 'NewShipmentAddress',
          params: {
            direction: isPickupCompleted ? 'delivery' : 'pickup'
          }
        })
        return false
      }

      // @ts-ignore
      next(vm => {
        vm.previousRoute = from
      })
    },
    mounted () {
      this.updateFormData()

      EventBus.$on('new-shipment:goods:update-violations', this.updateViolations)
    },
    computed: {
      ...mapGetters('shipments/new-shipment', [
        'getLoad',
        'getPallets',
        'getPalletsMpl'
      ]),
      /**
       * Returns true if the description field is required for that load type
       * @function isDescriptionRequired
       * @returns {boolean}
       */
      isDescriptionRequired () {
        return this.formData.goodType !== 'pallets'
      }
    },
    methods: {
      ...mapActions('shipments/new-shipment', [
        'setGoods',
        'setQuotation',
        'setGuard'
      ]),
      /**
       * Called from the eventbus to update the views violations
       * after the request made in the dates view.
       * @function updateViolations
       * @param {Array<import('@/resources/handlers/violations').Violation>} violations
       */
      async updateViolations (violations) {
        /**
         * Check if the property path belongs to the allowed properties paths
         */
        /**
         * @type {{[key: string]: string}}
         */
        const propertyMatch = {
          'load.weight': 'weight',
          'load.width': 'width',
          'load.height': 'height',
          'load.length': 'length',
          'load.quantity': 'quantity'
        }

        violations.forEach(violation => {
          /**
           * Check if the pallet type is "pallets", then set the violations in the
           * nested component.
           */
          let refs = this.$refs
          if (this.formData.goodType === 'pallets') {
            const palletTotalRef = refs['pallet-total']
            refs = palletTotalRef.$refs
          }

          const provider = refs[`${propertyMatch[violation.property_path] || violation.property_path}-provider`]
          if (provider) {
            provider.setErrors([violation.message])
          } else {
            showToaster(this, violation.message, {
              type: 'error',
              position: 'bottom-left'
            })
          }

          /**
           * Handle the edge case where the violation is the entire "load" object.
           * Then show a toaster since it's not associated with a specific field.
           */
          if (violation.property_path === 'load') {
            showToaster(this, violation.message, {
              type: 'error',
              position: 'bottom-left'
            })
          }
        })
      },
      /**
       * Returns a list of mapped errors
       * @function mappedErrors
       * @returns {Array}
       */
      mappedErrors (errors) {
        return Object.keys(errors)
          .filter(v => errors[v].length > 0)
          .map(v => errors[v][0])
      },
      /**
       * Called whenever the user presses the back button
       * @function back
       */
      back () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Quotations', 'Clicked Back')
        }

        /** @type {any} */
        let route = {
          name: 'NewShipmentAddress',
          params: {
            direction: 'delivery'
          }
        }

        /**
         * Verify if the previous route was the template list
         */
        if (this.previousRoute && this.previousRoute.name === 'NewShipmentTemplateList') {
          route = {
            name: 'NewShipmentTemplateList'
          }
        }

        this.$router.push(route).catch(() => {})
      },
      /**
       * Called whenever the user changes something in the load
       * to update the guards.
       * @function updateGuard
       */
      updateGuard () {
        const {
          goodType,
          quantity,
          format,
          weight,
          height,
          width,
          length,
          description
        } = this.formData

        /**
          * Check if the load informations has changed, if it does, disable the
          * quotation step
          */
        const hasTypeChanged = goodType && goodType !== this.getLoad.type
        const hasQuantityChanged = quantity && quantity !== this.getLoad.quantity
        const hasFormatChanged = format && format !== this.getLoad.format
        const hasWeightChanged = weight && weight !== this.getLoad.weight
        const hasWidthChanged = width && width !== this.getLoad.width
        const hasLengthChanged = length && length !== this.getLoad.length
        const hasHeightChanged = height && height !== this.getLoad.height
        const hasDescriptionChanged = description && description !== this.getLoad.description

        if (hasTypeChanged || hasQuantityChanged || hasFormatChanged ||
          hasWeightChanged || hasWidthChanged || hasLengthChanged ||
          hasHeightChanged || hasDescriptionChanged) {
          const guards = ['goods', 'dates', 'handling', 'quotation', 'informations']
          guards.forEach(guard => this.setGuard({
            guard,
            value: false
          }))
        }
      },
      clearFields () {
        if (this.$refs.observer) this.$refs.observer.reset()
        this.updateGuard()
      },
      updateFormData () {
        const {
          type,
          quantity,
          format,
          weight,
          height,
          width,
          length,
          description
        } = this.getLoad

        this.formData = {
          goodType: type,
          format,
          height,
          weight,
          width,
          length,
          description,
          quantity
        }
      },
      submitted () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Quotations', 'Validated Goods')
        }

        this.$refs.observer.reset()
        this.$refs.observer.validate()
          .then(valid => {
            if (!valid) return false

            const {
              goodType,
              quantity,
              format,
              weight,
              height,
              width,
              length,
              description
            } = this.formData

            if (goodType === 'pallets' && this.getPalletsMpl > 13.2) return false

            /**
             * Check if the load informations has changed, if it does, disable the
             * quotation step
             */
            this.updateGuard()

            /**
             * Create a pristine array/object to avoid references
             * @const pallets
             */
            const pallets = Array.from(this.getPallets.map(pallet => Object.assign({}, pallet)))

            this.setGoods({
              type: goodType,
              quantity,
              format,
              weight,
              width,
              length,
              height,
              description,
              pallets
            })

            this.setGuard({
              guard: 'goods',
              value: true
            })

            this.$router.push({
              name: 'NewShipmentHandling'
            })
              .catch(() => {})
          })
      }
    },
    beforeDestroy () {
      EventBus.$off('new-shipment:goods:update-violations')
    }
  })
</script>

<style lang="scss" scoped>

  .new-shipment-goods {
    &__title {
      position: relative;
      font-size: 20px;

      &::after {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 220px;
        height: 1px;
        background-color: $divider;
      }
    }

    &__fields {
      @media only screen and (max-width: $breakpoint-mobile-l) {
        flex-direction: column;
        margin-bottom: 32px !important;

        .ctk-input-select,
        .ctk-input-text {
          margin-right: 0 !important;
          margin-bottom: 16px;
        }
      }
    }

    .error-banner {
      color: white;
      background-color: $danger;
    }
  }

</style>
