<template>
  <div class="new-shipment-sidebar-group-item-handling tw-flex tw-flex-col dots-text">
    <div
      data-test="label"
      class="new-shipment-sidebar-group-item-handling__label tw-text-gray-700"
    >
      {{ $t('new-shipment.labels.handling_summary') }}
    </div>
    <div
      data-test="value"
      class="tw-font-medium dots-text"
    >
      {{ $t(driverValue) }} - {{ $t(tailLiftValue) }}
    </div>
  </div>
</template>

<script>
  /**
   * @module component - NewShipmentSidebarGroupItemHandling
   * @param {string} direction
   * @param {string} driver
   * @param {string} tailLift
   */
  export default {
    name: 'NewShipmentSidebarGroupItemHandling',
    props: {
      direction: {
        type: String,
        required: true
      },
      driver: {
        type: String,
        required: true
      },
      tailLift: {
        type: String,
        required: true
      }
    },
    computed: {
      /**
       * @function driverValue
       * @returns {string}
       */
      driverValue () {
        return this.driver === 'carrier'
          ? 'new-shipment.values.handling_summary.carrier'
          : (this.direction === 'delivery'
            ? 'new-shipment.values.handling_summary.shipper_delivery'
            : 'new-shipment.values.handling_summary.shipper')
      },
      /**
       * @function tailLiftValue
       * @returns {string}
       */
      tailLiftValue () {
        return this.tailLift === 'yes'
          ? 'new-shipment.values.handling_summary.with_tail_lift'
          : 'new-shipment.values.handling_summary.without_tail_lift'
      }
    }
  }
</script>

<style lang="scss" scoped>

  .new-shipment-sidebar-group-item-handling {
    color: $primary-text;
    line-height: 1.2em;

    &__label {
      font-size: 13px;
    }
  }

</style>
