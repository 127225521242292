import useEvent from '@/composables/useEvent'

/**
 * @function useKeyPress
 * @param {import('@vue/composition-api').Ref} element
 * @param {number} keyCode
 * @param {Function} cb
 */
export default function useKeyPress (element, keyCode, cb) {
  useEvent(element, 'keydown', event => {
    const key = event.which || event.keyCode
    if (key === keyCode) {
      cb(event)
    }
  })
}
