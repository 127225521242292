<template>
  <div class="new-shipment-template-list tw-px-4">
    <div class="ctk-container tw-pb-8">
      <div>
        <router-link
          :to="{ name: 'NewShipmentTemplate' }"
          class="tw-flex tw-items-center tw-text-blue-500 hover:tw-text-blue-600 hover:tw-no-underline tw-mt-2 tw-h-6"
          data-test="back"
        >
          <ui-material-icon
            class="tw-mr-2 tw-text-xl"
            name="keyboard_arrow_left"
          />
          {{ $t('back') | capitalize }}
        </router-link>
        <h1
          class="new-shipment-template-list__title tw-font-medium tw-text-2xl tw-mt-0 tw-mb-5"
          v-text="$t('new-shipment.titles.pick_template')"
          data-test="title"
        />
      </div>

      <div class="new-shipment-template-list__layout tw-relative tw-flex tw-flex-col tw-py-5 tw-px-4 md:tw-px-8 tw-rounded tw-border tw-border-solid tw-border-gray-300 tw-bg-white md:tw-py-8">
        <h2
          v-if="!$wait.is('fetching shipments')"
          class="new-shipment-template-list__layout__title tw-relative tw-text-xl tw-font-normal tw-mb-8"
          v-text="$tc('new-shipment.titles.templates', getFetchedShipments.length, {
            count: getFetchedShipments.length
          })"
          data-test="subtitle"
        />
        <ctk-skeleton-block
          v-else
          width="200px"
          height="35px"
          level="2"
          loading
          class="tw-mb-5"
        />

        <div class="tw-flex tw-flex-col">
          <template
            v-if="$wait.is('fetching shipments')"
          >
            <ctk-skeleton-view
              depth="1"
              class="tw-flex tw-flex-col"
            >
              <ctk-skeleton-block
                width="500px"
                height="55px"
                level="3"
                loading
                class="tw-mb-3"
              />
              <ctk-skeleton-block
                width="400px"
                height="55px"
                level="3"
                loading
                class="tw-mb-3"
              />
              <ctk-skeleton-block
                width="550px"
                height="55px"
                level="3"
                loading
                class="tw-mb-3"
              />
            </ctk-skeleton-view>
          </template>
          <template
            v-else
          >
            <new-shipment-template-list-item
              v-for="shipment in getFetchedShipments"
              :key="shipment.uuid"
              :shipment="shipment"
              class="tw-mb-4"
              @click="select(shipment)"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import store from '@/store'
  import CtkSkeletonView from '@/components/CtkSkeletonView/index.vue'
  import CtkSkeletonBlock from '@/components/CtkSkeletonView/_subs/CtkSkeletonBlock/index.vue'
  import NewShipmentTemplateListItem from './components/NewShipmentTemplateListItem/index.vue'
  import { AUTHORIZED_PALLET_FORMATS } from '@/composables/constants'

  /**
   * @module component - NewShipmentTemplateList
   */
  export default defineComponent({
    name: 'NewShipmentTemplateList',
    components: {
      CtkSkeletonView,
      CtkSkeletonBlock,
      NewShipmentTemplateListItem
    },
    metaInfo () {
      return {
        title: this.$t('new-shipment.titles.main')
      }
    },
    // @ts-ignore
    async beforeRouteEnter (to, from, next) {
      if (!store.getters.isUserShipper) {
        next({
          name: 'Offers'
        })
        return
      }

      if (store.getters['auth/isAccountLocked']) {
        next({
          name: 'Shipments'
        })
        return
      }

      store.dispatch('setAppReady', true)
      next()
    },
    computed: {
      ...mapGetters('shipments/new-shipment', [
        'getFetchedShipments'
      ])
    },
    methods: {
      ...mapActions('shipments/new-shipment', [
        'retrieveShipments',
        'setAddress',
        'setGoods',
        'setTimeslot',
        'setPallets',
        'setContact',
        'setComment',
        'setMeeting',
        'setHandlingDriver',
        'setHandlingTailLift'
      ]),
      // @ts-ignore
      select (shipment) {
        const steps = ['pickup', 'delivery']
        steps.forEach(direction => {
          const { address, comment, handling, contact, time_slot: timeSlot, meeting } = shipment[direction]
          this.setAddress({
            direction,
            address
          })

          this.setContact({
            direction,
            contact
          })

          this.setComment({
            direction,
            comment
          })

          this.setMeeting({
            direction,
            meeting
          })

          /**
           * Re-parse the start & end time to set the timeslot.
           */
          const parsedStartTime = this.$moment(timeSlot.start_time, 'HH:mm')
          const parsedEndTime = this.$moment(timeSlot.end_time, 'HH:mm')
          const today = this.$moment().startOf('day')

          if (parsedStartTime && parsedEndTime) {
            const startTime = parsedStartTime.diff(today, 'minutes')
            const endTime = parsedEndTime.diff(today, 'minutes')

            this.setTimeslot({
              direction,
              startTime,
              endTime
            })
          }

          /**
           * TODO: Change this behaviour and use a boolean instead.
           */
          const { driver, tail_lift: tailLift } = handling
          this.setHandlingDriver({
            direction: direction,
            value: driver ? 'carrier' : 'shipper'
          })
          this.setHandlingTailLift({
            direction: direction,
            value: tailLift ? 'yes' : 'no'
          })
        })

        /**
         * Set the goods when selecting.
         * A quick workaround here to fix the difference between the load type
         * between a shipment ("pallets") and a quotation request("pallet").
         * TODO: Remove this when the issue will be resolved by the back-end.
         */
        const { quantity, format, length, width, pallets } = shipment.load

        /**
         * In the case where we receive a list of pallets from the backend,
         * consider it as a load with the "pallets" type.
         * This should be removed when we'll be able to send pallets to the backend
         * without going through a transformation from pallets to custom type.
         */
        if (pallets) {
          this.setGoods({
            ...shipment.load,
            // Override the load type to "pallets" so the form shows the right tab.
            type: 'pallets'
          })

          // @ts-ignore
          this.setPallets(pallets.map(pallet => {
            /**
             * Since we do not have the format, build it ourselves.
             * Note: this may not match pre-existing formats, so we should fallback to the
             * "other" case that shows the 2 additional fields
             */
            let palletFormat = `${pallet.width}x${pallet.length}`
            if (!Object.keys(AUTHORIZED_PALLET_FORMATS).includes(palletFormat)) {
              palletFormat = 'other'
            }

            return {
              quantity: pallet.quantity,
              format: palletFormat,
              length: pallet.length,
              width: pallet.width
            }
          }))
        } else {
          this.setGoods(shipment.load)
          this.setPallets([
            {
              quantity,
              format,
              length,
              width
            }
          ])
        }

        if (this.$matomo) {
          this.$matomo.trackEvent('Quotations', 'Selected Previous Shipment', shipment.uuid)
        }

        this.$router.push({
          name: 'NewShipmentAddress',
          params: {
            direction: 'pickup'
          }
        })
          .catch(() => {})
      }
    },
    mounted () {
      this.$wait.start('fetching shipments')
      this.retrieveShipments()
        .finally(() => {
          this.$wait.end('fetching shipments')
        })
    }
  })
</script>

<style lang="scss" scoped>
.new-shipment-template-list {
  overflow-y: auto !important;
}
.new-shipment-template-list__layout {
  padding-bottom: 270px;
}
.new-shipment-template-list__layout__title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 200px;
  height: 1px;
  background-color: $divider;
}
.new-shipment-template-list__layout::after {
  content: '';
  position: absolute;
  bottom: 50px;
  right: 0;
  background-image: url('~@/assets/img/illustrations/shipments.svg');
  background-size: contain;
  background-position: right center;
  background-repeat: no-repeat;
  opacity: 0.8;
  height: 220px;
  width: 100%;
}
@media (min-width: 770px) {
  .new-shipment-template-list .new-shipment-template-list__layout::after {
    width: 660px;
  }
}
</style>
