<template>
  <div class="new-shipment-acceptation-dialog-confirmation tw-px-4 md:tw-px-10 tw-pb-4 md:tw-pb-10 tw-pt-8 md:tw-pt-16">
    <div class="new-shipment-acceptation-dialog-confirmation__wrapper tw-flex tw-flex-col tw-mx-auto tw-text-center tw-mb-4">
      <img
        src="@/assets/img/icons/confirmation.svg"
        width="80"
        height="80"
        alt=""
        class="tw-mb-8 tw-mx-auto"
        data-test="icon"
      >

      <h1
        v-text="$t(shipment.price_type === 'pallet_network'
          ? 'new-shipment.titles.quotation.confirmation_pallet_network'
          : 'new-shipment.titles.quotation.confirmation_chronotruck')"
        class="new-shipment-acceptation-dialog-confirmation__title fs-22 tw-font-medium tw-mb-4 md:tw-whitespace-nowrap"
        data-test="title"
      />

      <p
        v-if="!(isSelectedPriceCreditCard && shipment.price_type === 'pallet_network')"
        v-markdown="$t(shipment.price_type === 'pallet_network'
          ? 'new-shipment.paragraphs.quotation.confirmation.assign_right_away'
          : 'new-shipment.paragraphs.quotation.confirmation.best_delay')"
        class="tw-text-secondary-lighten tw-mb-0"
        data-test="best-delay"
      />
      <i18n
        :path="isSelectedPriceCreditCard && shipment.price_type === 'pallet_network'
          ? 'new-shipment.paragraphs.quotation.confirmation.email_payment'
          : 'new-shipment.paragraphs.quotation.confirmation.email'"
        tag="p"
        class="tw-text-secondary-lighten tw-mb-6"
        data-test="email-paragraph"
      >
        <template #email>
          <span
            v-text="getUserInfos.email"
            class="tw-font-bold tw-whitespace-nowrap"
            data-test="email"
          />
        </template>
      </i18n>

      <ui-button
        v-if="isSelectedPriceCreditCard && shipment.price_type === 'pallet_network'"
        :to="{
          name: 'Shipment',
          params: {
            uuid: shipment.uuid
          },
          query: {
            'ignore-loss-of-entry': 1
          }
        }"
        class="tw-text-white tw-rounded-full tw-px-4 tw-mx-auto"
        variant="primary"

        v-matomo="{
          click: { category: 'Quotations', action: 'Confirm Shipment Request', name: 'View' }
        }"
        data-test="pay-button"
      >
        {{ $t('app.buttons.track_shipment') }}
      </ui-button>

      <div class="new-shipment-acceptation-dialog-confirmation__links tw-flex tw-justify-center">
        <router-link
          v-if="!(isSelectedPriceCreditCard && shipment.price_type === 'pallet_network')"
          :to="{
            name: 'Shipment',
            params: {
              uuid: shipment.uuid
            },
            query: {
              'ignore-loss-of-entry': 1
            }
          }"
          v-matomo="{
            click: { category: 'Quotations', action: 'Confirm Shipment Request', name: 'View' }
          }"
          class="tw-text-right tw-italic tw-text-blue-500 hover:tw-text-blue-600 tw-text-sm tw-mt-4 tw-mr-8"
          data-test="view-shipment"
        >
          {{ $t(shipment.price_type === 'pallet_network'
            ? 'app.buttons.track_shipment'
            : 'new-shipment.buttons.quotation.confirmation_chronotruck') }}
        </router-link>
        <router-link
          :to="{
            name: 'NewShipment'
          }"
          v-matomo="{
            click: { category: 'Quotations', action: 'Confirm Shipment Request', name: 'New' }
          }"
          class="tw-text-left tw-italic tw-text-blue-500 hover:tw-text-blue-600 tw-text-sm tw-mt-4"
          replace
          data-test="new-shipment"
        >
          {{ $t('app.buttons.new_shipment') }}
        </router-link>
      </div>
    </div>

    <img
      src="@/assets/img/illustrations/shipment_confirmation.svg"
      alt=""
      class="new-shipment-acceptation-dialog-confirmation__illustration tw-w-full tw-pointer-events-none tw-select-none tw--mt-8 tw-hidden md:tw-block"
    >
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  /**
   * @module component - NewShipmentAcceptationDialogConfirmation
   * @param {object} shipment - The shipment object
   */
  export default {
    name: 'NewShipmentAcceptationDialogConfirmation',
    props: {
      shipment: {
        type: Object,
        default: null
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getUserInfos'
      ]),
      ...mapGetters('shipments/new-shipment', [
        'isSelectedPriceCreditCard'
      ])
    }
  }
</script>

<style lang="scss" scoped>
.new-shipment-acceptation-dialog-confirmation__wrapper {
  max-width: 480px;
}
.new-shipment-acceptation-dialog-confirmation__links a:not(:last-child) {
  position: relative;
}
.new-shipment-acceptation-dialog-confirmation__links a:not(:last-child)::after {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 118, 150, var(--tw-bg-opacity));
  position: absolute;
  content: '';
  height: 1rem;
  width: 1px;
  right: -1rem;
  top: 0;
  bottom: 0;
  margin: auto;
}
.new-shipment-acceptation-dialog-confirmation__illustration {
  -o-object-fit: contain;
  object-fit: contain;
  height: 220px;
}
</style>
