var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-fat-radio-group',{staticClass:"new-shipment-dates-types 2sm:tw-flex tw-flex-1",attrs:{"items":_vm.items},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var active = ref.active;
var keydown = ref.keydown;
return [_c('new-shipment-types-item',{directives:[{name:"matomo",rawName:"v-matomo",value:({
        click: {
          category: 'Quotations',
          action: item.value === 'tailored'
            ? 'Date Step Choose Tailored'
            : 'Date Step Choose Optimal'
        }
      }),expression:"{\n        click: {\n          category: 'Quotations',\n          action: item.value === 'tailored'\n            ? 'Date Step Choose Tailored'\n            : 'Date Step Choose Optimal'\n        }\n      }"}],staticClass:"tw-w-full tw-mb-4 2sm:tw-mb-0 2sm:tw-w-1/2",attrs:{"title":item.title,"active":active},nativeOn:{"keydown":function($event){return keydown.apply(null, arguments)},"click":function($event){_vm.selectedType = item.value}}},[_c('div',{staticClass:"new-shipment-dates-types__title tw-font-bold tw-text-sm",domProps:{"textContent":_vm._s(item.title)}}),_c('div',{staticClass:"new-shipment-dates-types__subtitle tw-text-xs",domProps:{"textContent":_vm._s(item.subtitle)}})])]}}]),model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})}
var staticRenderFns = []

export { render, staticRenderFns }