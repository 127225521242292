<template>
  <div class="new-shipment-dates-optimal-direction tw-flex tw-flex-col">
    <div
      class="tw-flex tw-items-center"
      data-test="header"
    >
      <ui-ctk-icon
        :class="{
          'tw-text-blue-500': direction === 'pickup',
          'tw-text-green-500': direction === 'delivery'
        }"
        name="calendar"
        class="tw--ml-1 tw-text-3xl"
        data-test="icon"
      />
      <div
        data-test="title"
        v-text="$t(direction === 'pickup'
          ? 'new-shipment.titles.dates.pickup'
          : 'new-shipment.titles.dates.delivery')"
      />
    </div>
    <div class="tw-flex">
      <div class="tw-flex tw-flex-1 tw-flex-col tw-bg-white tw-rounded tw-mr-1 tw-p-3 tw-leading-snug">
        <div
          class="new-shipment-dates-optimal-direction__label tw-text-xs"
          data-test="label"
          v-text="$t(direction === 'pickup'
            ? 'new-shipment.labels.dates.from_the'
            : 'new-shipment.labels.dates.until')"
        />
        <div class="tw-flex tw-items-center">
          <div
            class="new-shipment-dates-optimal-direction__value tw-font-medium"
            data-test="date"
            v-text="$moment(date).format('LL')"
          />
          <a
            v-if="editable"
            href="#"
            role="button"
            class="tw-text-blue-500 hover:tw-text-blue-600 tw-italic tw-ml-2 tw-text-xs"
            v-text="$options.filters.capitalize($t('edit'))"
            data-test="edit-button"
            @click.prevent="$emit('edit')"
          />
        </div>
      </div>
      <div class="tw-flex tw-bg-white tw-rounded">
        <ctk-time-slot-range
          :start-time="startTime"
          :end-time="endTime"
          class="tw-m-auto tw-px-2 tw-text-xs"
          data-test="time"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkTimeSlotRange from '@/components/CtkTimeSlot/_subs/CtkTimeSlotRange/index.vue'

  /**
   * @module component - NewShipmentDatesOptimalDirection
   * @param {string} direction
   * @param {string|object} date
   * @param {string} startTime
   * @param {string} endTime
   * @param {boolean} [editable=false]
   */
  export default defineComponent({
    name: 'NewShipmentDatesOptimalDirection',
    components: {
      CtkTimeSlotRange
    },
    props: {
      direction: {
        type: String,
        required: true
      },
      date: {
        type: [String, Object],
        required: true
      },
      startTime: {
        type: String,
        required: true
      },
      endTime: {
        type: String,
        required: true
      },
      editable: {
        type: Boolean,
        default: false
      }
    }
  })
</script>

<style lang="scss" scoped>

  .new-shipment-dates-optimal-direction {
    &__label {
      color: $secondary-text;
    }

    &__value {
      color: $secondary-lighten;
    }
  }

</style>
