<template functional>
  <div class="new-shipment-quotation-price-header tw-flex tw-items-center">
    <div
      v-if="props.hasCheck"
      :class="{
        'tw-bg-white tw-text-blue-500': props.active,
        'tw-border tw-border-solid tw-border-blue-500 tw-bg-white': !props.active
      }"
      class="new-shipment-quotation-price-header__checkbox tw-flex tw-items-center tw-justify-center tw-rounded-full tw-h-4 tw-w-4 tw-mr-2"
      data-test="checkbox"
    >
      <ui-material-icon
        v-if="props.active"
        class="tw-text-sm"
        name="done"
        data-test="icon"
      />
    </div>
    <div
      data-test="title"
      v-text="props.title"
    />
  </div>
</template>

<script>
  /**
   * @module component - NewShipmentQuotationPriceHeader
   * @param {string} title
   * @param {boolean} [active=false]
   * @param {boolean} [hasCheck=false]
   */
  export default {
    name: 'NewShipmentQuotationPriceHeader',
    props: {
      title: {
        type: String,
        required: true
      },
      active: {
        type: Boolean,
        default: false
      },
      hasCheck: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
