import { onBeforeUnmount } from '@vue/composition-api'

/**
 * @function useEvent
 * @param {import('@vue/composition-api').Ref} element
 * @param {string} name
 * @param {Function} cb
 */
export default function useEvent (element, name, cb) {
  function handler (event) {
    cb(event)
  }

  if (element.value) element.value.addEventListener(name, handler)

  onBeforeUnmount(() => {
    if (!element.value) return

    element.value.removeEventListener(name, handler)
  })
}
