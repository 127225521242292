<template>
  <div class="tw-flex new-shipment-goods-types">
    <ui-fat-radio-group
      v-model="selectedType"
      :items="types"
      class="tw-hidden 2sm:tw-flex tw-flex-1"
      data-test="group"
    >
      <template slot-scope="{ item, active, keydown }">
        <new-shipment-goods-types-item
          :icon="item.icon"
          :title="$t(item.title)"
          :subtitle="item.subtitle && $t(item.subtitle)"
          :data-test="item.value"
          :active="active"
          :disabled="$attrs.disabled"
          class="tw-w-1/3"
          @keydown.native="keydown"
          @click.native="selectedType = item.value"
        />
      </template>
    </ui-fat-radio-group>

    <ctk-drop-menu
      v-model="selectedType"
      :items="Object.keys(types).map(k => ({
        key: k,
        icon: types[k].icon,
        title: `${$t(types[k].title)}${types[k].subtitle ? ` (${$t(types[k].subtitle)})` : ''}`
      }))"
      class="tw-block 2sm:tw-hidden tw-w-full"
    >
      <template #icon>
        <ui-ctk-icon
          :name="types[selectedType].icon"
          class="tw-mr-2"
        />
      </template>
    </ctk-drop-menu>
  </div>
</template>

<script>
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  import CtkDropMenu from '@/components/CtkDropMenu/index.vue'
  import NewShipmentGoodsTypesItem from './_subs/NewShipmentGoodsTypesItem/index.vue'
  import UiFatRadioGroup from '@/components/UI/FatRadioGroup/index.vue'

  /**
   * @module component - NewShipmentGoodsTypes
   * @param {string} [active=false]
   * @param {string} icon
   * @param {string} title
   */
  export default {
    name: 'NewShipmentGoodsTypes',
    inheritAttrs: false,
    components: {
      CtkDropMenu,
      UiFatRadioGroup,
      NewShipmentGoodsTypesItem
    },
    props: {
      value: {
        type: String,
        required: true
      }
    },
    setup (props) {
      const { state: selectedType } = useModelGetterSetter(props, 'value')

      return {
        selectedType
      }
    },
    computed: {
      types () {
        return {
          pallets: {
            value: 'pallets',
            icon: 'pallet-count',
            title: 'new-shipment.labels.goods.types.pallet',
            subtitle: 'new-shipment.labels.goods.types.pallet_subtitle'
          },
          custom: {
            value: 'custom',
            icon: 'pallet-mpl',
            title: 'new-shipment.labels.goods.types.pallet',
            subtitle: 'new-shipment.labels.goods.types.custom_subtitle'
          },
          bulk: {
            value: 'bulk',
            icon: 'unprepared',
            title: 'new-shipment.labels.goods.types.bulk'
          }
        }
      }
    }
  }
</script>

<style lang="scss">

  .new-shipment-goods-types {
    .new-shipment-goods-types-item {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }

    .ctk-drop-menu .ctk-drop-menu__button {
      background-color: $info;

      &:focus {
        @include focusShadow($info);
      }
    }
  }

</style>
