var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-shipment-quotation"},[_c('new-shipment-sidebar',{staticClass:"new-shipment-sidebar__embedded"}),_c('div',{staticClass:"tw-flex tw-justify-between tw-items-start"},[_c('h2',{staticClass:"new-shipment-quotation__title tw-font-normal",domProps:{"textContent":_vm._s(_vm.$t('new-shipment.titles.quotation'))}}),_c('ui-button',{attrs:{"rounded":false,"variant":"info","type":"button"},on:{"click":function($event){_vm.dialogs.compare = true}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.getQuotation && _vm.getQuotation.prices && _vm.getQuotation.prices.length > 1 ? 'shipments.titles.price_compare' : 'shipments.titles.price_understanding'))+" ")])],1),(!!_vm.getPalletNetworkPrice)?[(_vm.getQuotation && _vm.getPrice)?_c('new-shipment-quotation-price',{staticClass:"tw-mb-5",attrs:{"title":_vm.offers[_vm.getPrice.type],"lines":_vm.lines,"value":_vm.selectedPrice && (_vm.selectedPrice.type === 'chronotruck' || _vm.selectedPrice.type === 'corridor'),"has-check":true,"total-vat-included":("" + (_vm.$t('price_incl_tax', {
        price: _vm.$options.filters.currency(_vm.getPrice.total_including_vat, _vm.getPrice.currency, _vm.$i18n.locale)
      })) + (_vm.priceSurge ? '*' : '')),"total-vat-excluded":("" + (_vm.$t('price_excl_tax', {
        price: _vm.$options.filters.currency(_vm.getPrice.total, _vm.getPrice.currency, _vm.$i18n.locale)
      })) + (_vm.priceSurge ? '*' : '')),"open":_vm.detailOpen.chronotruck},on:{"update:open":function($event){return _vm.$set(_vm.detailOpen, "chronotruck", $event)},"input":function($event){return _vm.selectPrice(_vm.getPrice)}},scopedSlots:_vm._u([(_vm.priceSurge)?{key:"price-surge",fn:function(){return [_c('p',{directives:[{name:"matomo",rawName:"v-matomo",value:({
            insert: {
              category: 'Quotations',
              action: 'Display Price Increase Sentence',
              name: _vm.priceSurge
            }
          }),expression:"{\n            insert: {\n              category: 'Quotations',\n              action: 'Display Price Increase Sentence',\n              name: priceSurge\n            }\n          }"}],staticClass:"tw-mt-4 tw-italic tw-text-xs tw-text-gray-700",domProps:{"textContent":_vm._s(("* " + _vm.priceSurgeExplanation))}})]},proxy:true}:null],null,true)},[_c('new-shipment-quotation-price-content',{attrs:{"offer-type":_vm.getPrice.type},on:{"learn-more":_vm.learnMore}})],1):_vm._e(),(_vm.getQuotation && _vm.getPalletNetworkPrice)?_c('new-shipment-quotation-price',{attrs:{"title":_vm.offers.pallet_network,"lines":_vm.palletNetworkLines,"value":_vm.selectedPrice && _vm.selectedPrice.type === 'pallet_network',"has-check":true,"total-vat-included":("" + (_vm.$t('price_incl_tax', {
        price: _vm.$options.filters.currency(_vm.getPalletNetworkPrice.total_including_vat, _vm.getPalletNetworkPrice.currency, _vm.$i18n.locale)
      }))),"total-vat-excluded":("" + (_vm.$t('price_excl_tax', {
        price: _vm.$options.filters.currency(_vm.getPalletNetworkPrice.total, _vm.getPalletNetworkPrice.currency, _vm.$i18n.locale)
      }))),"open":_vm.detailOpen.pallet_network,"disabled":true,"has-unavailable-message":true},on:{"update:open":function($event){return _vm.$set(_vm.detailOpen, "pallet_network", $event)},"input":function($event){return _vm.selectPrice(_vm.getPalletNetworkPrice)}},scopedSlots:_vm._u([{key:"unavailable-message",fn:function(){return [_c('i18n',{staticClass:"tw-italic tw-mb-0 tw-leading-tight",attrs:{"path":"new-shipment.paragraphs.offer_unavailable","tag":"p"},scopedSlots:_vm._u([{key:"name",fn:function(){return [_c('span',[_vm._v("Laura Pinto")])]},proxy:true},{key:"phone",fn:function(){return [_c('span',{staticClass:"tw-whitespace-nowrap",domProps:{"textContent":_vm._s(_vm.phone.text)}})]},proxy:true}],null,false,427321744)})]},proxy:true}],null,false,552506500)},[_c('new-shipment-quotation-price-content',{attrs:{"offer-type":_vm.getPalletNetworkPrice.type},on:{"learn-more":_vm.learnMore}})],1):_vm._e()]:[(_vm.getQuotation && _vm.getPrice)?_c('new-shipment-quotation-price',{attrs:{"title":_vm.offers[_vm.getPrice.type],"lines":_vm.lines,"value":true,"has-check":false,"total-vat-included":("" + (_vm.$t('price_incl_tax', {
        price: _vm.$options.filters.currency(_vm.getPrice.total_including_vat, _vm.getPrice.currency, _vm.$i18n.locale)
      })) + (_vm.priceSurge ? '*' : '')),"total-vat-excluded":("" + (_vm.$t('price_excl_tax', {
        price: _vm.$options.filters.currency(_vm.getPrice.total, _vm.getPrice.currency, _vm.$i18n.locale)
      })) + (_vm.priceSurge ? '*' : '')),"open":_vm.detailOpen.chronotruck},on:{"update:open":function($event){return _vm.$set(_vm.detailOpen, "chronotruck", $event)}},scopedSlots:_vm._u([(_vm.priceSurge)?{key:"price-surge",fn:function(){return [_c('p',{directives:[{name:"matomo",rawName:"v-matomo",value:({
            insert: {
              category: 'Quotations',
              action: 'Display Price Increase Sentence',
              name: _vm.priceSurge
            }
          }),expression:"{\n            insert: {\n              category: 'Quotations',\n              action: 'Display Price Increase Sentence',\n              name: priceSurge\n            }\n          }"}],staticClass:"tw-mt-4 tw-italic tw-text-xs tw-text-gray-700",domProps:{"textContent":_vm._s(("* " + _vm.priceSurgeExplanation))}})]},proxy:true}:null],null,true)},[_c('new-shipment-quotation-price-content',{attrs:{"offer-type":_vm.getPrice.type},on:{"learn-more":_vm.learnMore}})],1):_vm._e()],_c('new-shipment-quotation-compare-dialog',{on:{"select-price":_vm.pickPrice},model:{value:(_vm.dialogs.compare),callback:function ($$v) {_vm.$set(_vm.dialogs, "compare", $$v)},expression:"dialogs.compare"}}),_c('new-shipment-quotation-learn-more-dialog',{attrs:{"price-type":_vm.dialogs.learnMore.priceType},model:{value:(_vm.dialogs.learnMore.visible),callback:function ($$v) {_vm.$set(_vm.dialogs.learnMore, "visible", $$v)},expression:"dialogs.learnMore.visible"}}),_c('div',{staticClass:"new-shipment-quotation__buttons tw-flex tw-flex-col-reverse 2sm:tw-flex-row 2sm:tw-justify-between tw-mt-6 tw-mb-4"},[_c('div',{staticClass:"tw-mt-4 2sm:tw-mt-0"},[_c('ui-button',{staticClass:"tw-w-full 2sm:tw-w-auto",attrs:{"to":{
          name: 'NewShipmentDates'
        },"variant":"link"},nativeOn:{"click":function($event){return _vm.back.apply(null, arguments)}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('ui-material-icon',{attrs:{"name":"keyboard_arrow_left"}})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t('back')))+" ")])],1),_c('ui-button',{attrs:{"variant":"primary","type":"button","loading":_vm.$wait.is('fetching company infos'),"disabled":(!!_vm.getPalletNetworkPrice && !_vm.selectedPrice) || _vm.$wait.is('fetching company infos')},on:{"click":_vm.nextStep}},[_vm._v(" "+_vm._s(_vm.$t('new-shipment.buttons.accept_quotation'))+" ")])],1),_c('new-shipment-quotation-actions',{staticClass:"tw-w-full"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }