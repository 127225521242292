<template>
  <div
    :class="{
      'new-shipment-quotation-price--active': value,
      'new-shipment-quotation-price--disabled': disabled
    }"
    class="new-shipment-quotation-price tw-rounded"
  >
    <component
      :is="hasCheck ? 'button' : 'div'"
      :type="hasCheck ? 'button': null"
      :disabled="disabled"
      class="new-shipment-quotation-price__header tw-relative tw-flex tw-items-center tw-w-full tw-border-none tw-text-white tw-text-base tw-px-4 md:tw-px-6 tw-justify-between focus:tw-outline-none"
      data-test="header"
      @click="$emit('input')"
    >
      <new-shipment-quotation-price-header
        :active="value"
        :has-check="hasCheck"
        :title="title"
        data-test="header-content"
      />
    </component>
    <!-- Warning unavailable message -->
    <div
      v-if="hasUnavailableMessage"
      class="new-shipment-quotation-price__content tw-flex tw-p-4 tw-border-0 tw-border-b tw-border-b-gray-400 tw-border-solid"
      data-test="unavailable-message"
    >
      <ui-ctk-icon
        name="warning-full"
        class="tw-text-yellow-500 tw-text-3xl tw-mr-2"
        data-test="icon"
      />
      <slot name="unavailable-message" />
    </div>
    <div class="new-shipment-quotation-price__content tw-flex tw-flex-col md:tw-flex-row tw-px-4 md:tw-px-6 tw-pt-5 md:tw-pt-8">
      <div class="new-shipment-quotation-price__content__inner tw-mb-2 md:tw-mb-0">
        <slot />
      </div>
      <div class="new-shipment-quotation-price__content__price">
        <div class="tw-flex tw-flex-col tw-text-right">
          <div
            class="tw-font-bold tw-text-xl"
            data-test="total-vat-excluded"
            v-text="totalVatExcluded"
          />
          <div
            class="tw-text-sm tw-italic"
            data-test="total-vat-included"
            v-text="totalVatIncluded"
          />
        </div>
      </div>
    </div>
    <new-shipment-quotation-price-lines
      v-if="!disabled"
      class="tw-px-4 md:tw-px-6 tw-mb-4"
      :open.sync="isOpen"
      data-test="lines"
    >
      <pricing-layout
        data-test="price"
        class="new-shipment-quotation-price__price-lines__items"
      >
        <pricing-lines
          v-for="line in lines"
          :key="line.key"
          :label="line.label"
          data-test="line"
        >
          <span
            class="tw-text-blue-500 tw-font-bold"
            v-text="line.value"
          />
        </pricing-lines>
      </pricing-layout>
      <slot name="price-surge" />
    </new-shipment-quotation-price-lines>
  </div>
</template>

<script>
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  import PricingLayout from '@/components/CtkPricing/_subs/PricingLayout.vue'
  import PricingLines from '@/components/CtkPricing/_subs/PricingLines/index.vue'

  import NewShipmentQuotationPriceLines from './_subs/NewShipmentQuotationPriceLines/index.vue'
  import NewShipmentQuotationPriceHeader from './_subs/NewShipmentQuotationPriceHeader/index.vue'

  /**
   * @module component - NewShipmentQuotationPrice
   * @param {string} title
   * @param {Array<object>} lines
   * @param {String} totalVatIncluded
   * @param {String} totalVatIncluded
   * @param {boolean} [hasCheck=false]
   */
  export default {
    name: 'NewShipmentQuotationPrice',
    components: {
      PricingLayout,
      PricingLines,
      NewShipmentQuotationPriceLines,
      NewShipmentQuotationPriceHeader
    },
    props: {
      value: {
        type: Boolean,
        default: null
      },
      title: {
        type: String,
        required: true
      },
      totalVatIncluded: {
        type: String,
        required: true
      },
      totalVatExcluded: {
        type: String,
        required: true
      },
      hasCheck: {
        type: Boolean,
        default: false
      },
      lines: {
        type: Array,
        required: true
      },
      open: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      hasUnavailableMessage: {
        type: Boolean,
        default: false
      }
    },
    setup (props) {
      const { state: isOpen } = useModelGetterSetter(props, 'open')

      return {
        isOpen
      }
    }
  }
</script>

<style lang="scss">
.new-shipment-quotation-price {
  border: 1px solid $divider;
}
.new-shipment-quotation-price .new-shipment-quotation-price__price-lines__items {
  padding: 0 !important;
}
.new-shipment-quotation-price .new-shipment-quotation-price__price-lines__items .pricing-lines {
  padding-top: 0;
  padding-bottom: 0.25rem;
  border-bottom: none;
}
.new-shipment-quotation-price .pricing-lines-slot span {
  color: $secondary-text !important;
}
.new-shipment-quotation-price .new-shipment-quotation-price--active .pricing-lines-slot span {
  color: $info !important;
}
.new-shipment-quotation-price .new-shipment-quotation-price__content .markdown, .new-shipment-quotation-price .new-shipment-quotation-price__content .markdown div, .new-shipment-quotation-price .new-shipment-quotation-price__content .markdown p {
  display: inline;
}
@media (min-width: 770px) {
  .new-shipment-quotation-price .new-shipment-quotation-price__content__inner {
    width: 70%;
  }
  .new-shipment-quotation-price .new-shipment-quotation-price__content__price {
    width: 30%;
  }
}
@media (min-width: 1024px) {
  .new-shipment-quotation-price .new-shipment-quotation-price .new-shipment-quotation-price-lines {
    margin-top: -20px;
  }
}
.new-shipment-quotation-price .new-shipment-quotation-price .new-shipment-quotation-price-lines__header.open {
  border-bottom: 1px solid $divider;
}
.new-shipment-quotation-price .new-shipment-quotation-price .new-shipment-quotation-price-lines__header__button {
  color: $secondary-text;
}
.new-shipment-quotation-price .new-shipment-quotation-price--active .new-shipment-quotation-price-lines__header.open {
  border-bottom: 1px solid $info;
}
.new-shipment-quotation-price .new-shipment-quotation-price--active .new-shipment-quotation-price-lines__header__button {
  color: $info;
}
.new-shipment-quotation-price:not(.new-shipment-quotation-price--active) .new-shipment-quotation-price-content__reassurance__content, .new-shipment-quotation-price:not(.new-shipment-quotation-price--active) .new-shipment-quotation-price-content__reassurance__content:hover, .new-shipment-quotation-price:not(.new-shipment-quotation-price--active) a, .new-shipment-quotation-price:not(.new-shipment-quotation-price--active) a:hover {
  color: $secondary-text;
}
.new-shipment-quotation-price:not(.new-shipment-quotation-price--active) .new-shipment-quotation-price-content__reassurance__content {
  border-left: 1px solid $divider;
  border-right: 1px solid $divider;
}
.new-shipment-quotation-price__header {
  color: $info;
  height: 35px;
  background-color: $divider;
}
.new-shipment-quotation-price__content, .new-shipment-quotation-price__content a, .new-shipment-quotation-price__content a:hover {
  color: $secondary-text;
}
.new-shipment-quotation-price--active {
  border: 1px solid $info;
}
.new-shipment-quotation-price--active .new-shipment-quotation-price__header {
  color: white;
  background-color: $info;
}
.new-shipment-quotation-price--active .new-shipment-quotation-price__content, .new-shipment-quotation-price--active .new-shipment-quotation-price__content a {
  --tw-text-opacity: 1;
  color: rgba(40, 118, 150, var(--tw-text-opacity));
}
.new-shipment-quotation-price--active .new-shipment-quotation-price__content a:hover {
  --tw-text-opacity: 1;
  color: rgba(29, 86, 110, var(--tw-text-opacity));
}
.new-shipment-quotation-price--disabled {
  cursor: not-allowed;
}
.new-shipment-quotation-price--disabled .new-shipment-quotation-price__header {
  cursor: not-allowed;
  --tw-text-opacity: 1;
  color: rgba(103, 106, 108, var(--tw-text-opacity));
}
.new-shipment-quotation-price--disabled .new-shipment-quotation-price-header__checkbox {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(103, 106, 108, var(--tw-border-opacity));
}
.new-shipment-quotation-price--disabled .new-shipment-quotation-price__content {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 249, 249, var(--tw-bg-opacity));
  padding-bottom: 1rem;
}
.new-shipment-quotation-price--disabled .new-shipment-quotation-price-content__image {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  --tw-grayscale: grayscale(100%);
}
.new-shipment-quotation-price--disabled .new-shipment-quotation-price__content__price {
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
}
.new-shipment-quotation-price--disabled .new-shipment-quotation-price-content__image__learn-more {
  display: none;
}
</style>
