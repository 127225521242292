var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shipment-payment-form"},[_c('ValidationObserver',{ref:"observer",attrs:{"slim":""}},[_c('form',{staticClass:"tw-w-full",attrs:{"disabled":_vm.$wait.is(_vm.loader) || _vm.expired || (_vm.hasCardsSelect && !_vm.formData.sourceId)},on:{"submit":function($event){$event.preventDefault();return _vm.submitted.apply(null, arguments)}}},[(_vm.getPaymentSources.length > 0)?_c('tabs-bar',{staticClass:"tw-mb-6",attrs:{"tabs":_vm.tabs},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}}):_vm._e(),(_vm.hasCardsSelect)?_c('stripe-card-select',{staticClass:"tw-mb-8",attrs:{"items":_vm.getPaymentSources,"disabled":_vm.$wait.is(_vm.loader) || _vm.expired},on:{"input":_vm.selectCard},model:{value:(_vm.formData.sourceId),callback:function ($$v) {_vm.$set(_vm.formData, "sourceId", $$v)},expression:"formData.sourceId"}}):_vm._e(),(_vm.getPaymentSources.length === 0 || (_vm.getPaymentSources.length > 0 && _vm.currentTab === 1))?_c('fieldset',{staticClass:"stripe-group tw-mb-6",class:{
          'tw-opacity-75': _vm.expired
        }},[_c('legend',{staticClass:"tw-text-sm tw-font-medium tw-text-gray-700",domProps:{"textContent":_vm._s(_vm.$t('app.labels.cc_informations'))}}),_c('ValidationProvider',{attrs:{"name":_vm.$t('app.fields.cc_holder'),"rules":"required|max:255","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var invalid = ref.invalid;
        var validated = ref.validated;
return [_c('ctk-input-text',{staticClass:"tw-mb-6",class:{
                'tw-opacity-75': _vm.expired
              },attrs:{"id":"cardHolder","name":"cardHolder","type":"text","error":!_vm.$wait.is(_vm.loader) && invalid && validated,"hint":errors && errors[0],"disabled":_vm.$wait.is(_vm.loader) || _vm.expired,"label":_vm.$t('app.labels.cc_holder'),"required":"","autocomplete":"name"},model:{value:(_vm.formData.cardHolder),callback:function ($$v) {_vm.$set(_vm.formData, "cardHolder", $$v)},expression:"formData.cardHolder"}})]}}],null,false,2172452900)}),_c('stripe-element-group',{attrs:{"stripe":_vm.stripe},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var stripeElements = ref.stripeElements;
return [_c('stripe-card-number',{ref:"card-number",attrs:{"disabled":_vm.$wait.is(_vm.loader) || _vm.expired,"stripe-elements":stripeElements}}),_c('div',{staticClass:"tw-flex"},[_c('stripe-card-expiry',{staticClass:"tw-w-1/2",attrs:{"disabled":_vm.$wait.is(_vm.loader) || _vm.expired,"stripe-elements":stripeElements}}),_c('stripe-card-cvc',{staticClass:"tw-w-1/2",attrs:{"disabled":_vm.$wait.is(_vm.loader) || _vm.expired,"stripe-elements":stripeElements}})],1)]}}],null,false,3134074178)}),_c('b-form-checkbox',{staticClass:"tw-text-secondary-text tw-mt-4",attrs:{"disabled":_vm.$wait.is(_vm.loader) || _vm.expired},model:{value:(_vm.formData.saveCard),callback:function ($$v) {_vm.$set(_vm.formData, "saveCard", $$v)},expression:"formData.saveCard"}},[_c('div',{staticClass:"tw-inline tw--mt-1"},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('app.labels.save_card'))}}),_c('ui-material-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"tw-text-base tw-align-middle tw-ml-1",attrs:{"title":_vm.$t('app.paragraphs.save_card'),"name":"info"}})],1)])],1):_vm._e(),_vm._t("notice"),_c('ui-button',{staticClass:"tw-w-full tw-mb-2",class:{
          'tw-opacity-75': _vm.expired
        },attrs:{"disabled":_vm.$wait.is(_vm.loader) || _vm.expired || (_vm.hasCardsSelect && !_vm.formData.sourceId),"loading":_vm.$wait.is(_vm.loader),"type":"submit","variant":"primary"},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('ui-material-icon',{attrs:{"name":"lock"}})]},proxy:true}])},[_c('i18n',{attrs:{"path":_vm.buttonLabel,"tag":"span"},scopedSlots:_vm._u([{key:"amount",fn:function(){return [_c('span',{staticClass:"tw-font-medium",domProps:{"textContent":_vm._s(_vm.$options.filters.currency(_vm.amount, _vm.currency, _vm.$i18n.locale))}})]},proxy:true}])})],1)],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }