<template>
  <ValidationObserver
    ref="observer"
    tag="div"
  >
    <form
      class="new-shipment-address-not-found-dialog-search tw-flex tw-flex-col md:tw-flex-row"
      @submit.prevent="submitted"
    >
      <div class="tw-flex md:tw-w-5/12">
        <ctk-input-address-countries
          v-model="countryGetter"
          :disabled-countries="disabledCountries"
          :disabled="$wait.is('geocoding the address')"
          class="flex-fixed"
          @input="countryUpdated"
        />
        <ValidationProvider
          class="tw-relative tw-flex-1"
          rules="required"
          v-click-outside="onBlur"
        >
          <template slot-scope="{ error }">
            <ctk-input-text
              id="city"
              ref="city"
              v-model="formData.city"
              :label="$t('new-shipment.labels.search_address.city')"
              :error="!!error"
              :hint="error"
              :disabled="$wait.is('geocoding the address')"
              :loader="$wait.is('geocoding the address')"
              type="text"
              name="city"
              required
              clearable
              autocomplete="off"
              class="new-shipment-address-not-found-dialog-search__city"
              @input="lazyFetchCities"
              @focus="onFocus"
            />
          </template>
          <transition name="slide">
            <ctk-input-address-predictions
              v-if="(isFocus && predictions.length > 0) || (isFocus && isDirty)"
              ref="predictions"
              :predictions="predictions"
              :prediction="lastPrediction && lastPrediction.id"
              :has-marker="false"
              class="tw-z-50"
              @select="selectPrediction"
            />
          </transition>
        </ValidationProvider>
      </div>

      <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-w-7/12">
        <ValidationProvider
          rules="required"
          class="tw-mb-4 md:tw-mb-0 tw-flex-1"
        >
          <template slot-scope="{ error }">
            <ctk-input-text
              id="address"
              ref="address"
              v-model="formData.address"
              :label="$t(direction === 'pickup'
                ? 'new-shipment.labels.search_address.pickup_address'
                : 'new-shipment.labels.search_address.delivery_address')"
              :error="!!error"
              :hint="error"
              :disabled="$wait.is('geocoding the address')"
              :loader="$wait.is('geocoding the address')"
              type="text"
              name="address"
              required
              clearable
              class="new-shipment-address-not-found-dialog-search__address"
              autocomplete="street-address"
            />
          </template>
        </ValidationProvider>

        <ui-button
          :title="$t('search') | capitalize"
          :disabled="$wait.is('geocoding the address')"
          :loading="$wait.is('geocoding the address')"
          type="submit"
          variant="info"
          class="new-shipment-address-not-found-dialog-search__submit tw-w-full md:tw-w-auto flex-fixed tw-py-0 tw-px-2"
          data-test="submit"
          v-matomo="{
            click: { category: 'Quotations', action: 'Find Address Search' }
          }"
        >
          <div class="tw-flex">
            <ui-ctk-icon
              class="tw-m-auto"
              name="search"
              data-test="icon"
            />
          </div>
        </ui-button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
  import { debounce } from 'underscore'
  import { mapGetters } from 'vuex'
  import { directive } from 'v-click-outside'
  import { defineComponent } from '@vue/composition-api'

  import { availableCountries } from '@/../config/chronoinfos'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  import { showToaster } from '@/services/Toaster'
  import UiButton from '@/components/UI/Button/index.vue'
  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'
  import CtkInputAddressCountries from '@/components/CtkInputs/CtkInputAddress/_subs/CtkInputAddressCountries/index.vue'
  import CtkInputAddressPredictions from '@/components/CtkInputs/CtkInputAddress/_subs/CtkInputAddressPredictions/index.vue'
  import AlgoliaAddressProvider from '@/providers/AddressProvider/AlgoliaAddressProvider'
  import GoogleAddressProvider from '@/providers/AddressProvider/GoogleAddressProvider'
  import AddressComponent from '@/models/AddressComponent'

  /**
   * @module component - NewShipmentAddressNotFoundDialogSearch
   * @param {string} direction
   */
  export default defineComponent({
    name: 'NewShipmentAddressNotFoundDialogSearch',
    components: {
      UiButton,
      CtkInputText,
      CtkInputAddressCountries,
      CtkInputAddressPredictions
    },
    directives: {
      clickOutside: directive
    },
    props: {
      country: {
        type: String,
        required: true
      },
      direction: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        /** @type {{[index:string]: ?string}} */
        formData: {
          /** @type {?string} */
          city: null,
          /** @type {?string} */
          address: null
        },
        provider: new AlgoliaAddressProvider(),
        /** @type {Array<any>} */
        predictions: [],
        isDirty: false,
        isFocus: false,
        lastPrediction: null,
        loadTimeout: null,
        hasError: false
      }
    },
    setup (props) {
      const { state: countryGetter } = useModelGetterSetter(props, 'country')

      return {
        countryGetter
      }
    },
    computed: {
      ...mapGetters('shipments/new-shipment', [
        'getPickupAddress',
        'getDeliveryAddress'
      ]),
      /**
       * Returns a list of disabled countries from the pickup & delivery address
       * data from store.
       * @function disabledCountries
       * @returns {Array<string>}
       */
      disabledCountries () {
        const unauthorizedSameCountries = availableCountries
          .filter(country => country['disabled-for-home'])
          .map(country => country['iso-2'])

        if (this.getPickupAddress && this.direction === 'delivery' && unauthorizedSameCountries.includes(this.getPickupAddress.country)) {
          return [this.getPickupAddress.country]
        }

        if (this.getDeliveryAddress && this.direction === 'pickup' && unauthorizedSameCountries.includes(this.getDeliveryAddress.country)) {
          return [this.getDeliveryAddress.country]
        }

        return []
      }
    },
    mounted () {
      new GoogleAddressProvider().inject()
    },
    methods: {
      submitted () {
        // @ts-ignore
        this.$refs.observer.validate()
          .then((/** @type {boolean} */ valid) => {
            if (!valid) return false

            const provider = new GoogleAddressProvider()
            const { city, address } = this.formData

            if (city && address && this.countryGetter) {
              this.$wait.start('geocoding the address')
              provider.geocode({
                city,
                address,
                country: this.countryGetter
              })
                .then((/** @type {Array<import('@/models/AddressComponent')>} */ results) => {
                  const adresses = results
                    .filter(result => result instanceof AddressComponent && result.hasRequiredComponents)

                  this.$emit('update-results', adresses)
                })
                .catch(err => {
                  if (!err.response) return

                  showToaster(this, this.$t('an_error_has_occurred'), { type: 'error' })
                })
                .finally(() => {
                  this.$wait.end('geocoding the address')
                })
            }

            return true
          })
      },
      onFocus () {
        this.isFocus = true
      },
      onBlur () {
        this.isFocus = false
      },
      /**
       * Called whenever the user updates the country through the select input
       * @function countryUpdated
       * @param {string} country
       */
      countryUpdated (country) {
        this.resetPredictions()
        this.$emit('update-country', country)
      },
      resetPredictions () {
        this.lastPrediction = null
      },
      lazyFetchCities: debounce(function (value) {
        this.fetchCities(value)
      }, 200),
      /**
       * @function selectPrediction
       * @param {string} predictionId
       * @returns {Promise<any>}
       */
      async selectPrediction (predictionId) {
        const prediction = this.predictions
          .find((/** @type {any} */ prediction) => prediction.id === predictionId)

        this.onBlur()

        if (prediction) {
          this.formData.city = prediction.description
          this.lastPrediction = prediction
        }
      },
      /**
       * Request the address predictions whenever the user types in the keyboard
       * @function fetchCities
       * @param {string} value
       */
      fetchCities (value) {
        if (value !== null && value.length === 0) {
          this.resetPredictions()
          return false
        }

        if (!value) {
          return false
        }

        this.$wait.start('fetching cities predictions')
        // @ts-ignore
        if (this.loadTimeout) clearTimeout(this.loadTimeout)

        // @ts-ignore
        this.provider.setCountries([this.countryGetter])
        return this.provider.getPredictions(value)
          .then(predictions => {
            this.predictions = predictions

            return predictions
          })
          .finally(() => {
            // @ts-ignore
            this.loadTimeout = setTimeout(() => {
              this.$wait.end('fetching cities predictions')
            }, 500)
          })
      }
    }
  })
</script>

<style lang="scss">

  .new-shipment-address-not-found-dialog-search .modal-container {
    overflow: scroll;
  }

  @media only screen and (min-width: $breakpoint-tablet) {
    .new-shipment-address-not-found-dialog-search__city .ctk-input-text__field {
      border-right: 1px solid transparent;
    }
  }

  .new-shipment-address-not-found-dialog-search .ctk-input-address-countries .ctk-input-select__wrapper {
    border-right: 1px solid transparent;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .new-shipment-address-not-found-dialog-search__city .ctk-input-text__field,
  .new-shipment-address-not-found-dialog-search__address .ctk-input-text__field {
    border-radius: 0;
  }

  .new-shipment-address-not-found-dialog-search {
    &__submit {
      height: 42px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

</style>
