<template>
  <div class="shipment-payment-view tw-p-4 md:tw-p-8 md:tw-pt-18">
    <new-shipment-acceptation-payment-expiration
      v-if="hasExpiration"
      :expires-at="expiresAt"
      :expired-label="expiredLabel"
      :expired="expired"
      class="tw-mb-12"
      data-test="expiration"
    />

    <new-shipment-acceptation-payment-amount
      :label="amountLabel"
      :expired="expired"
      :initial-amount="initialAmount"
      :amount="amount"
      :currency="currency"
      data-test="amount"
      @renew="$emit('renew')"
    />

    <shipment-payment-form
      ref="payment-form"
      :stripe="stripe"
      :button-label="buttonLabel"
      :amount="amount"
      :currency="currency"
      :loader="loader"
      :expired="expired"
      data-test="payment-form"
      @submitted="submitted"
    >
      <template #notice>
        <shipment-payment-view-notice
          v-if="hasNotice"
          :notice="notice"
          data-test="notice-content"
        />
      </template>
    </shipment-payment-form>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import Hotjar from '@/plugins/VueHotjar'
  import { isTesting } from '@/../utils/env'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  import NewShipmentAcceptationPaymentExpiration from '@/views/Shippers/NewShipment/_subs/NewShipmentInformations/components/NewShipmentAcceptationPaymentDialog/_subs/NewShipmentAcceptationPaymentExpiration/index.vue'
  import NewShipmentAcceptationPaymentAmount from '@/views/Shippers/NewShipment/_subs/NewShipmentInformations/components/NewShipmentAcceptationPaymentDialog/_subs/NewShipmentAcceptationPaymentAmount/index.vue'
  import ShipmentPaymentViewNotice from './_subs/ShipmentPaymentViewNotice/index.vue'
  import ShipmentPaymentForm from './_subs/ShipmentPaymentForm/index.vue'

  import useExpirationAfterDateTime from '@/composables/useExpirationAfterDateTime'

  /**
   * Generic component including the payment form with it's suroundings.
   * This component is meant to be used as a base for other components with specific
   * usage cases.
   *
   * @module component - ShipmentPaymentView
   * @param {boolean} [value=false]
   * @param {boolean} [confirmation=false]
   * @param {boolean} [error=false]
   * @param {boolean} [hasExpiration=true]
   * @param {object} [shipment=null]
   * @param {string|Date} [expiresAt=null] - The expiring date, used to disable the form if it's reached.
   * @param {string} [expiredLabel=null] - Wording shown when the expiration has reached its term.
   * @param {string} buttonLabel - Wording shown in the "pay" button. Should be the translation key.
   * @param {string} loader - The loader key to be used
   * @param {number} [initialAmount=null] - The value for an initial amount shown
   * that's line-through
   * @param {number} amount - Amount to be paid
   * @param {string} amountLabel - Label shown near the amount to be paid
   * @param {string} currency - Currency used for initialAmount and amount
   * @param {boolean} [hasNotice=false] - Should show the notice paragrah or not
   * @param {string} notice - Wording to be used in the notice paragraph
   */
  export default {
    name: 'ShipmentPaymentView',
    components: {
      NewShipmentAcceptationPaymentExpiration,
      NewShipmentAcceptationPaymentAmount,
      ShipmentPaymentForm,
      ShipmentPaymentViewNotice
    },
    props: {
      value: {
        type: Boolean,
        default: true
      },
      stripe: {
        type: Object,
        default: null
      },
      confirmation: {
        type: Boolean,
        default: false
      },
      initialAmount: {
        type: Number,
        default: null
      },
      amount: {
        type: Number,
        required: true
      },
      currency: {
        type: String,
        required: true
      },
      loader: {
        type: String,
        required: true
      },
      error: {
        type: Boolean,
        default: false
      },
      notice: {
        type: String,
        default: null
      },
      hasNotice: {
        type: Boolean,
        default: false
      },
      hasExpiration: {
        type: Boolean,
        default: true
      },
      shipment: {
        type: Object,
        default: null
      },
      amountLabel: {
        type: String,
        required: true
      },
      buttonLabel: {
        type: String,
        required: true
      },
      expiredLabel: {
        type: String,
        default: null
      },
      expiresAt: {
        type: [String, Date],
        default: null
      }
    },
    setup (props) {
      // @ts-ignore
      const { expired } = useExpirationAfterDateTime(props.expiresAt)
      const { state: dialogValue } = useModelGetterSetter(props, 'value')

      return {
        expired,
        dialogValue
      }
    },
    data () {
      return {
        currentTab: 0,
        formData: {
          sourceId: null,
          cardHolder: null,
          saveCard: false
        }
      }
    },
    async mounted () {
      Hotjar.tag('Payment Dialog')
    },
    computed: {
      ...mapGetters('auth', [
        'getPaymentSources'
      ])
    },
    methods: {
      /**
       * Called whenever the form is submitted
       * @function submitted
       * @param {object} params
       * @param {string} params.cardHolder
       * @param {string?} params.sourceId
       * @param {boolean} params.saveCard
       * @param {any?} params.cardElement
       */
      submitted (params) {
        /**
         * Since during test mode, we cannot pass through Stripe, we must ignore
         * the integration.
         */
        if (isTesting) {
          this.$emit('submitted', {
            id: 'pm_fake_id',
            type: 'stripe_payment_method'
          })
        } else {
          this.$wait.start(this.loader)

          if (params.sourceId) {
            this.$emit('submitted', {
              id: params.sourceId,
              type: 'stripe_payment_method'
            })
          } else {
            this.stripe.createPaymentMethod({
              type: 'card',
              card: params.cardElement,
              billing_details: {
                name: params.cardHolder
              }
            })
              .then(res => {
                if (res.error && res.error.type === 'validation_error') {
                  this.$wait.end(this.loader)
                  return
                }

                /**
                 * TODO: Handle errors
                 */
                if (!res.error) {
                  this.$emit('submitted', {
                    id: res.paymentMethod.id,
                    type: 'stripe_payment_method',
                    saveCard: params.saveCard
                  })
                }
              })
          }
        }
      }
    }
  }
</script>
