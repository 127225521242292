<template>
  <div
    class="new-shipment-dates-direction"
    :class="[`--${direction}`]"
  >
    <slot name="title">
      <h3 class="new-shipment-dates-direction__title tw-font-normal tw-flex tw-items-center">
        <ui-ctk-icon
          name="calendar"
          data-test="icon"
        /> {{ $t(`new-shipment.titles.dates.${direction}`) }}
      </h3>
    </slot>
    <slot />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - NewShipmentDatesDirection
   * @param {string} direction
   */
  export default defineComponent({
    name: 'NewShipmentDatesDirection',
    props: {
      direction: {
        type: String,
        required: true
      }
    }
  })
</script>

<style lang="scss" scoped>

  .new-shipment-dates-direction {
    &__title {
      position: relative;
      left: -4px;
      font-size: 16px;

      .ctk-font {
        font-size: 24px;
      }
    }

    &.--pickup .new-shipment-dates-direction__title .ctk-font {
      color: $info;
    }

    &.--delivery .new-shipment-dates-direction__title .ctk-font {
      color: $primary;
    }
  }

</style>
