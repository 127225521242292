<template>
  <button
    :class="{
      active
    }"
    :title="title"
    type="button"
    class="btn new-shipment-handling-button"
  >
    <div class="new-shipment-handling-button__container">
      <ui-ctk-icon
        :name="icon"
        class="new-shipment-handling-button__icon"
        data-test="icon"
      />
      <div
        class="new-shipment-handling-button__title"
        data-test="title"
      >
        {{ title }}
      </div>
      <div
        v-if="subtitle"
        class="new-shipment-handling-button__subtitle"
        data-test="subtitle"
      >
        {{ subtitle }}
      </div>
    </div>
  </button>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - NewShipmentHandlingButton
   * @param {string} [active=false]
   * @param {string} icon
   * @param {string} title
   * @param {string} [subtitle=null]
   */
  export default defineComponent({
    name: 'NewShipmentHandlingButton',
    props: {
      active: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      subtitle: {
        type: String,
        default: null
      }
    }
  })
</script>

<style lang="scss" scoped>

  .new-shipment-handling-button {
    border: 1px solid $divider;
    color: #979797;
    min-height: 100px;

    &__icon {
      font-size: 40px;
    }

    &__title {
      font-size: 14px;
    }

    &__subtitle {
      font-size: 12px;
    }

    &.active {
      border: 1px solid $info;
      background-color: $info;
      color: white;
    }

    &:hover,
    &:focus {
      box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
    }

    &:hover:not(.active),
    &:focus:not(.active) {
      border: 1px solid #F9F9F9;
      background-color: #F9F9F9;
    }

    &:hover.active,
    &:focus-visible.active {
      background-color: lighten($info, 5%);
    }
  }

</style>
