<template>
  <div class="new-shipment-quotation-price-content tw-flex tw-flex-col-reverse 2sm:tw-flex-row 2sm:tw-items-center">
    <div class="new-shipment-quotation-price-content__image tw-flex tw-flex-col 2sm:tw-items-center md:tw-mr-4">
      <img
        :src="icon"
        alt=""
        width="65"
        height="53"
        class="2sm:tw-mx-auto tw-mb-2 tw-ml-4 md:tw-ml-auto"
      >
      <ui-button
        class="new-shipment-quotation-price-content__image__learn-more tw-italic tw-whitespace-nowrap tw-mr-auto tw-ml-0 md:tw-ml-auto"
        variant="link"
        size="sm"
        v-matomo="{
          click: { category: 'Quotations', action: 'Click Learn More', name: eventName },
        }"
        @click="$emit('learn-more', offerType)"
      >
        {{ $t('new-shipment.labels.learn_more') }}
      </ui-button>
    </div>
    <div
      class="new-shipment-quotation-price-content__reassurance tw-flex-1"
    >
      <offer-reassurance-list
        class="new-shipment-quotation-price-content__reassurance__content"
        :offer-type="offerType"
      />
    </div>
  </div>
</template>

<script>
  import OfferReassuranceList from '@/views/Shippers/components/OfferReassuranceList/index.vue'

  /**
   * @module component - NewShipmentQuotationPriceContent
   * @param {string} offerType
   * @emits learn-more
   */
  export default {
    name: 'NewShipmentQuotationPriceContent',
    components: {
      OfferReassuranceList
    },
    props: {
      offerType: {
        type: String,
        required: true
      }
    },
    computed: {
      /**
       * @function icon
       * @returns {string}
       */
      icon () {
        const icons = {
          chronotruck: require('@/assets/img/icons/truck-classic.svg'),
          pallet_network: require('@/assets/img/icons/truck-network.svg'),
          corridor: require('@/assets/img/icons/truck-lines.svg')
        }

        // @ts-ignore
        return icons[this.offerType]
      },
      /**
       * Returns the corresponding Matomo event for the offer type.
       * Used to avoid breaking the Matomo event tracking for now.
       * TODO: Must be removed whenever possible.
       * @function eventName
       * @returns {string}
       */
      eventName () {
        const eventNamesByOfferType = {
          chronotruck: 'classic',
          corridor: 'lines',
          pallet_network: 'network'
        }

        // @ts-ignore
        return eventNamesByOfferType[this.offerType]
      }
    }
  }
</script>

<style lang="scss" scoped>
@media (min-width: 455px) {
  .new-shipment-quotation-price-content__image {
    width: 35%;
  }
  .new-shipment-quotation-price-content__reassurance {
    width: 65%;
  }
}
@media (min-width: 770px) {
  .new-shipment-quotation-price-content__reassurance__content {
    font-size: 0.75rem;
    border-left: 1px solid $info;
    border-right: 1px solid $info;
    padding: 0 1rem;
  }
}
</style>
