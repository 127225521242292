<template>
  <div class="new-shipment-address-not-found-dialog-map">
    <l-map
      ref="map"
      :zoom="2"
      :max-zoom="mapData.maxZoom"
      :min-zoom="mapData.minZoom"
      :options="mapData.options"
      :center="mapData.center"
      class="leaflet-map"
    >
      <l-tile-layer
        :url="mapData.url"
        :attribution="mapData.attribution"
      />

      <!-- Single offer markers -->
      <l-feature-group
        v-if="markers.length > 0"
        ref="markers"
      >
        <l-marker
          v-for="marker in markers"
          :ref="`marker-${marker.id}`"
          :key="marker.id"
          :lat-lng="marker.location"
          :icon="marker.icon"
          @click="select(marker)"
          @mouseover="$emit('mouseover-result', marker)"
          @mouseout="$emit('mouseout-result', marker)"
        />
      </l-feature-group>
    </l-map>
  </div>
</template>

<script>
  import {
    LMap,
    LMarker,
    LTileLayer,
    LFeatureGroup
  } from 'vue2-leaflet'

  import { fitBoundsOptions } from '@/services/Leaflet'
  import useMap from '@/composables/useMap'

  /**
   * @typedef Marker
   * @type {object}
   * @property {string} id
   * @property {any} icon
   * @property {object} location
   * @property {number} location.lat
   * @property {number} location.lng
   * @property {any} component
   */

  /**
   * @module component - NewShipmentAddressNotFoundDialogMap
   * @param {string} [selectedResult=null] - The selected result id
   * @param {Array<Marker>} [markers=[]] - List of markers
   */
  export default {
    name: 'NewShipmentAddressNotFoundDialogMap',
    components: {
      LMap,
      LMarker,
      LTileLayer,
      LFeatureGroup
    },
    props: {
      selectedResult: {
        type: String,
        default: null
      },
      markers: {
        type: Array,
        default: () => ([])
      }
    },
    setup () {
      const { mapData } = useMap()

      return {
        mapData
      }
    },
    methods: {
      /**
       * @function select
       * @param {Object} marker
       */
      select (marker) {
        this.$emit('select-result', marker)
      },
      /**
       * Fly the map to the current markers displayed in the map
       * @function flyToMarkers
       */
      flyToMarkers () {
        // @ts-ignore
        const markers = this.$refs.markers && this.$refs.markers.mapObject
        // @ts-ignore
        const map = this.$refs.map && this.$refs.map.mapObject

        if (map && markers) {
          const bounds = markers.getBounds()
          map.invalidateSize()
          map.flyToBounds(bounds, fitBoundsOptions)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .new-shipment-address-not-found-dialog-map {
    &,
    .leaflet-map {
      border-radius: 0 0.25rem 0.25rem 0;
    }

    &-container,
    .leaflet-map {
      z-index: 0;
      width: 100%;
      height: 360px;
      max-height: 100vh;
      overflow: hidden;
    }
  }

</style>
