<template>
  <ui-button
    type="button"
    variant="link"
    class="new-shipment-address-not-found-dialog-contact !tw-text-left tw-flex tw-items-center !tw-bg-white tw-text-sm tw-py-2"
    v-matomo="{
      click: { category: 'Quotations', action: 'Find Address Click Help' }
    }"
    @click.prevent.capture="open = !open"
  >
    <div class="tw-flex tw-items-center tw-justify-between tw-text-blue-500">
      <template
        v-if="open"
      >
        <i18n
          tag="span"
          path="new-shipment.paragraphs.search_address.contact"
          data-test="contact-paragraph"
        >
          <template #phone>
            <span
              v-text="phone"
              class="tw-whitespace-nowrap"
              data-test="phone"
            />
          </template>
        </i18n>
      </template>
      <template
        v-else
      >
        <div
          class="tw-flex tw-items-center"
          data-test="not-found-paragraph"
        >
          <ui-ctk-icon
            class="tw-mr-2"
            name="phone-help"
            data-test="phone-icon"
          />
          <span
            class="tw-font-bold"
            v-text="$t('new-shipment.paragraphs.search_address.not_found')"
            data-test="not-found-paragraph-content"
          />
        </div>
      </template>
      <div class="tw-flex tw-items-center tw-ml-4">
        <ui-material-icon
          :name="open ? 'remove_circle_outline' : 'add_circle_outline'"
          data-test="icon"
        />
      </div>
    </div>
  </ui-button>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { phones } from '@/../config/chronoinfos'

  import UiButton from '@/components/UI/Button'

  /**
   * @module component - NewShipmentAddressNotFoundDialogContact
   */
  export default {
    name: 'NewShipmentAddressNotFoundDialogContact',
    components: {
      UiButton
    },
    data () {
      return {
        open: false
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getCompanyAnimation'
      ]),
      /**
       * @function phone
       * @returns {string}
       */
      phone () {
        return this.getCompanyAnimation && this.getCompanyAnimation.phone
          ? this.$options.filters.telephone(this.getCompanyAnimation.phone)
          : phones['address-not-found'].text
      }
    }
  }
</script>

<style lang="scss" scoped>

  .new-shipment-address-not-found-dialog-contact {
    border-left: 5px solid $info;
    min-height: 53px;
    border-top: 0;
    border-bottom: 0;

    @media only screen and (min-width: $breakpoint-tablet) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &,
    &:focus {
      box-shadow: rgba(#B9A1A1, 0.1) 1px 2px 4px 0;
    }
  }

</style>
