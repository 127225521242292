var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-shipment-dates-optimal-direction tw-flex tw-flex-col"},[_c('div',{staticClass:"tw-flex tw-items-center"},[_c('ui-ctk-icon',{staticClass:"tw--ml-1 tw-text-3xl",class:{
        'tw-text-blue-500': _vm.direction === 'pickup',
        'tw-text-green-500': _vm.direction === 'delivery'
      },attrs:{"name":"calendar"}}),_c('div',{domProps:{"textContent":_vm._s(_vm.$t(_vm.direction === 'pickup'
        ? 'new-shipment.titles.dates.pickup'
        : 'new-shipment.titles.dates.delivery'))}})],1),_c('div',{staticClass:"tw-flex"},[_c('div',{staticClass:"tw-flex tw-flex-1 tw-flex-col tw-bg-white tw-rounded tw-mr-1 tw-p-3 tw-leading-snug"},[_c('div',{staticClass:"new-shipment-dates-optimal-direction__label tw-text-xs",domProps:{"textContent":_vm._s(_vm.$t(_vm.direction === 'pickup'
          ? 'new-shipment.labels.dates.from_the'
          : 'new-shipment.labels.dates.until'))}}),_c('div',{staticClass:"tw-flex tw-items-center"},[_c('div',{staticClass:"new-shipment-dates-optimal-direction__value tw-font-medium",domProps:{"textContent":_vm._s(_vm.$moment(_vm.date).format('LL'))}}),(_vm.editable)?_c('a',{staticClass:"tw-text-blue-500 hover:tw-text-blue-600 tw-italic tw-ml-2 tw-text-xs",attrs:{"href":"#","role":"button"},domProps:{"textContent":_vm._s(_vm.$options.filters.capitalize(_vm.$t('edit')))},on:{"click":function($event){$event.preventDefault();return _vm.$emit('edit')}}}):_vm._e()])]),_c('div',{staticClass:"tw-flex tw-bg-white tw-rounded"},[_c('ctk-time-slot-range',{staticClass:"tw-m-auto tw-px-2 tw-text-xs",attrs:{"start-time":_vm.startTime,"end-time":_vm.endTime}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }