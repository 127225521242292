<script>
  import { mapGetters } from 'vuex'

  import CtkInputAddress from '@/components/CtkInputs/CtkInputAddress'
  import AddressProvider from '@/providers/AddressProvider'

  /**
   * @module component - AutocompleteAddressInput
   * @param {string} direction - Either "pickup" or "delivery"
   * @param {string} [error=null]
   */
  export default {
    name: 'AutocompleteAddressInput',
    components: {
      CtkInputAddress
    },
    render (createElement) {
      return createElement(CtkInputAddress, {
        class: {
          'autocomplete-address-input': true
        },
        props: {
          label: this.$t(`new-shipment.labels.address.${this.direction}`),
          'fetched-addresses': this.getFetchedAddresses[this.direction],
          provider: this.provider,
          hasHelp: true,
          ...this.$props
        },
        ref: 'address-input',
        on: this.$listeners
      })
    },
    props: {
      direction: {
        type: String,
        required: true
      },
      disabledCountries: {
        type: Array,
        default: () => []
      },
      country: {
        type: String,
        default: 'FR'
      },
      value: {
        type: [String, Number],
        default: null
      },
      error: {
        type: String,
        default: null
      }
    },
    data () {
      return {
        provider: AddressProvider
      }
    },
    computed: {
      ...mapGetters('shipments/new-shipment', [
        'getFetchedAddresses'
      ])
    },
    methods: {
      resetPredictions () {
        this.$refs['address-input'].resetPredictions()
      }
    }
  }
</script>
