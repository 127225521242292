<template>
  <ctk-summary-card
    :title="offers[getQuotationSelectedPrice.type]"
    class="new-shipment-acceptation-dialog-summary"
  >
    <template #pickup>
      <shipment-summary-card-direction
        :timeslot="timeslot('pickup')"
        :name="name('pickup')"
        direction="pickup"
        class="tw-mb-3"
        data-test="pickup"
      />
    </template>

    <template #delivery>
      <shipment-summary-card-direction
        :timeslot="timeslot('delivery')"
        :name="name('delivery')"
        direction="delivery"
        class="tw-mb-3"
        data-test="delivery"
      />
    </template>

    <template #load>
      <shipment-summary-card-load
        :load="getLoad"
        :pallets="getPallets"
        data-test="load"
      />
    </template>

    <template
      v-if="hasPrice && getQuotation && getQuotationSelectedPrice"
      #price
    >
      <price-total
        v-if="hasPrice && getQuotation && getQuotationSelectedPrice"
        :price-included="`${$t('price_incl_tax', {
          price: $options.filters.currency(getQuotationSelectedPrice.total_including_vat, getQuotationSelectedPrice.currency, $i18n.locale)
        })}${priceSurge ? '*' : ''}`"
        :price-excluded="`${$t('price_excl_tax', {
          price: $options.filters.currency(getQuotationSelectedPrice.total, getQuotationSelectedPrice.currency, $i18n.locale)
        })}${priceSurge ? '*' : ''}`"
        data-test="total"
      />
    </template>
  </ctk-summary-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  // @ts-ignore
  import { offers } from '@/../config/chronoinfos'

  import CtkSummaryCard from '@/components/CtkSummaryCard/index.vue'
  import ShipmentSummaryCardDirection from '@/views/Shippers/Shipment/_subs/ShipmentSummary/_subs/ShipmentSummaryCard/_subs/ShipmentSummaryCardDirection/index.vue'
  import ShipmentSummaryCardLoad from '@/views/Shippers/Shipment/_subs/ShipmentSummary/_subs/ShipmentSummaryCard/_subs/ShipmentSummaryCardLoad/index.vue'
  import ShipmentSummaryCardPriceType from '@/views/Shippers/Shipment/_subs/ShipmentSummary/_subs/ShipmentSummaryCard/_subs/ShipmentSummaryCardPriceType/index.vue'
  import PriceTotal from '@/components/CtkPricing/_subs/PriceTotal/index.vue'

  /**
   * @module component - ShipmentSummaryCard
   * @param {object} shipment
   * @param {boolean} [hasPrice=false]
   */
  export default defineComponent({
    name: 'ShipmentSummaryCard',
    components: {
      CtkSummaryCard,
      ShipmentSummaryCardDirection,
      ShipmentSummaryCardLoad,
      ShipmentSummaryCardPriceType,
      PriceTotal
    },
    props: {
      shipment: {
        type: Object,
        required: true
      },
      hasPrice: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        offers
      }
    },
    computed: {
      ...mapGetters('shipments/new-shipment', [
        'getQuotation',
        'getQuotationSelectedPrice',
        'getLoad',
        'getPallets'
      ]),
      /**
       * Returns the quotation price surge
       * @function priceSurge
       * @returns {string|null}
       */
      priceSurge () {
        const price = this.getQuotationSelectedPrice
        return price && price.price_surge
      },
      /**
       * @function name
       * @param {string} direction
       */
      name () {
        return (/** @type {string} */ direction) => this.shipment[direction].address.name
      },
      /**
       * @function timeslot
       * @param {string} direction
       */
      timeslot () {
        return (/** @type {string} */ direction) => this.shipment[direction].time_slot
      }
    }
  })
</script>
