<template>
  <div class="new-shipment-sidebar-group-item-date dots-text tw-flex tw-flex-col tw-justify-between tw-flex-1">
    <i18n
      :path="flexible
        ? `new-shipment.labels.selected_dates.${direction}.date_flexible`
        : `new-shipment.labels.selected_dates.${direction}.date`"
      tag="div"
      data-test="label"
    >
      <template #date>
        <span
          class="tw-font-medium"
          data-test="date"
        >
          {{ date | moment('ddd D MMMM') }}
        </span>
      </template>
    </i18n>
    <div
      class="new-shipment-sidebar-group-item-date__timeslot tw-text-gray-700 dots-text fs-13"
      data-test="timeslot"
    >
      <span data-test="start-time">{{ startTime }}</span> - <span data-test="end-time">{{ endTime }}</span>
    </div>
  </div>
</template>

<script>
  /**
   * @module component - NewShipmentSidebarGroupItemDate
   * @param {boolean} flexible
   * @param {string} direction
   * @param {string} date
   * @param {Array} timeslot
   */
  export default {
    name: 'NewShipmentSidebarGroupItemDate',
    props: {
      flexible: {
        type: Boolean,
        required: true
      },
      direction: {
        type: String,
        required: true
      },
      timeslot: {
        type: Array,
        required: true
      },
      date: {
        type: String,
        required: true
      }
    },
    computed: {
      /**
       * Returns the formated start time
       * @function startTime
       * @returns {string}
       */
      startTime () {
        return this.$moment().startOf('day').minute(this.timeslot[0]).format('LT')
      },
      /**
       * Returns the formated start time
       * @function endTime
       * @returns {string}
       */
      endTime () {
        return this.$moment().startOf('day').minute(this.timeslot[1]).format('LT')
      }
    }
  }
</script>

<style lang="scss" scoped>

  .new-shipment-sidebar-group-item-date {
    line-height: 1.2em;
    color: $primary-text;
  }

</style>
