<template>
  <div class="new-shipment-template-list-item-address tw-flex">
    <div class="tw-mr-2">
      <img
        :src="require(`@/assets/img/icons/map/in_progress_${direction}.svg`)"
        alt=""
        width="14"
        height="20"
      >
    </div>
    <div class="tw-flex tw-flex-col">
      <span
        v-if="address.name"
        class="tw-font-medium"
        data-test="name"
        v-text="address.name"
      />
      <ctk-address
        :address="address"
      />
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkAddress from '@/components/CtkAddress/index.vue'

  /**
   * @module component - NewShipmentTemplateListItemAddress
   * @param {string} direction
   * @param {object} shipment
   */
  export default defineComponent({
    name: 'NewShipmentTemplateListItemAddress',
    components: {
      CtkAddress
    },
    props: {
      direction: {
        type: String,
        required: true
      },
      shipment: {
        type: Object,
        required: true
      }
    },
    computed: {
      // @ts-ignore
      address () {
        // @ts-ignore
        return this.shipment[this.direction].address
      }
    }
  })
</script>
