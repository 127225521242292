<template>
  <new-shipment-alert>
    <i18n
      :path="'new-shipment.paragraphs.flexible'"
      tag="p"
      class="tw-mb-0"
      data-test="paragraph"
    >
      <template #pickup_date>
        <span
          class="tw-font-medium"
          data-test="pickup"
        >
          {{ pickupDate | moment('ddd D MMMM') }}
        </span>
      </template>
      <template #delivery_date>
        <span
          class="tw-font-medium"
          data-test="delivery"
        >
          {{ deliveryDate | moment('ddd D MMMM') }}
        </span>
      </template>
    </i18n>
  </new-shipment-alert>
</template>

<script>
  import NewShipmentAlert from '@/views/Shippers/NewShipment/components/NewShipmentAlert/index.vue'

  /**
   * @module component - NewShipmentDatesFlexibleExplanation
   * @param {string} pickupDate
   * @param {string} deliveryDate
   */
  export default {
    name: 'NewShipmentDatesFlexibleExplanation',
    components: {
      NewShipmentAlert
    },
    props: {
      pickupDate: {
        type: String,
        required: true
      },
      deliveryDate: {
        type: String,
        required: true
      }
    }
  }
</script>
