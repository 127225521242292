<template>
  <router-link
    :to="to"
    class="new-shipment-template-link tw-flex tw-flex-col tw-justify-center tw-items-center tw-text-center tw-rounded tw-bg-gray-200 tw-py-8 hover:tw-bg-gray-100 hover:tw-shadow-md hover:tw-no-underline tw-px-4"
  >
    <div
      v-text="title"
      class="tw-text-xl tw-text-blue-500"
      data-test="title"
    />
    <div
      v-text="subtitle"
      class="new-shipment-template-link__subtitle tw-relative tw-text-sm tw-text-gray-600"
      data-test="subtitle"
    />
  </router-link>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - NewShipmentTemplateLink
   * @param {Object|String} to
   * @param {String} title
   * @param {String} subtitle
   */
  export default defineComponent({
    name: 'NewShipmentTemplateLink',
    props: {
      to: {
        type: [Object, String],
        required: true
      },
      title: {
        type: String,
        required: true
      },
      subtitle: {
        type: String,
        required: true
      }
    }
  })
</script>

<style lang="scss" scoped>

  .new-shipment-template-link {
    background-color: #F5F5F5;
    height: 200px;
    transition: background-color 200ms ease-in-out, box-shadow 200ms ease-in-out;

    &:hover {
      background-color: #F9F9F9;
    }

    &__subtitle {
      &::after {
        content: '';
        position: absolute;
        bottom: -1rem;
        left: 0;
        right: 0;
        margin: auto;
        height: 2px;
        width: 70%;
        background-color: $divider;
        border-bottom: 1px solid white;
      }
    }
  }

</style>
