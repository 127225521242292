<template>
  <new-shipment-types-item
    :title="title"
    :active="active"
    class="new-shipment-goods-types-item"
  >
    <ui-ctk-icon
      :name="icon"
      class="new-shipment-goods-types-item__icon"
      data-test="icon"
    />
    <div
      class="new-shipment-goods-types-item__title tw-font-medium tw-text-sm"
      v-text="title"
      data-test="title"
    />
    <div
      v-if="subtitle"
      class="new-shipment-goods-types-item__subtitle tw-text-xs"
      v-text="subtitle"
      data-test="subtitle"
    />
  </new-shipment-types-item>
</template>

<script>
  import NewShipmentTypesItem from '@/views/Shippers/NewShipment/components/NewShipmentTypesItem/index.vue'

  /**
   * @module component - NewShipmentGoodsTypesItem
   * @param {string} [active=false]
   * @param {string} icon
   * @param {string} title
   * @param {string} [subtitle=null]
   */
  export default {
    name: 'NewShipmentGoodsTypesItem',
    components: {
      NewShipmentTypesItem
    },
    props: {
      active: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      subtitle: {
        type: String,
        default: null
      }
    }
  }
</script>

<style lang="scss" scoped>

  .new-shipment-goods-types-item {
    &__icon {
      font-size: 50px;
    }
  }

</style>
