<template>
  <ctk-dialog
    :value="dialogValue"
    modal-class="new-shipment-address-not-found-dialog"
    :persistent="true"
    max-width="765px"
    hide-header
    hide-footer
  >
    <div class="new-shipment-address-not-found-dialog__wrapper tw-relative">
      <button
        :title="$t('close') | capitalize"
        type="button"
        class="new-shipment-address-not-found-dialog__header__close tw-absolute tw-right-0 tw-top-0"
        data-test="close"
        @click="close"
      >
        <ui-ctk-icon
          name="close"
          data-test="icon"
        />
      </button>

      <div class="tw-flex tw-flex-col tw-px-4 2sm:tw-px-8 tw-pt-8">
        <h1
          data-test="title"
          class="new-shipment-address-not-found-dialog__header__title tw-text-2xl tw-mb-0 tw-font-medium tw-text-secondary"
          v-text="$t('new-shipment.titles.search_address')"
        />
        <p
          data-test="subtitle"
          v-text="$t('new-shipment.paragraphs.search_address')"
          class="new-shipment-address-not-found-dialog__header__explanation tw-text-sm"
        />
      </div>

      <div class="new-shipment-address-not-found-dialog__content tw-px-4 2sm:tw-px-8 tw-pt-4">
        <new-shipment-address-not-found-dialog-search
          class="tw-mb-5"
          data-test="search-form"
          :country.sync="country"
          :direction="direction"
          @update-results="updateResults"
        />
        <div class="tw-flex tw-flex-col md:tw-flex-row">
          <template
            v-if="requestedResults"
          >
            <div
              class="new-shipment-address-not-found-dialog__content__side md:tw-w-5/12 tw-relative tw-flex tw-flex-col tw-justify-between flex-fixed"
            >
              <template
                v-if="results.length > 0"
              >
                <new-shipment-address-not-found-dialog-results
                  v-model="selectedResultId"
                  :results="formattedResults"
                  class="tw-mb-4 tw-flex-1"
                  data-test="results"
                  @mouseover-item="mouseoverResult"
                  @mouseout-item="mouseoutResult"
                />
              </template>
              <template
                v-else
              >
                <div
                  class="new-shipment-address-not-found-dialog__no-results tw-rounded tw-bg-white tw-h-full tw-mb-4 tw-flex-1"
                  data-test="no-results"
                >
                  <p
                    class="tw-px-4 tw-py-2 tw-border-b tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-gray-300 tw-border-solid"
                    v-text="$t('new-shipment.paragraphs.address_not_found.no_result')"
                  />
                </div>
              </template>
              <new-shipment-address-not-found-dialog-contact
                class="tw-shadow tw-mb-4 md:tw-mb-0"
                data-test="contact"
              />
            </div>
          </template>
          <new-shipment-address-not-found-dialog-map
            ref="map"
            :select-result="selectedResultId"
            :markers="markers"
            :class="[
              results.length > 0 ? 'md:tw-w-7/12' : 'md:tw-w-full'
            ]"
            data-test="map"
            @select-result="selectResult"
            @mouseover-result="mouseoverResult"
            @mouseout-result="mouseoutResult"
          />
        </div>
      </div>

      <div
        class="new-shipment-address-not-found-dialog__footer tw-flex tw-justify-end tw-px-4 2sm:tw-px-8 tw-pt-4 tw-pb-8"
      >
        <ui-button
          :disabled="!selectedResultId"
          type="button"
          variant="primary"

          class="tw-rounded-full tw-w-full md:tw-w-auto tw-px-4 tw-text-white"
          data-test="validate"
          v-matomo="{
            click: { category: 'Quotations', action: 'Find Address Validate' }
          }"
          @click="validateSelection"
        >
          {{ $t('new-shipment.buttons.address_not_found.validate') }}
        </ui-button>
      </div>
    </div>
  </ctk-dialog>
</template>

<script>
  import { defineComponent, ref, watch } from '@vue/composition-api'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  import AddressComponent from '@/models/AddressComponent'
  import CtkDialog from '@/components/CtkDialog/index.vue'
  import UiButton from '@/components/UI/Button/index.vue'

  import NewShipmentAddressNotFoundDialogSearch from './_subs/NewShipmentAddressNotFoundDialogSearch/index.vue'
  import NewShipmentAddressNotFoundDialogResults from './_subs/NewShipmentAddressNotFoundDialogResults/index.vue'
  import NewShipmentAddressNotFoundDialogMap from './_subs/NewShipmentAddressNotFoundDialogMap/index.vue'
  import NewShipmentAddressNotFoundDialogContact from './_subs/NewShipmentAddressNotFoundDialogContact/index.vue'
  import { icons } from '@/services/Leaflet'
  import Hotjar from '@/plugins/VueHotjar'

  /**
   * @module component - NewShipmentAddressNotFoundDialog
   * @param {string} direction
   */
  export default defineComponent({
    name: 'NewShipmentAddressNotFoundDialog',
    components: {
      CtkDialog,
      UiButton,
      NewShipmentAddressNotFoundDialogResults,
      NewShipmentAddressNotFoundDialogSearch,
      NewShipmentAddressNotFoundDialogMap,
      NewShipmentAddressNotFoundDialogContact
    },
    props: {
      direction: {
        type: String,
        required: true
      },
      value: {
        type: Boolean,
        required: true
      }
    },
    setup (props) {
      const { state: dialogValue } = useModelGetterSetter(props, 'value')

      /** @type {import('@vue/composition-api').Ref<string|null>} */
      const selectedResultId = ref(null)
      /** @type {import('@vue/composition-api').Ref<boolean>} */
      const requestedResults = ref(false)
      /** @type {import('@vue/composition-api').Ref<Array<import('@/models/AddressComponent/index').default>>} */
      const results = ref([])
      /** @type {import('@vue/composition-api').Ref<import('./_subs/NewShipmentAddressNotFoundDialogMap/index.vue').Marker?>} */
      const hoverResult = ref(null)
      /** @type {import('@vue/composition-api').Ref<string?>} */
      const country = ref(null)

      watch(dialogValue, (value) => {
        if (value) {
          Hotjar.tag('Shipments With Complex Address')
        } else {
          requestedResults.value = false
          selectedResultId.value = null
          results.value = []
        }
      })

      return {
        dialogValue,
        selectedResultId,
        requestedResults,
        results,
        country,
        hoverResult
      }
    },
    computed: {
      /**
       * @function formattedResults
       * @returns {Array<any>}
       */
      formattedResults () {
        return this.results.map(result => {
          const isHover = this.hoverResult &&
            (this.hoverResult.component.place_id === result.component.place_id)

          return {
            // @ts-ignore
            ...result,
            hovered: isHover
          }
        })
      },
      /**
       * @function markers
       * @returns {Array<any>}
       */
      markers () {
        return this.results.map(result => {
          const isActive = this.selectedResultId &&
            (this.selectedResultId === result.component.place_id)
          const isHover = this.hoverResult &&
            (this.hoverResult.component.place_id === result.component.place_id)

          return {
            ...result,
            id: result.component.place_id,
            location: result.location,
            icon: isActive || isHover
              ? icons.pickup('in_progress')
              : icons.disabled()
          }
        })
      }
    },
    methods: {
      /**
       * @function validateSelection
       */
      validateSelection () {
        // @ts-ignore
        const selectedResult = this.results
          // @ts-ignore
          .find((/** @type {import('@/models/AddressComponent/index').default} **/ result) => result.component.place_id === this.selectedResultId)

        if (selectedResult) {
          const address = new AddressComponent(selectedResult.component)
          this.$emit('selection', address)
        }

        this.close()
      },
      /**
       * @function selectResult
       * @param {import('./_subs/NewShipmentAddressNotFoundDialogMap/index.vue').Marker} marker
       */
      selectResult (marker) {
        this.selectedResultId = marker.id
      },
      /**
       * @function mouseoverResult
       * @param {import('./_subs/NewShipmentAddressNotFoundDialogMap/index.vue').Marker} marker
       */
      mouseoverResult (marker) {
        this.hoverResult = marker
      },
      /**
       * @function mouseoutResult
       */
      mouseoutResult () {
        this.hoverResult = null
      },
      /**
       * @function updateResults
       * @param {Array<import('@/models/AddressComponent/index').default>} results
       */
      async updateResults (results) {
        this.requestedResults = true
        this.results = results

        if (this.results.length === 0 && this.$matomo) {
          this.$matomo.trackEvent('Quotations', 'Find Address Search No Results')
        }

        await this.$nextTick()
        // @ts-ignore
        if (this.$refs.map) this.$refs.map.flyToMarkers()
      },
      close () {
        this.dialogValue = false
      }
    }
  })
</script>

<style lang="scss">
.new-shipment-address-not-found-dialog .modal-container {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
  width: 100%;
}
.new-shipment-address-not-found-dialog__header__close {
  appearance: none;
  border: none;
  background: transparent;
  height: 50px;
  width: 50px;
  padding: 0;
  color: $secondary-text;
}
.new-shipment-address-not-found-dialog__header__explanation {
  color: $secondary-text;
}
.new-shipment-address-not-found-dialog__content__side {
  max-height: 360px;
}
.new-shipment-address-not-found-dialog-map {
  border: 1px solid $divider;
}
.new-shipment-address-not-found-dialog .new-shipment-address-not-found-dialog-results, .new-shipment-address-not-found-dialog__no-results {
  position: relative;
  z-index: 0;
  box-shadow: rgba(#B9A1A1, 0.1) 1px 2px 4px 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-left: 1px solid $divider;
  border-top: 1px solid $divider;
  border-bottom: 1px solid $divider;
  max-height: initial;
}
</style>
