<template>
  <button
    :class="{
      'is-checked': !selectedValue
    }"
    class="new-shipment-dates-flexible tw-rounded tw-w-full text-left"
    type="button"
    @click="selectedValue = !selectedValue"
  >
    <div class="tw-flex tw-w-full tw-items-center">
      <ui-material-icon
        :name="!selectedValue ? 'check_box' : 'check_box_outline_blank'"
        class="tw-mr-2"
        aria-hidden="true"
        data-test="icon"
      />
      <i18n
        :path="`new-shipment.labels.${direction}.date_flexible`"
        tag="span"
        data-test="label"
      >
        <template #date>
          <span
            class="tw-font-medium"
            data-test="date"
          >
            {{ date | moment('ddd D MMMM') }}
          </span>
        </template>
      </i18n>
    </div>
  </button>
</template>

<script>
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  /**
   * @module component - NewShipmentDatesFlexible
   * @param {string} direction
   * @param {string} date
   */
  export default {
    name: 'NewShipmentDatesFlexible',
    props: {
      value: {
        type: Boolean,
        default: false
      },
      direction: {
        type: String,
        required: true
      },
      date: {
        type: String,
        required: true
      }
    },
    setup (props) {
      const { state: selectedValue } = useModelGetterSetter(props, 'value')

      return {
        selectedValue
      }
    }
  }
</script>

<style lang="scss" scoped>

  .new-shipment-dates-flexible {
    background-color: $light-gray;
    border: 1px solid #A4AAB3;
    min-height: 50px;
    padding: 4px 8px;
    transition: box-shadow 200ms;

    &:focus {
      @include focusShadow($info);
    }

    .material-icons {
      color: $secondary-text;
    }

    &.is-checked {
      .material-icons {
        color: $info;
      }
    }
  }

</style>
