import moment from 'moment'
import { ref, onMounted } from '@vue/composition-api'
import useInterval from './useInterval'

/**
 * @function useExpirationAfterDateTime
 * @param {string|Date} datetime
 * @param {number} [heartbeat=60000] - Interval value in milliseconds
 */
export default function useExpirationAfterDateTime (datetime, heartbeat = 60000) {
  const expired = ref(false)

  function updateExpiration () {
    expired.value = moment().isSameOrAfter(moment(datetime))
  }

  useInterval(updateExpiration, heartbeat)

  onMounted(() => {
    updateExpiration()
  })

  return {
    expired
  }
}
