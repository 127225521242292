<template>
  <div class="shipment-summary-card-direction tw-flex">
    <ui-ctk-icon
      :class="[
        direction === 'pickup'
          ? 'tw-text-blue-500'
          : 'tw-text-green-500'
      ]"
      class="tw-relative tw-mb-auto tw--mx-2"
      name="marker"
      data-test="icon"
    />

    <div class="shipment-summary-card-direction__content tw-leading-snug tw-flex-1 tw-pl-2 tw-truncate">
      <div
        class="shipment-summary-card-direction__content__name tw-uppercase tw-font-medium tw-truncate"
        v-text="name"
      />
      <ctk-timeslot-date
        :flexible="timeslot.flexible"
        :direction="direction"
        class="shipment-summary-card-direction__content__date tw-text-gray-800 tw-truncate"
      >
        {{ date }}
      </ctk-timeslot-date>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkTimeslotDate from '@/components/CtkTimeslotDate/index.vue'

  /**
   * @module component - shipmentSummaryCardDirection
   * @param {string} direction
   * @param {string} name
   * @param {object} timeslot
   * @param {string} timeslot.date
   * @param {string} timeslot.start_time
   * @param {boolean} timeslot.flexible
   */
  export default defineComponent({
    name: 'ShipmentSummaryCardDirection',
    components: {
      CtkTimeslotDate
    },
    props: {
      direction: {
        type: String,
        required: true
      },
      name: {
        type: String,
        required: true
      },
      timeslot: {
        type: Object,
        required: true
      }
    },
    computed: {
      /**
       * @function date
       * @returns {string}
       */
      date () {
        return this.$moment(`${this.timeslot.date} ${this.timeslot.start_time}`).format('LL')
      }
    }
  })
</script>
