<template>
  <div class="new-shipment-handling">
    <h2
      v-text="$t('new-shipment.titles.handling')"
      class="new-shipment-handling__title tw-font-normal"
      data-test="title"
    />
    <ValidationObserver
      ref="observer"
      slim
    >
      <form
        @submit.prevent="submitted"
      >
        <div class="tw-flex new-shipment-handling__layout">
          <new-shipment-handling-direction
            v-for="direction in ['pickup', 'delivery']"
            :key="direction"
            :direction="direction"
            :data-test="direction"
            class="tw-flex-1"
          >
            <ValidationProvider
              ref="driver"
              :name="`${direction}-driver`"
              rules="required"
              slim
            >
              <template slot-scope="{ errors, invalid }">
                <new-shipment-handling-driver
                  v-model="formData.driver[direction]"
                  :direction="direction"
                  :name="`${direction}-driver`"
                  :error="invalid ? errors[0] : null"
                  class="mb-4"
                  @input="updateGuard(direction)"
                />
              </template>
            </ValidationProvider>
            <ValidationProvider
              ref="tail-lift"
              :name="`${direction}-tail-lift`"
              rules="required"
              slim
            >
              <template slot-scope="{ errors, invalid }">
                <new-shipment-handling-tail-lift
                  v-model="formData.tail_lift[direction]"
                  :direction="direction"
                  :name="`${direction}-tail-lift`"
                  :error="invalid ? errors[0] : null"
                  @input="updateGuard(direction)"
                />
              </template>
            </ValidationProvider>
          </new-shipment-handling-direction>
        </div>

        <new-shipment-alert
          class="new-shipment-handling__explanation tw-mt-5"
          data-test="explanation"
        >
          <p data-test="price">
            * {{ $t('new-shipment.paragraphs.handling.explanation.handling_price') }}
          </p>
          <ul>
            <li data-test="price-standard">
              {{ $t('new-shipment.paragraphs.handling.explanation.handling_price.standard') }}
            </li>
            <li data-test="price-custom">
              {{ $t('new-shipment.paragraphs.handling.explanation.handling_price.custom') }}
            </li>
          </ul>
          <p
            class="tw-mb-0"
            data-test="tail-lift"
          >
            ** {{ $t('new-shipment.paragraphs.handling.explanation.tail_lift') }}
          </p>
        </new-shipment-alert>

        <div
          v-if="filteredErrors.length > 0"
          class="mt-3"
        >
          <div
            v-for="error in filteredErrors"
            :key="error.id"
            class="error-banner tw-rounded px-3 py-2 mb-2"
          >
            {{ error.msg }}
          </div>
        </div>

        <div
          class="new-shipment-handling__buttons tw-flex tw-flex-col-reverse 2sm:tw-flex-row 2sm:tw-justify-between tw-mt-6"
          data-test="buttons"
        >
          <div
            class="tw-mt-4 2sm:tw-mt-0"
          >
            <ui-button
              :to="{
                name: 'NewShipmentGoods'
              }"
              variant="link"
              class="tw-w-full 2sm:tw-w-auto"
              data-test="back"
              @click.native="back"
            >
              <template #left-icon>
                <ui-material-icon
                  name="keyboard_arrow_left"
                />
              </template>

              {{ $t('back') | capitalize }}
            </ui-button>
          </div>
          <ui-button
            :loading="$wait.is('fetching addresses distance')"
            :disabled="$wait.is('fetching addresses distance')"
            data-test="save-button"
            variant="primary"

            type="submit"
          >
            {{ $t('new-shipment.buttons.save_handling') }}
          </ui-button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import * as Sentry from '@sentry/browser'

  import store from '@/store'
  import { showToaster } from '@/services/Toaster'

  import NewShipmentHandlingDirection from './components/NewShipmentHandlingDirection/index.vue'
  import NewShipmentHandlingDriver from './components/NewShipmentHandlingDirection/_subs/NewShipmentHandlingDriver/index.vue'
  import NewShipmentHandlingTailLift from './components/NewShipmentHandlingDirection/_subs/NewShipmentHandlingTailLift/index.vue'
  import NewShipmentAlert from '@/views/Shippers/NewShipment/components/NewShipmentAlert/index.vue'

  /**
   * @module view - NewShipmentHandling
   */
  export default {
    name: 'NewShipmentHandling',
    components: {
      NewShipmentHandlingDirection,
      NewShipmentHandlingDriver,
      NewShipmentHandlingTailLift,
      NewShipmentAlert
    },
    beforeRouteEnter (to, from, next) {
      /**
       * Check if the user has completed his dates informations
       */
      if (!store.getters['shipments/new-shipment/isGoodsCompleted']) {
        next({
          name: 'NewShipmentGoods'
        })
        return false
      }

      next()
    },
    mounted () {
      this.formData = {
        driver: {
          pickup: this.getPickupHandlingDriver,
          delivery: this.getDeliveryHandlingDriver
        },
        tail_lift: {
          pickup: this.getPickupHandlingTailLift,
          delivery: this.getDeliveryHandlingTailLift
        }
      }
    },
    data () {
      return {
        formData: {
          driver: {
            pickup: null,
            delivery: null
          },
          tail_lift: {
            pickup: null,
            delivery: null
          }
        }
      }
    },
    computed: {
      ...mapGetters('shipments/new-shipment', [
        'getPickupHandlingDriver',
        'getDeliveryHandlingDriver',
        'getPickupHandlingTailLift',
        'getDeliveryHandlingTailLift'
      ]),
      /**
       * @function getHandlingDriver
       * @returns {string}
       */
      getHandlingDriver () {
        return direction => direction === 'pickup'
          ? this.getPickupHandlingDriver
          : this.getDeliveryHandlingDriver
      },
      /**
       * @function getHandlingTailLift
       * @returns {string}
       */
      getHandlingTailLift () {
        return direction => direction === 'pickup'
          ? this.getPickupHandlingTailLift
          : this.getDeliveryHandlingTailLift
      },
      /**
       * Re-map the errors to remove the pickup / delivery driver errors to unify them
       * into a single error message.
       * @function filteredErrors
       * @returns {Array} errors
       */
      filteredErrors () {
        const optionsKeys = ['delivery-driver', 'pickup-driver', 'delivery-tail-lift', 'pickup-tail-lift']
        if (!this.errors) return []
        const requiredOptionsErrors = this.errors.items.filter(error => optionsKeys.includes(error.field) && error.rule === 'required')

        return [
          ...this.errors.items.filter(error => !(optionsKeys.includes(error.field) && error.rule === 'required')),
          ...requiredOptionsErrors.length > 0 ? [
            {
              field: 'x-options',
              id: '99',
              msg: this.$t('new-shipment.paragraphs.error.options_required')
            }
          ] : []
        ]
      }
    },
    methods: {
      ...mapActions('shipments/new-shipment', [
        'retrieveAddressesDistance',
        'setHandlingDriver',
        'setHandlingTailLift',
        'setGuard'
      ]),
      /**
       * Called whenever the user presses the back button
       * @function back
       */
      back () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Quotations', 'Clicked Back')
        }
      },
      /**
       * Called whenever the user changes something in the handling
       * to update the guards.
       * @function updateGuard
       */
      updateGuard (direction) {
        const alreadySelectedHandlingDriver = this.getHandlingDriver(direction)
        const alreadySelectedHandlingTailLift = this.getHandlingTailLift(direction)

        const isHandlingDriverChanged = alreadySelectedHandlingDriver &&
          this.formData.driver[direction] !== alreadySelectedHandlingDriver
        const isHandlingTailLiftChanged = alreadySelectedHandlingTailLift &&
          this.formData.tail_lift[direction] !== alreadySelectedHandlingTailLift

        if (isHandlingDriverChanged || isHandlingTailLiftChanged) {
          const guards = ['handling', 'dates']
          guards.forEach(guard => this.setGuard({
            guard,
            value: false
          }))
        }
      },
      submitted () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Quotations', 'Validated Handling')
        }

        this.$refs.observer.validate()
          .then(valid => {
            if (!valid) return false

            this.$wait.start('fetching addresses distance')
            this.retrieveAddressesDistance()
              .then(() => {
                const directions = ['pickup', 'delivery']
                directions.forEach(direction => {
                  /**
                   * Check if the handling has changed, if it does, disallow all the next steps
                   */
                  this.updateGuard(direction)

                  this.setHandlingDriver({
                    direction,
                    value: this.formData.driver[direction]
                  })

                  this.setHandlingTailLift({
                    direction,
                    value: this.formData.tail_lift[direction]
                  })
                })

                this.setGuard({
                  guard: 'handling',
                  value: true
                })

                this.$router.push({
                  name: 'NewShipmentDates'
                })
                  .catch(() => {})
              })
              .catch((/** @type {any} */ err) => {
                if (!err.response) return

                Sentry.captureException(err)
                console.error('Error occured', err)

                showToaster(this, this.$t('an_error_has_occurred'), {
                  type: 'error',
                  position: 'bottom-right'
                })
              })
              .finally(() => {
                this.$wait.end('fetching addresses distance')
              })
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
.new-shipment-handling__title {
  position: relative;
  font-size: 20px;
  margin-bottom: 36px;
}
.new-shipment-handling__title::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 220px;
  height: 1px;
  background-color: $divider;
}
.new-shipment-handling__explanation ul {
  list-style-type: none;
  padding-left: 22px;
}
.new-shipment-handling__explanation ul li {
  position: relative;
}
.new-shipment-handling__explanation ul li::before {
  position: absolute;
  content: '•';
  color: $info;
  font-size: 20px;
  left: -16px;
  top: -4px;
}
.new-shipment-handling .new-shipment-handling-direction {
  width: 50%;
}
.new-shipment-handling .new-shipment-handling-direction:first-child {
  border-right: 1px solid $divider;
  padding-right: 16px;
}
.new-shipment-handling .new-shipment-handling-direction:last-child {
  padding-left: 16px;
}
.new-shipment-handling .error-banner {
  color: white;
  background-color: $danger;
}
@media only screen and (max-width: $breakpoint-laptop-s) {
  .new-shipment-handling__layout {
    flex-direction: column;
  }
  .new-shipment-handling__layout .new-shipment-handling-direction {
    width: 100%;
  }
  .new-shipment-handling__layout .new-shipment-handling-direction:last-child {
    padding-left: 0;
  }
  .new-shipment-handling__layout .new-shipment-handling-direction:first-child {
    padding-right: 0;
    border-right: none;
    border-bottom: 1px solid $divider;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
}
</style>
