<template>
  <div class="ctk-drop-menu">
    <button
      class="btn tw-font-medium ctk-drop-menu__button tw-w-full text-left"
      type="button"
      data-test="button"
      @click="open = !open"
    >
      <div class="tw-flex">
        <slot name="icon" />
        <div class="tw-flex tw-justify-between tw-flex-1 tw-items-center">
          <span
            v-text="selectedItem.title"
            data-test="title"
          />
          <ui-material-icon
            :name="open ? 'arrow_drop_up': 'arrow_drop_down'"
            data-test="drop-icon"
          />
        </div>
      </div>
    </button>
    <ul
      v-if="open"
      data-test="list"
    >
      <li
        v-for="item in items"
        :key="item.key"
      >
        <button
          :class="{
            active: selectedItem.key === item.key
          }"
          class="btn ctk-drop-menu__item tw-w-full tw-text-left"
          type="button"
          data-test="list-button"
          @click="select(item)"
        >
          <div class="tw-flex tw-items-center">
            <ui-ctk-icon
              v-if="item.icon"
              :name="item.icon"
              class="tw-mr-2"
              data-test="list-button-icon"
            />
            <span
              v-text="item.title"
              data-test="list-button-title"
            />
          </div>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
  /**
   * @module component - CtkDropMenu
   * @param {Array} items
   * @param {string} items.key
   * @param {string} items.title
   */
  export default {
    name: 'CtkDropMenu',
    props: {
      value: {
        type: String,
        required: true
      },
      items: {
        type: Array,
        required: true
      }
    },
    data () {
      return {
        open: false
      }
    },
    computed: {
      /**
       * @function selectedItem
       * @returns {object} item
       */
      selectedItem () {
        return this.items.find(item => this.value === item.key)
      }
    },
    methods: {
      select (item) {
        this.open = false
        this.$emit('input', item.key)
      }
    }
  }
</script>

<style lang="scss" scoped>

  .ctk-drop-menu {
    &__button {
      background-color: black;
      color: white;
      min-height: 45px;

      &:focus {
        @include focusShadow(black);
      }
    }

    ul,
    li {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    ul {
      padding-bottom: 16px;
      margin-top: 8px;

      &.open {
        display: block;
        padding: 0 16px;
      }
    }

    &__item {
      display: block;
      height: 55px;
      border-bottom: 1px solid $divider;
      color: $secondary-text;
      text-decoration: none;
      transition: background-color 200ms, color 200ms;
      padding: 0 8px 0 8px;
      font-size: 14px;
      border-radius: 0;

      &:hover {
        background-color: $light-gray;
      }

      &.active {
        color: $primary-text;
        background-color: darken($light-gray, 5%);
        font-weight: 500;
      }
    }
  }

</style>
