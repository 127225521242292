<template>
  <component
    :is="!!to ? 'router-link' : 'div'"
    :to="to"
    :class="{
      'is-link': !!to,
      'disabled': $attrs.disabled
    }"
    :event="$attrs.disabled ? '' : 'click'"
    class="new-shipment-sidebar-group tw-flex tw-flex-col"
  >
    <slot />
  </component>
</template>

<script>
  /**
   * @module component - NewShipmentSidebarGroup
   * @param {object} [to=null] - Specify the destination of the link. If not defined, it will
   * be a simple div.
   */
  export default {
    name: 'NewShipmentSidebarGroup',
    props: {
      to: {
        type: Object,
        default: null
      }
    }
  }
</script>

<style lang="scss">

  .new-shipment-sidebar-group {
    color: $secondary-text;
    text-decoration: none;

    .new-shipment-sidebar-group-item {
      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      &:first-child:not(:last-child):not(.router-link-exact-active) {
        border-bottom: none;
      }

      &:last-child:not(:last-child).router-link-exact-active {
        border-top: none;
      }
    }

    &.router-link-exact-active {
      color: $primary-text;
    }

    /* stylelint-disable */
    &:hover,
    &.disabled {
      text-decoration: none;

      &.is-link .new-shipment-sidebar-group-item {
        background-color: rgba(black, 0.03);
      }
    }

    &.router-link-exact-active .new-shipment-sidebar-group-item {
      border: 1px solid $info;
    }
    /* stylelint-enalbe */

    &.disabled {
      opacity: 0.7;
      filter: grayscale(1);
      cursor: not-allowed;
    }
  }

</style>
