<template>
  <div class="new-shipment-dates">
    <div class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between md:tw-items-start">
      <h2
        class="new-shipment-dates__title tw-font-normal"
        data-test="title"
      >
        {{ $t('new-shipment.titles.dates') }}
      </h2>
    </div>

    <new-shipment-dates-types
      v-model="selectedType"
      class="tw-mb-10"
    />

    <ValidationObserver
      ref="observer"
      slim
    >
      <form
        :disabled="$wait.is('requesting quotation')"
        @submit.prevent="submitted"
      >
        <template
          v-if="selectedType === 'tailored'"
        >
          <div data-test="tailored">
            <div class="tw-flex new-shipment-dates__calendars">
              <new-shipment-dates-direction
                v-for="direction in ['pickup', 'delivery']"
                :ref="`direction-${direction}`"
                :key="direction"
                :direction="direction"
                :data-test="direction"
              >
                <new-shipment-dates-calendar
                  :ref="`${direction}-date-provider`"
                  v-model="formData.dates[direction]"
                  :name="`${direction}-date`"
                  :direction="direction"
                  :min-date="minDate(direction)"
                  :max-date="maxDate(direction)"
                  :disabled="$wait.is('requesting quotation')"
                  :data-test="`direction-${direction}-calendar`"
                  @input="pickDate(direction, formData.dates[direction])"
                />
              </new-shipment-dates-direction>
            </div>
            <new-shipment-dates-flexible-explanation
              v-if="formData.dates.pickup && formData.dates.delivery && formData.dates.pickup !== formData.dates.delivery"
              :pickup-date="formData.dates.pickup"
              :delivery-date="formData.dates.delivery"
              class="tw-mt-4 tw-mb-2"
            />
            <div class="tw-flex new-shipment-dates__timeslot">
              <new-shipment-dates-direction
                v-for="direction in ['pickup', 'delivery']"
                :ref="`direction-${direction}`"
                :key="direction"
                :direction="direction"
                :data-test="direction"
              >
                <template #title>
                  <div />
                </template>
                <new-shipment-dates-flexible
                  v-if="formData.dates.pickup && formData.dates.delivery && formData.dates.pickup !== formData.dates.delivery"
                  v-model="formData.flexible[direction]"
                  :direction="direction"
                  :date="formData.dates[direction]"
                  :disabled="$wait.is('requesting quotation')"
                  :data-test="`direction-${direction}-flexible`"
                  class="tw-mt-4 tw-mb-2"
                  @input="updateGuard(direction)"
                />
                <new-shipment-dates-timeslot
                  v-model="formData.timeslot[direction]"
                  :data-test="`direction-${direction}-timeslot`"
                  :disabled="$wait.is('requesting quotation')"
                  class="tw-mt-4 tw-mb-2"
                  @input="debounceGuard(direction)"
                />
              </new-shipment-dates-direction>
            </div>
          </div>
        </template>

        <template
          v-else
        >
          <new-shipment-dates-optimal
            :pickup.sync="formData.optimalDates.pickup"
            :delivery.sync="formData.optimalDates.delivery"
            :distance="getAddressesDistance"
            data-test="optimal"
            @update="updateGuards"
          />
        </template>

        <div
          class="new-shipment-dates__buttons tw-flex tw-flex-col-reverse 2sm:tw-justify-between 2sm:tw-flex-row tw-mt-6"
          data-test="buttons"
        >
          <div class="tw-mt-4 2sm:tw-mt-0">
            <ui-button
              :to="{
                name: 'NewShipmentHandling'
              }"
              :disabled="$wait.is('requesting quotation')"
              variant="link"
              class="tw-w-full 2sm:tw-w-auto"
              data-test="back"
              @click.native="back"
            >
              <template #left-icon>
                <ui-material-icon
                  name="keyboard_arrow_left"
                />
              </template>

              {{ $t('back') | capitalize }}
            </ui-button>
          </div>
          <ui-button
            :loading="$wait.is('requesting quotation')"
            :disabled="$wait.is('requesting quotation')"
            data-test="save-button"
            variant="primary"

            type="submit"
          >
            {{ $t('new-shipment.buttons.save_dates') }}
          </ui-button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import moment from 'moment'
  import { debounce } from 'underscore'
  import { mapGetters, mapActions } from 'vuex'

  import { showToaster } from '@/services/Toaster'
  import store from '@/store'
  import { EventBus } from '@/services/EventBus'
  import Hotjar from '@/plugins/VueHotjar'

  import NewShipmentDatesDirection from './components/NewShipmentDatesDirection/index.vue'
  import NewShipmentDatesCalendar from './components/NewShipmentDatesDirection/_subs/NewShipmentDatesCalendar/index.vue'
  import NewShipmentDatesFlexible from './components/NewShipmentDatesDirection/_subs/NewShipmentDatesFlexible/index.vue'
  import NewShipmentDatesFlexibleExplanation from './components/NewShipmentDatesDirection/_subs/NewShipmentDatesFlexible/_subs/NewShipmentDatesFlexibleExplanation/index.vue'
  import NewShipmentDatesTypes from './components/NewShipmentDatesTypes/index.vue'
  import NewShipmentDatesOptimal from './components/NewShipmentDatesOptimal/index.vue'
  import NewShipmentDatesTimeslot from './components/NewShipmentDatesDirection/_subs/NewShipmentDatesTimeslot/index.vue'

  /**
   * Returns a pickup/delivery dates from a single date and the distance
   * between the pickup & delivery, optimised for pallet network.
   * @function computeDates
   * @param {string|object} day
   * @param {number} distance - Distance in km between the two addresses
   * @returns {any}
   */
  export function computeDates (day, distance) {
    let range = distance <= 1000 ? 4 : 5

    /**
     * Add 2 additional days for the weekend if the selected date is either
     * wednesday, thursday or friday.
     */
    const weekDay = moment(day).day()
    if (weekDay === 3 || weekDay === 4 || weekDay === 5) {
      range += 2
    }

    /**
     * Increase the range by 1 if the shipper is creating a shipment
     * on the weekend
     */
    const today = moment().day()
    if ((today === 0 || today === 6) && weekDay === 1) {
      range += 1
    }

    /**
     * When the delivery date falls in a weekend, add additional days according to
     * either it's saturday or sunday.
     */
    const deliveryDate = moment(day).add(range - 1, 'days')
    const deliveryDay = deliveryDate.day()
    if (deliveryDay === 0 || deliveryDay === 6) {
      deliveryDate.add(deliveryDay === 0 ? 1 : 2, 'days')
    }

    return {
      pickup: day,
      delivery: deliveryDate
    }
  }

  /**
   * @module view - NewShipmentDates
   */
  export default {
    name: 'NewShipmentDates',
    components: {
      NewShipmentDatesDirection,
      NewShipmentDatesCalendar,
      NewShipmentDatesFlexible,
      NewShipmentDatesOptimal,
      NewShipmentDatesFlexibleExplanation,
      NewShipmentDatesTypes,
      NewShipmentDatesTimeslot
    },
    beforeRouteEnter (to, from, next) {
      /**
       * Check if the user has completed his handlings informations
       */
      if (!store.getters['shipments/new-shipment/isHandlingsCompleted']) {
        next({
          name: 'NewShipmentHandling'
        })
        return false
      }

      next()
    },
    computed: {
      ...mapGetters('auth', [
        'isOnboarded'
      ]),
      ...mapGetters('shipments/new-shipment', [
        'getAddressesDistance',
        'getPickupDate',
        'getDeliveryDate',
        'getPickupTimeslot',
        'getDeliveryTimeslot',
        'isPickupFlexible',
        'getPickupAddress',
        'getDeliveryAddress',
        'getLoad',
        'isDeliveryFlexible',
        'getSelectedDateType'
      ]),
      /**
       * @function selectedType
       * @returns {string}
       */
      selectedType: {
        get () {
          return this.getSelectedDateType
        },
        set (v) {
          this.setSelectedDateType(v)
        }
      },
      /**
       * Returns true if the shipment seems to be pallet network compatible
       * @function isPalletNetworkCompatible
       * @returns {boolean}
       */
      isPalletNetworkCompatible () {
        if (!this.getPickupAddress || !this.getDeliveryAddress) return false
        const euroDeliveryCountries = ['DE', 'BE']

        const { country: pickupCountry } = this.getPickupAddress
        const { country: deliveryCountry } = this.getDeliveryAddress
        const { type, format, quantity, weight, height, pallets } = this.getLoad
        if (quantity === null) return false

        const isDeliveryToEuro = euroDeliveryCountries.includes(deliveryCountry)
        const allowedPallets = isDeliveryToEuro ? ['120x80'] : ['120x80', '120x100', '60x80']

        const parsedQuantity = parseInt(quantity, 10)
        const parsedWeight = parseInt(weight, 10)

        const isOnlyPallets = type === 'pallets' && allowedPallets.includes(format)
        const isOnlyPalletsMultipallet = type === 'pallets' && pallets && pallets
          .every(pallet => pallet.format === pallets[0].format && allowedPallets.includes(pallet.format))

        const maxUnitWeight = isDeliveryToEuro ? 600 : (format === '60x80' ? 200 : 1000)
        const isPalletUnitWeight = (parsedWeight / parsedQuantity) <= maxUnitWeight

        const isFRtoEuro = pickupCountry === 'FR' && (isDeliveryToEuro || deliveryCountry === 'FR')
        const isLessThan3T = parsedWeight <= 3000
        const isUnderMaxHeight = height <= 230
        const hasCorrectQuantityAccordingToFormat = format === '120x80'
          ? parsedQuantity <= 6
          : format === '120x100'
            ? parsedQuantity <= 4
            : parsedQuantity <= 12

        return (isOnlyPallets || isOnlyPalletsMultipallet) && isPalletUnitWeight && isFRtoEuro && isLessThan3T && hasCorrectQuantityAccordingToFormat && isUnderMaxHeight && this.isOnboarded
      },
      /**
       * @function getFlexibleDate
       * @param {boolean} flexible
       */
      getFlexibleDate () {
        return direction => direction === 'pickup'
          ? this.isPickupFlexible
          : this.isDeliveryFlexible
      },
      /**
       * @function getDate
       * @param {string} direction
       */
      getDate () {
        return direction => direction === 'pickup'
          ? this.getPickupDate
          : this.getDeliveryDate
      },
      /**
       * @function getTimeslot
       * @param {Array} timeslot
       */
      getTimeslot () {
        return direction => direction === 'pickup'
          ? this.getPickupTimeslot
          : this.getDeliveryTimeslot
      },
      minDate () {
        return direction => {
          /**
           * If the pickup date is selected, it becomes the min-date for the delivery
           */
          if (direction === 'delivery' && !!this.formData.dates.pickup) {
            return this.$moment(this.formData.dates.pickup).format('YYYY-MM-DD')
          }

          return this.$moment().format('YYYY-MM-DD')
        }
      },
      /**
       * Returns the max date for the pickup & delivery
       * @function maxDate
       */
      maxDate () {
        return direction => {
          if (direction === 'pickup') {
            return this.$moment().add(30, 'days').format('YYYY-MM-DD')
          }
          return null
        }
      }
    },
    data () {
      return {
        formData: {
          optimalDates: {
            pickup: null,
            delivery: null
          },
          dates: {
            pickup: null,
            delivery: null
          },
          flexible: {
            pickup: false,
            delivery: false
          },
          timeslot: {
            pickup: [540, 1020],
            delivery: [540, 1020]
          }
        },
        isFixedForced: false
      }
    },
    beforeMount () {
      this.updateFormDates()
    },
    mounted () {
      if (this.isPalletNetworkCompatible) {
        Hotjar.tag('Network Compatible Shipment Without Date')

        if (this.$matomo) {
          this.$matomo.trackEvent('Quotations', 'Date Step Network Compatible')
        }
      }
    },
    methods: {
      ...mapActions('auth', [
        'retrievePaymentSources'
      ]),
      ...mapActions('shipments/new-shipment', [
        'setDate',
        'setDateFlexible',
        'setTimeslot',
        'setGuard',
        'setSelectedDateType',
        'requestQuotation'
      ]),
      debounceGuard: debounce(
        function (direction) {
          this.updateGuard(direction)
        },
        600
      ),
      /**
       * Called whenever the user presses the back button
       * @function back
       */
      back () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Quotations', 'Clicked Back')
        }
      },
      /**
       * @function updateGuards
       */
      updateGuards () {
        this.updateGuard('pickup')
        this.updateGuard('delivery')
      },
      /**
       * Called whenever the user changes something in the dates
       * to update the guards.
       * @function updateGuard
       */
      updateGuard (direction) {
        let date = this.formData.dates[direction]
        if (this.selectedType === 'optimal') {
          date = this.formData.optimalDates
        }

        if (!date || !this.formData.timeslot[direction]) {
          return false
        }

        const alreadySavedDate = this.getDate(direction)
        const alreadySavedFlexibleDate = this.getFlexibleDate(direction)
        const alreadySavedTimeslot = this.getTimeslot(direction)

        const hasDateChanged = alreadySavedDate && date !== alreadySavedDate
        const hasDateFlexibleChanged = this.formData.flexible[direction] !== alreadySavedFlexibleDate
        const hasTimeslotChanged = alreadySavedTimeslot &&
          ((this.formData.timeslot[direction][0] !== alreadySavedTimeslot[0]) ||
            (this.formData.timeslot[direction][1] !== alreadySavedTimeslot[1]))

        if (hasDateChanged || hasDateFlexibleChanged || hasTimeslotChanged) {
          const guards = ['dates', 'quotation']
          guards.forEach(guard => this.setGuard({
            guard,
            value: false
          }))
        }
      },
      updateFormDates () {
        if (this.getAddressesDistance === null) {
          throw new Error('The distance between the two addresses is not defined.')
        }

        let baseDate = this.$moment().add(1, 'day')
        if (this.getPickupDate) {
          baseDate = this.$moment(this.getPickupDate)
        }

        /**
         * Check if the base date falls in the weekend
         */
        const baseDay = baseDate.day()
        if (baseDay === 0 || baseDay === 6) {
          baseDate.add(baseDay === 0 ? 1 : 2, 'days')
        }

        const optimalDates = computeDates(baseDate, this.getAddressesDistance)
        this.formData.optimalDates = optimalDates

        this.formData.dates = {
          pickup: this.getPickupDate,
          delivery: this.getDeliveryDate
        }

        this.formData.flexible = {
          pickup: this.isPickupFlexible,
          delivery: this.isDeliveryFlexible
        }

        this.formData.timeslot = {
          pickup: this.getPickupTimeslot,
          delivery: this.getDeliveryTimeslot
        }
      },
      /**
       * @function validateDates
       */
      validateDates () {
        let { pickup, delivery } = Object.assign({}, this.formData.dates)

        if (this.selectedType === 'optimal') {
          pickup = this.formData.optimalDates.pickup
          delivery = this.formData.optimalDates.delivery
        }

        const pickupDate = this.$moment(pickup).startOf('day')
        const deliveryDate = this.$moment(delivery)
        if (pickupDate < this.$moment().startOf('day')) {
          throw new Error('pickup.in_past')
        }

        if (!this.formData.flexible.pickup) {
          const startTime = this.$moment()
            .startOf('day')
            .minutes(this.formData.timeslot.pickup[0])
            .format('HH:mm')

          const pickupDatetime = this.$moment(`${pickup} ${startTime}`, 'YYYY-MM-DD HH:mm')
          if (pickupDatetime < this.$moment()) {
            throw new Error('pickup.in_past')
          }
        }

        if (pickupDate > deliveryDate) {
          throw new Error('pickup.past_delivery')
        }

        if (pickupDate > this.$moment().add(30, 'days')) {
          throw new Error('pickup.out_of_range')
        }
      },
      /**
       * Called whenver the user picks a date in the calendar
       * @function pickDate
       */
      pickDate (direction, date) {
        /**
         * If the pickup date is after the delivery date, resets the delivery date
         */
        if (direction === 'pickup') {
          if (!!this.formData.dates.delivery && this.$moment(date) > this.$moment(this.formData.dates.delivery)) {
            this.formData.dates.delivery = null
            return false
          }
        }

        this.$nextTick(() => {
          this.isFixedForced = false
          if (this.formData.dates.pickup && this.formData.dates.delivery && this.formData.dates.pickup === this.formData.dates.delivery) {
            this.isFixedForced = true
          }

          this.updateGuard(direction)
        })
      },
      submitted () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Quotations', 'Validated Dates')
        }

        this.$refs.observer.validate()
          .then(async valid => {
            if (!valid) {
              return false
            }

            try {
              this.validateDates()
            } catch (e) {
              if (!this.$refs['pickup-date-provider'] ||
                !this.$refs['pickup-date-provider'][0] ||
                !this.$refs['pickup-date-provider'][0].$children[0]) {
                return false
              }

              this.$refs['pickup-date-provider'][0].$children[0]
                .setErrors([
                  this.$t(`new-shipment.paragraphs.error.${e.message}`)
                ])
              return false
            }

            const directions = ['pickup', 'delivery']
            directions.forEach(direction => {
              try {
                /**
                 * Check if the formDates is different than the current date.
                 * If it does, disallow all the steps after the date.
                 */
                this.updateGuard(direction)
              } catch (e) {
                console.error('Error occured', e)
              }

              if (this.selectedType === 'tailored') {
                this.setDate({
                  direction,
                  date: this.formData.dates[direction]
                })

                this.setDateFlexible({
                  direction,
                  flexible: this.isFixedForced
                    ? false
                    : this.formData.flexible[direction]
                })

                this.setTimeslot({
                  direction,
                  startTime: this.formData.timeslot[direction][0],
                  endTime: this.formData.timeslot[direction][1]
                })
              } else {
                Hotjar.tag('Shipment Optimal Dates')

                this.setDate({
                  direction,
                  date: this.$moment(this.formData.optimalDates[direction]).format('YYYY-MM-DD')
                })

                this.setDateFlexible({
                  direction,
                  flexible: true
                })

                this.setTimeslot({
                  direction,
                  startTime: 540,
                  endTime: 1020
                })
              }
            })

            this.$wait.start('requesting quotation')
            /**
             * If the payment sources fetching fails for some reason,
             * we do not want to block the payment form.
             */
            try {
              await this.retrievePaymentSources()
            } catch (e) {
              this.$wait.end('requesting quotation')
            }

            this.requestQuotation()
              .then(() => {
                /**
                 * If all the validation passes, consider the dates view as valid
                 */
                this.setGuard({
                  guard: 'dates',
                  value: true
                })

                this.$router.push({
                  name: 'NewShipmentQuotation'
                })
                  .catch(() => {})
              })
              .catch(err => {
                if (!err.response) return

                let hasViolations = false
                const { data } = err.response
                if (data && data.error) {
                  if (data.error.violations) {
                    const violations = data.error.violations

                    /**
                     * Check if one of the violations are from another view
                     * than the dates, to make the redirection to the right view accordingly.
                     */
                    const hasLoadViolations = violations
                      .filter(violation => violation.property_path.includes('load'))
                      .length > 0

                    hasViolations = violations.length > 0
                    if (hasLoadViolations) {
                      this.$router.push({
                        name: 'NewShipmentGoods'
                      })
                        .then(() => {
                          this.$nextTick(() => {
                            EventBus.$emit('new-shipment:goods:update-violations', violations)
                          })
                        })
                        .catch(() => {})
                    } else {
                      /**
                       * Fix to exclude the "load." and "load" violations
                       * TODO: This is a patch and should not be made here. It's purpose
                       * is to fix the issue where no error is displayed if a load error
                       * occurs.
                       */
                      const filteredViolations = violations
                        .filter(violation => !violation.property_path.includes('load'))

                      hasViolations = filteredViolations.length > 0

                      /**
                       * TODO: Use the handlePropertyPathViolations handler.
                       */
                      filteredViolations.forEach(({ property_path: property, message }) => {
                        const provider = this.$refs[`${property}-provider`]
                        /**
                         * Quick fix here to show a toaster if the provider does not exists
                         * in the current page.
                         * Doing this to avoid the UX quirk if a violation occurs in
                         * two different pages. (eg. pickup & delivery addresses).
                         */
                        if (provider) {
                          provider.setErrors([message])
                        } else {
                          showToaster(this, message, { type: 'error' })
                        }
                      })
                    }
                  }

                  if (!hasViolations) {
                    if (data && data.error && data.error.title) {
                      /**
                       * In some cases, we want to redirect the user to specific views
                       * since the error does not belong to the goods view.
                       */
                      if (data.error.key) {
                        if (data.error.key === 'quotation_request.minimum_distance') {
                          this.$router.push({
                            name: 'NewShipmentAddress',
                            params: {
                              direction: 'pickup'
                            }
                          })
                            .catch(() => {})
                        }
                      }

                      showToaster(this, data.error.title, { type: 'error' })
                    } else {
                      showToaster(this, this.$t('an_error_has_occurred'), { type: 'error' })
                    }
                  }
                }
              })
              .finally(() => {
                this.$wait.end('requesting quotation')
              })
          })
      }
    }
  }
</script>

<style lang="scss">

  .new-shipment-dates {
    &__title {
      position: relative;
      font-size: 20px;
      margin-bottom: 36px;

      &::after {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 220px;
        height: 1px;
        background-color: $divider;
      }
    }

    .new-shipment-dates-direction {
      width: 50%;

      &:first-child {
        border-right: 1px solid $divider;
        padding-right: 16px;
      }

      &:last-child {
        padding-left: 16px;
      }
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      .new-shipment-dates {
        &__calendars,
        &__timeslot {
          flex-direction: column;

          .new-shipment-dates-direction {
            width: 100%;
            padding-right: 0;
            padding-left: 0;
            border-right: none;
          }
        }
      }
    }
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    .new-shipment-dates .datepicker-container {
      width: 100%;
    }
  }

</style>
