var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex new-shipment-goods-types"},[_c('ui-fat-radio-group',{staticClass:"tw-hidden 2sm:tw-flex tw-flex-1",attrs:{"items":_vm.types},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var active = ref.active;
var keydown = ref.keydown;
return [_c('new-shipment-goods-types-item',{staticClass:"tw-w-1/3",attrs:{"icon":item.icon,"title":_vm.$t(item.title),"subtitle":item.subtitle && _vm.$t(item.subtitle),"active":active,"disabled":_vm.$attrs.disabled},nativeOn:{"keydown":function($event){return keydown.apply(null, arguments)},"click":function($event){_vm.selectedType = item.value}}})]}}]),model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}}),_c('ctk-drop-menu',{staticClass:"tw-block 2sm:tw-hidden tw-w-full",attrs:{"items":Object.keys(_vm.types).map(function (k) { return ({
      key: k,
      icon: _vm.types[k].icon,
      title: ("" + (_vm.$t(_vm.types[k].title)) + (_vm.types[k].subtitle ? (" (" + (_vm.$t(_vm.types[k].subtitle)) + ")") : ''))
    }); })},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ui-ctk-icon',{staticClass:"tw-mr-2",attrs:{"name":_vm.types[_vm.selectedType].icon}})]},proxy:true}]),model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }