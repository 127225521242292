<template>
  <new-shipment-acceptation-dialog
    v-model="dialogValue"
    v-on="$listeners"
    :confirmation="confirmation"
    :error="error"
    :shipment="shipment"
    :title="$t('app.titles.pay')"
    :has-price="false"
    :has-illustration="false"
    class="new-shipment-acceptation-payment-dialog"
  >
    <template #aside>
      <div
        class="tw-mt-4"
        data-test="reassurances"
      >
        <new-shipment-acceptation-dialog-reassurance
          v-for="(reassurance, k) in reassurances"
          :key="k"
          :icon="reassurance.icon"
          :title="$t(reassurance.title)"
          :content="$t(reassurance.content)"
          :highlight="reassurance.highlight"
          :data-test="`reassurance-${k}`"
          class="tw-mb-6"
        />
      </div>
    </template>

    <shipment-payment-view
      :stripe="stripe"
      :loader="'accepting quotation'"
      :amount-label="isSelectedPriceCCAuthorization
        ? $t('app.labels.authorization_amount')
        : $t('app.labels.pay_amount')"
      :amount="getQuotationSelectedPrice.total_including_vat"
      :currency="getQuotationSelectedPrice.currency"
      :has-notice="isSelectedPriceCCAuthorization"
      :button-label="isSelectedPriceCCAuthorization
        ? 'app.buttons.authorization'
        : 'app.buttons.pay_amount'"
      :notice="$t('new-shipment.paragraphs.immediate_cc_authorization_notice')"
      :expired-label="$t('new-shipment.labels.quotation_request.expired')"
      :expires-at="expiresAt"
      data-test="payment-view"
      @submitted="submitted"
      @renew="renew"
    />
  </new-shipment-acceptation-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'

  import useStripe from '@/composables/useStripe'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  import ShipmentPaymentView from '@/views/Shippers/components/ShipmentPaymentView/index.vue'
  import NewShipmentAcceptationDialog from '@/views/Shippers/NewShipment/_subs/NewShipmentInformations/components/NewShipmentAcceptationDialog/index.vue'
  import NewShipmentAcceptationDialogReassurance from '@/views/Shippers/NewShipment/_subs/NewShipmentInformations/components/NewShipmentAcceptationDialog/_subs/NewShipmentAcceptationDialogReassurance/index.vue'

  /**
   * @module component - NewShipmentAcceptationPaymentDialog
   * @param {boolean} [value=false]
   * @param {boolean} [confirmation=false]
   * @param {boolean} [error=false]
   * @param {object} [shipment=null]
   */
  export default {
    name: 'NewShipmentAcceptationPaymentDialog',
    components: {
      ShipmentPaymentView,
      NewShipmentAcceptationDialog,
      NewShipmentAcceptationDialogReassurance
    },
    props: {
      value: {
        type: Boolean,
        default: true
      },
      confirmation: {
        type: Boolean,
        default: false
      },
      error: {
        type: Boolean,
        default: false
      },
      shipment: {
        type: Object,
        default: null
      }
    },
    setup (props) {
      const { stripe } = useStripe()
      const { state: dialogValue } = useModelGetterSetter(props, 'value')

      return {
        stripe,
        dialogValue
      }
    },
    computed: {
      ...mapGetters('shipments/new-shipment', [
        'getQuotation',
        'getQuotationSelectedPrice',
        'isSelectedPriceCCAuthorization'
      ]),
      /**
       * @function expiresAt
       * @returns {string}
       */
      expiresAt () {
        return this.$moment(this.getQuotation.created_at).add(30, 'minutes').toISOString()
      },
      /**
       * @function reassurances
       * @returns {object}
       */
      reassurances () {
        return {
          'cancel-without-fee': {
            icon: require('@/assets/img/icons/quotation/cancel.svg'),
            title: 'app.titles.cancel-without-fee',
            content: 'app.paragraphs.cancel-without-fee'
          }
        }
      }
    },
    methods: {
      /**
       * @function renew
       */
      renew () {
        this.$router.push({
          name: 'NewShipmentDates'
        })
          .finally(() => {
            this.dialogValue = false
          })
          .catch(() => {})
      },
      /**
       * Called whenever the form is submitted
       * @function submitted
       * @param {object} paymentRes
       * @param {string} paymentRes.id
       * @param {string} paymentRes.type
       * @param {string} paymentRes.saveCard
       */
      submitted ({ id, type, saveCard }) {
        if (this.$matomo) {
          const { type, payment_plans: paymentPlans } = this.getQuotationSelectedPrice
          this.$matomo.trackEvent('Quotations', 'Validated Submission', `${type}|${paymentPlans[0]}`)
        }

        this.$parent.acceptationSubmitted({
          id,
          type,
          save_card: saveCard
        })
      }
    }
  }
</script>

<style lang="scss">
.new-shipment-acceptation-payment-dialog .new-shipment-acceptation-dialog-reassurance {
  padding-left: 0px;
  padding-right: 0px;
}
</style>
