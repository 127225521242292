import { onMounted, ref } from '@vue/composition-api'

import useEvent from './useEvent'
import useKeyPress from './useKeyPress'
import { KEYCODES } from './constants'

/**
 * @function useListBoxAria
 * @param {import('@vue/composition-api').Ref} listBoxElement
 */
export default function useListBoxAria (listBoxElement) {
  const isFocused = ref(false)
  const activeElement = ref(null)
  const optionsElements = ref([])

  function removeActiveClassFromActive () {
    if (activeElement.value) {
      activeElement.value.classList.remove('aria-focus-active')
    }
  }

  function focusElement (element) {
    if (!isFocused.value) return

    removeActiveClassFromActive()

    activeElement.value = element
    activeElement.value.classList.add('aria-focus-active')
    listBoxElement.value.setAttribute('aria-activedescendant', activeElement.value.id)
  }

  function focusFirstElement () {
    if (optionsElements.value.length === 0) return

    focusElement(optionsElements.value[0])
  }

  function focusLastElement () {
    if (optionsElements.value.length === 0) return

    focusElement(optionsElements.value[optionsElements.value.length - 1])
  }

  function isDisabled () {
    return listBoxElement.value && !!listBoxElement.value.getAttribute('disabled')
  }

  onMounted(() => {
    optionsElements.value = listBoxElement.value.querySelectorAll('[role="option"]')

    useKeyPress(listBoxElement, KEYCODES.UP, () => {
      if (!activeElement.value) return

      const nextElement = activeElement.value.previousElementSibling
      if (nextElement) {
        focusElement(nextElement)
      } else {
        /**
         * If there are no more previous elements, that means we are at
         * the top of the list. Focus the last one.
         */
        focusLastElement()
      }
    })

    useKeyPress(listBoxElement, KEYCODES.DOWN, () => {
      if (!activeElement.value) return

      const nextElement = activeElement.value.nextElementSibling
      if (nextElement) {
        focusElement(nextElement)
      } else {
        /**
         * If there are no more next elements, that means we are at
         * the bottom of the list. Focus the first one.
         */
        focusFirstElement()
      }
    })

    useKeyPress(listBoxElement, KEYCODES.HOME, () => {
      focusFirstElement()
    })

    useKeyPress(listBoxElement, KEYCODES.END, () => {
      focusLastElement()
    })

    useKeyPress(listBoxElement, KEYCODES.SPACE, selectElement)
    useKeyPress(listBoxElement, KEYCODES.RETURN, selectElement)

    function selectElement () {
      if (!activeElement.value) return

      const event = document.createEvent('Event')
      event.initEvent('click', true, true)
      activeElement.value.dispatchEvent(event)
    }

    useEvent(listBoxElement, 'focus', () => {
      if (isDisabled()) return
      isFocused.value = true

      /**
       * If there is already a selected item, put that item as
       * active by default.
       */
      const selectedElements = listBoxElement.value.querySelectorAll('[aria-selected="true"]')
      if (selectedElements.length > 0) {
        focusElement(selectedElements[0])
      } else {
        focusFirstElement()
      }
    })

    useEvent(listBoxElement, 'blur', () => {
      if (isDisabled()) return
      isFocused.value = false

      if (activeElement.value) {
        removeActiveClassFromActive()
        activeElement.value = null
      }
    })
  })
}
