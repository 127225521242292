<template>
  <ui-fat-radio-item
    :active="active"
    :title="title"
    class="new-shipment-types-item"
  >
    <div class="new-shipment-types-item__container">
      <slot />
    </div>
  </ui-fat-radio-item>
</template>

<script>
  import UiFatRadioItem from '@/components/UI/FatRadioGroup/FatRadioItem/index.vue'

  /**
   * @module component - NewShipmentTypesItem
   * @param {string} [active=false]
   * @param {string} title
   */
  export default {
    name: 'NewShipmentTypesItem',
    components: {
      UiFatRadioItem
    },
    props: {
      active: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        required: true
      }
    }
  }
</script>

<style lang="scss">

  .new-shipment-types-item {
    &.active {
      &::after {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: -10px;
        content: '';
        width: 20px;
        height: 20px;
        background-color: $info;
        transform: rotate(45deg);
      }
    }

    &:hover.active:not(:disabled),
    &:focus-visible.active:not(:disabled),
    &:hover.active:not(:disabled)::after,
    &:focus-visible.active:not(:disabled)::after {
      background-color: lighten($info, 5%);
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      &.active::after {
        display: none;
      }
    }
  }

</style>
