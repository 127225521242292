<template>
  <div
    :class="{
      'new-shipment-address--pickup': direction === 'pickup',
      'new-shipment-address--delivery': direction === 'delivery'
    }"
    class="new-shipment-address tw-flex tw-flex-1"
  >
    <template v-if="!$wait.is('fetching addresses')">
      <new-shipment-address-add
        ref="address-form"
        :prefilled-address="enrichedAddress[direction] ||
          (getEnrichedAddress(direction).address && getEnrichedAddress(direction).address.city && getEnrichedAddress(direction))"
        class="tw-flex-1"
        @select-address="pickAddress"
        @cancel="clearAddress"
      />
    </template>
    <template v-else>
      <div data-test="skeleton-view">
        <ctk-skeleton-block
          :loading="true"
          width="300px"
          height="30px"
          level="2"
          class="mb-3"
        />
        <ctk-skeleton-block
          :loading="true"
          width="200px"
          height="50px"
          level="3"
          class="mb-2"
        />
        <ctk-skeleton-block
          :loading="true"
          width="150px"
          height="50px"
          level="3"
          class="mb-2"
        />
        <ctk-skeleton-block
          :loading="true"
          width="180px"
          height="50px"
          level="3"
          class="mb-2"
        />
      </div>
    </template>

    <!-- Dialogs -->
    <new-shipment-address-not-found-dialog
      ref="address-not-found"
      :direction="dialogs.addressNotFound.direction"
      v-model="dialogs.addressNotFound.visible"
      data-test="address-not-found"
      @selection="selectAddress"
    />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import { EventBus } from '@/services/EventBus'
  import store from '@/store'

  import CtkSkeletonBlock from '@/components/CtkSkeletonView/_subs/CtkSkeletonBlock'
  import NewShipmentAddressNotFoundDialog from '@/views/Shippers/NewShipment/components/NewShipmentAddressNotFoundDialog'
  import NewShipmentAddressAdd from './components/NewShipmentAddressAdd'

  /**
   * @module view - NewShipmentAddress
   */
  export default {
    name: 'NewShipmentAddress',
    components: {
      CtkSkeletonBlock,
      NewShipmentAddressAdd,
      NewShipmentAddressNotFoundDialog
    },
    beforeRouteEnter (to, from, next) {
      /**
       * Check if the user has completed his pickup address infos
       */
      if (to.params.direction === 'delivery') {
        const isPickupCompleted = !!store.getters['shipments/new-shipment/getPickupAddress'] &&
          store.getters['shipments/new-shipment/getGuard'].pickup_address
        if (!isPickupCompleted) {
          next({
            name: 'NewShipmentAddress',
            params: {
              direction: 'pickup'
            }
          })
          return false
        }
      }

      next(vm => {
        vm.previousRoute = from
      })
    },
    async beforeRouteUpdate (to, from, next) {
      await this.$nextTick()
      const addressForm = this.$refs['address-form']
      if (addressForm) {
        addressForm.updateFormAddress()
      }

      next()
    },
    data () {
      return {
        previousRoute: null,
        dialogs: {
          addressNotFound: {
            visible: false,
            direction: 'pickup'
          }
        },
        requestNewAddress: {
          pickup: false,
          delivery: false
        },
        enrichedAddress: {
          pickup: null,
          delivery: null
        }
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getCid'
      ]),
      ...mapGetters('shipments/new-shipment', [
        'getPickupAddress',
        'getDeliveryAddress',
        'getPickupComment',
        'getPickupMeeting',
        'getPickupContact',
        'getPickupHandlingDriver',
        'getDeliveryHandlingDriver',
        'getPickupHandlingTailLift',
        'getDeliveryHandlingTailLift',
        'getPickupTimeslot',
        'getDeliveryTimeslot',
        'getDeliveryComment',
        'getDeliveryMeeting',
        'getDeliveryContact',
        'getFetchedAddresses'
      ]),
      direction () {
        return this.$route.params.direction
      },
      /**
       * @function getEnrichedAddress
       * @param {string} direction
       */
      getEnrichedAddress () {
        return (direction) => {
          const address = this.getAddress(direction)
          const comment = this.getComment(direction)
          const meeting = this.getMeeting(direction)
          const contact = this.getContact(direction)
          const handling = {
            driver: this.getHandlingDriver(direction),
            tail_lift: this.getHandlingTailLift(direction)
          }

          let startTime = null
          let endTime = null
          if (this.getTimeslot(direction)[0]) {
            startTime = this.$moment()
              .startOf('day')
              .minutes(this.getTimeslot(direction)[0])
              .format('HH:mm')
            endTime = this.$moment()
              .startOf('day')
              .minutes(this.getTimeslot(direction)[1])
              .format('HH:mm')
          }

          return {
            address,
            comment,
            contact,
            meeting,
            handling,
            start_time: startTime,
            end_time: endTime,
            isGaAddress: true
          }
        }
      },
      /**
       * @function getAddress
       * @param {string} direction
       */
      getAddress () {
        return (direction) => direction === 'pickup'
          ? this.getPickupAddress
          : this.getDeliveryAddress
      },
      /**
       * @function getTimeslot
       * @param {string} direction
       */
      getTimeslot () {
        return (direction) => direction === 'pickup'
          ? this.getPickupTimeslot
          : this.getDeliveryTimeslot
      },
      /**
       * @function getHandlingDriver
       * @param {string} direction
       */
      getHandlingDriver () {
        return (direction) => direction === 'pickup'
          ? this.getPickupHandlingDriver
          : this.getDeliveryHandlingDriver
      },
      /**
       * @function getHandlingTailLift
       * @param {string} direction
       */
      getHandlingTailLift () {
        return (direction) => direction === 'pickup'
          ? this.getPickupHandlingTailLift
          : this.getDeliveryHandlingTailLift
      },
      /**
       * @function getContact
       * @param {string} direction
       */
      getContact () {
        return (direction) => direction === 'pickup'
          ? this.getPickupContact
          : this.getDeliveryContact
      },
      /**
       * @function getComment
       * @param {string} direction
       */
      getComment () {
        return (direction) => direction === 'pickup'
          ? this.getPickupComment
          : this.getDeliveryComment
      },
      /**
       * TODO: Move to the store? As this code is also repeated in the AddressAdd component.
       * @function getMeeting
       * @param {string} direction
       */
      getMeeting () {
        return (direction) => direction === 'pickup'
          ? this.getPickupMeeting
          : this.getDeliveryMeeting
      },
      hasAddress () {
        const hasSavedEnrichedAddress = this.getEnrichedAddress(this.direction) &&
          this.getEnrichedAddress(this.direction).address
        const hasLocalEnrichedAddress = this.enrichedAddress[this.direction] &&
          this.enrichedAddress[this.direction].address

        return !!hasSavedEnrichedAddress || !!hasLocalEnrichedAddress
      },
      /**
       * Returns true if there are fetched addresses
       * @function hasFetchedAddresses
       * @returns {boolean} hasAddress
       */
      hasFetchedAddresses () {
        return this.getFetchedAddresses[this.direction].length > 0
      }
    },
    mounted () {
      this.fetchAddresses()

      EventBus.$on('shipments:new-shipment:set-enriched-address', ({ direction, address }) => {
        this.enrichedAddress[direction] = address
      })

      EventBus.$on('dialogs:address-not-found', (/** @type {string} */ direction) => {
        /**
         * Get the country value from the address add form
         * and update it manually in the address not found dialog
         */
        const addressFormRef = this.$refs['address-form']
        if (addressFormRef) {
          const notFoundDialogRef = this.$refs['address-not-found']
          if (notFoundDialogRef) {
            notFoundDialogRef.country = addressFormRef.country
          }
        }

        this.dialogs.addressNotFound = {
          direction,
          visible: true
        }
      })
    },
    beforeDestroy () {
      const events = [
        'shipments:new-shipment:set-enriched-address',
        'dialogs:address-not-found'
      ]
      events.forEach(event => EventBus.$off(event))
    },
    methods: {
      ...mapActions('shipments/new-shipment', [
        'retrieveAddresses',
        'setHandlingDriver',
        'setHandlingTailLift'
      ]),
      /**
       * Method called whenever the user clicks on the clear button on the address field
       * or in the cancel button.
       * @function clearAddress
       */
      clearAddress () {
        /**
         * We'll check if the direction we're in have a hanlding information.
         * If it does, reset the handling field
         */
        const hasHandlingDriver = this.getHandlingDriver(this.direction)
        const hasHandlingTailLift = this.getHandlingTailLift(this.direction)
        if (hasHandlingDriver || hasHandlingTailLift) {
          this.setHandlingDriver({
            direction: this.direction,
            value: null
          })

          this.setHandlingTailLift({
            direction: this.direction,
            value: null
          })
        }
      },
      /**
       * @function selectAddress
       * @param {import('@/models/AddressComponent')} address
       */
      selectAddress (address) {
        /** @type {any} */
        const addressForm = this.$refs['address-form']
        if (addressForm) {
          addressForm.updateAddress({
            address,
            source: null,
            companyName: null
          })
        }
      },
      /**
       * Called whenever the user selects an address in it's list
       * @function pickAddress
       */
      async pickAddress ({ enrichedAddress, direction }) {
        this.enrichedAddress[direction] = enrichedAddress

        await this.$nextTick()
        const addressForm = this.$refs['address-form']
        if (addressForm) {
          addressForm.updateFormAddress()
        }
      },
      /**
       * @function fetchAddresses
       */
      fetchAddresses () {
        /**
         * TODO: Remove this to re-fetch the addresses
         * or fetch them once at the first view load.
         * Waiting for addresses search to know what to do.
         */
        const addresses = this.getFetchedAddresses[this.direction]
        if (addresses.length > 0) return

        this.$wait.start('fetching addresses')
        Promise.all([
          this.retrieveAddresses({
            direction: 'pickup'
          }),
          this.retrieveAddresses({
            direction: 'delivery'
          })
        ])
          .finally(() => this.$wait.end('fetching addresses'))
      }
    }
  }
</script>

<style lang="scss">

  .new-shipment-address {
    @media only screen and (max-width: $breakpoint-tablet) {
      flex-direction: column;
      flex: 0;
    }
  }

</style>
