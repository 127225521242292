import { onBeforeUnmount, onMounted, ref } from '@vue/composition-api'

export default function useInterval (callback, heartbeat) {
  const interval = ref(null)

  function clear () {
    if (interval.value) {
      clearInterval(interval.value)
    }
  }

  onMounted(() => {
    interval.value = setInterval(callback, heartbeat)
  })

  onBeforeUnmount(() => {
    clear()
  })

  return {
    clear
  }
}
