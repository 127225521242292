<template>
  <div
    class="new-shipment-goods-pallet-row tw-flex tw-flex-col 2sm:tw-flex-row"
  >
    <div
      :class="[
        formatData === 'other' && '2sm:tw-flex-1',
        formatData !== 'other' && '2sm:tw-w-1/2 new-shipment-goods-pallet-row__fields--format',
      ]"
      class="new-shipment-goods-pallet-row__fields tw-flex tw-flex-col 2sm:tw-flex-row"
    >
      <ValidationProvider
        ref="format-provider"
        :name="$t('new-shipment.fields.goods.format')"
        :rules="'required'"
        slim
      >
        <template slot-scope="{ invalid, validated, errors }">
          <ctk-input-select
            v-model="formatData"
            :id="`format-${index}`"
            :label="$t('new-shipment.labels.goods.format')"
            :items="formats"
            :value-to-show="formatData"
            :error="invalid && validated || errors && errors.length > 0"
            :disabled="$wait.is('requesting quotation')"
            :class="[
              formatData === 'other' && '2sm:tw-w-1/4',
              formatData !== 'other' && '2sm:tw-w-1/2'
            ]"
            name="format"
            class="tw-mb-3 2sm:tw-mr-3 2sm:tw-mb-0"
            data-test="format"
            required
            @input="updateFormat"
          />
        </template>
      </ValidationProvider>
      <ValidationProvider
        ref="quantity-provider"
        :name="$t('app.labels.load.quantity')"
        :rules="`required|min_value:1`"
        slim
      >
        <template slot-scope="{ invalid, validated, errors }">
          <ctk-input-text
            :id="`quantity-${index}`"
            v-model="quantityData"
            :error="invalid && validated || errors && errors.length > 0"
            :label="$t('app.labels.load.quantity') | capitalize"
            :disabled="$wait.is('requesting quotation')"
            :class="[
              formatData === 'other' && '2sm:tw-w-1/4 2sm:tw-mr-3',
              formatData !== 'other' && '2sm:tw-w-1/2'
            ]"
            name="quantity"
            type="tel"
            class="tw-mb-3 2sm:tw-mb-0"
            data-test="quantity"
            required
            @input="updateGuard"
          />
        </template>
      </ValidationProvider>
      <template
        v-if="formatData === 'other'"
      >
        <ValidationProvider
          ref="length-provider"
          :name="$t('new-shipment.fields.goods.length')"
          :rules="'required|min_value:1|max_value:1320|numeric'"
          slim
        >
          <template slot-scope="{ invalid, validated, errors }">
            <ctk-input-text
              :id="`length-${index}`"
              v-model="lengthData"
              :error="invalid && validated || errors && errors.length > 0"
              :label="$t('new-shipment.labels.goods.length')"
              :disabled="$wait.is('requesting quotation')"
              name="length"
              type="tel"
              suffix="cm"
              class="tw-mb-4 2sm:tw-mr-3 2sm:tw-w-1/4 2sm:tw-mb-0 tw-flex-1"
              data-test="length"
              required
              @input="updateGuard"
            />
          </template>
        </ValidationProvider>
        <ValidationProvider
          ref="width-provider"
          :name="$t('new-shipment.fields.goods.width')"
          :rules="'required|min_value:1|max_value:240|numeric'"
          slim
        >
          <template slot-scope="{ invalid, validated, errors }">
            <ctk-input-text
              :id="`width-${index}`"
              v-model="widthData"
              :error="invalid && validated || errors && errors.length > 0"
              :label="$t('new-shipment.labels.goods.width')"
              :disabled="$wait.is('requesting quotation')"
              name="width"
              type="tel"
              suffix="cm"
              class="tw-mb-4 2sm:tw-w-1/4 2sm:tw-mb-0 tw-flex-1"
              data-test="width"
              required
              @input="updateGuard"
            />
          </template>
        </ValidationProvider>
      </template>
    </div>
    <UiButton
      v-if="removable"
      :title="$t('new-shipment.buttons.load.delete')"
      :class="[
        formatData !== 'other' && '2sm:tw--ml-3'
      ]"
      class="!tw-ml-1 !tw-min-w-0"
      type="button"
      variant="link"
      size="sm"
      data-test="remove-button"
      @click="remove"
    >
      <span class="2sm:tw-hidden">
        {{ $t('new-shipment.buttons.load.delete') }}
      </span>
      <template #left-icon>
        <ui-ctk-icon
          class="tw-hidden 2sm:tw-inline-block !md:tw-mr-0"
          name="close"
          data-test="icon"
        />
      </template>
    </UiButton>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import CtkInputSelect from '@/components/CtkInputs/CtkInputSelect/index.vue'
  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'
  import { AUTHORIZED_PALLET_FORMATS } from '@/composables/constants'

  /**
   * Util function to generate a getter/setter computed property
   * for each field by emitting an update event for the .sync data binding.
   * Used to avoid repetition.
   * @function syncInterface
   * @returns {Object}
   */
  function syncInterface (key) {
    return {
      [`${key}Data`]: {
        get () {
          return this[key]
        },
        set (v) {
          this.setPalletValue({
            index: this.index,
            key,
            value: v
          })
        }
      }
    }
  }

  /**
   * @module component - NewShipmentGoodsPalletRow
   * @param {boolean} [removable=false]
   */
  export default defineComponent({
    name: 'NewShipmentGoodsPalletRow',
    props: {
      index: {
        type: Number,
        required: true
      },
      removable: {
        type: Boolean,
        default: false
      },
      quantity: {
        type: [Number, String],
        default: null
      },
      length: {
        type: [Number, String],
        default: null
      },
      width: {
        type: [Number, String],
        default: null
      },
      format: {
        type: String,
        default: null
      }
    },
    components: {
      CtkInputSelect,
      CtkInputText
    },
    computed: {
      formats () {
        return [
          ...Object.keys(AUTHORIZED_PALLET_FORMATS).map(key => ({
            value: key,
            text: key
          })),
          {
            value: 'other',
            text: this.$t('new-shipment.values.load.other_format')
          }
        ]
      },
      ...syncInterface('quantity'),
      ...syncInterface('format'),
      ...syncInterface('length'),
      ...syncInterface('width')
    },
    methods: {
      ...mapActions('shipments/new-shipment', [
        'setPalletValue'
      ]),
      updateFormat (v) {
        if (v !== 'other') {
          const format = AUTHORIZED_PALLET_FORMATS[v]
          const { width, length } = format

          this.lengthData = length
          this.widthData = width

          this.updateGuard()
        } else {
          this.lengthData = null
          this.widthData = null
        }
      },
      updateGuard () {
        this.$emit('update-guard')
      },
      remove () {
        this.$emit('remove')
      }
    }
  })
</script>
