<template>
  <div class="new-shipment-acceptation-payment-expiration tw-flex">
    <UiBadge
      :color="expired ? 'red' : 'blue'"
      neutral
      class="tw-mx-auto"
      data-test="item"
    >
      <template #left-icon>
        <ui-ctk-icon
          name="expiration"
          data-test="icon"
        />
      </template>

      {{ expired
        ? expiredLabel
        : $t(isPastDay
          ? 'app.labels.price_expiring_on'
          : 'app.labels.price_expiring_at', {
          time: expiresIn
        }) }}
    </UiBadge>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - NewShipmentAcceptationPaymentExpiration
   * @param {string} expiredLabel
   * @param {string|number} expiresAt
   * @param {boolean} expired
   */
  export default defineComponent({
    name: 'NewShipmentAcceptationPaymentExpiration',
    props: {
      expiredLabel: {
        type: String,
        required: true
      },
      expiresAt: {
        type: [String, Number],
        required: true
      },
      expired: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      /**
       * Returns if the expiration is greater than one day.
       * @function isPastDay
       */
      isPastDay () {
        const date = this.$moment(this.expiresAt)
        return date.isSameOrAfter(this.$moment().add(1, 'day'))
      },
      /**
       * @function expiresIn
       * @returns {string}
       */
      expiresIn () {
        const date = this.$moment(this.expiresAt)
        return date.format(this.isPastDay
          ? 'LLL'
          : 'LT'
        )
      }
    }
  })
</script>
