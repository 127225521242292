var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",attrs:{"tag":"div"}},[_c('form',{staticClass:"new-shipment-address-not-found-dialog-search tw-flex tw-flex-col md:tw-flex-row",on:{"submit":function($event){$event.preventDefault();return _vm.submitted.apply(null, arguments)}}},[_c('div',{staticClass:"tw-flex md:tw-w-5/12"},[_c('ctk-input-address-countries',{staticClass:"flex-fixed",attrs:{"disabled-countries":_vm.disabledCountries,"disabled":_vm.$wait.is('geocoding the address')},on:{"input":_vm.countryUpdated},model:{value:(_vm.countryGetter),callback:function ($$v) {_vm.countryGetter=$$v},expression:"countryGetter"}}),_c('ValidationProvider',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onBlur),expression:"onBlur"}],staticClass:"tw-relative tw-flex-1",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var error = ref.error;
return [_c('ctk-input-text',{ref:"city",staticClass:"new-shipment-address-not-found-dialog-search__city",attrs:{"id":"city","label":_vm.$t('new-shipment.labels.search_address.city'),"error":!!error,"hint":error,"disabled":_vm.$wait.is('geocoding the address'),"loader":_vm.$wait.is('geocoding the address'),"type":"text","name":"city","required":"","clearable":"","autocomplete":"off"},on:{"input":_vm.lazyFetchCities,"focus":_vm.onFocus},model:{value:(_vm.formData.city),callback:function ($$v) {_vm.$set(_vm.formData, "city", $$v)},expression:"formData.city"}})]}}])},[_c('transition',{attrs:{"name":"slide"}},[((_vm.isFocus && _vm.predictions.length > 0) || (_vm.isFocus && _vm.isDirty))?_c('ctk-input-address-predictions',{ref:"predictions",staticClass:"tw-z-50",attrs:{"predictions":_vm.predictions,"prediction":_vm.lastPrediction && _vm.lastPrediction.id,"has-marker":false},on:{"select":_vm.selectPrediction}}):_vm._e()],1)],1)],1),_c('div',{staticClass:"tw-flex tw-flex-col md:tw-flex-row md:tw-w-7/12"},[_c('ValidationProvider',{staticClass:"tw-mb-4 md:tw-mb-0 tw-flex-1",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var error = ref.error;
return [_c('ctk-input-text',{ref:"address",staticClass:"new-shipment-address-not-found-dialog-search__address",attrs:{"id":"address","label":_vm.$t(_vm.direction === 'pickup'
              ? 'new-shipment.labels.search_address.pickup_address'
              : 'new-shipment.labels.search_address.delivery_address'),"error":!!error,"hint":error,"disabled":_vm.$wait.is('geocoding the address'),"loader":_vm.$wait.is('geocoding the address'),"type":"text","name":"address","required":"","clearable":"","autocomplete":"street-address"},model:{value:(_vm.formData.address),callback:function ($$v) {_vm.$set(_vm.formData, "address", $$v)},expression:"formData.address"}})]}}])}),_c('ui-button',{directives:[{name:"matomo",rawName:"v-matomo",value:({
          click: { category: 'Quotations', action: 'Find Address Search' }
        }),expression:"{\n          click: { category: 'Quotations', action: 'Find Address Search' }\n        }"}],staticClass:"new-shipment-address-not-found-dialog-search__submit tw-w-full md:tw-w-auto flex-fixed tw-py-0 tw-px-2",attrs:{"title":_vm._f("capitalize")(_vm.$t('search')),"disabled":_vm.$wait.is('geocoding the address'),"loading":_vm.$wait.is('geocoding the address'),"type":"submit","variant":"info"}},[_c('div',{staticClass:"tw-flex"},[_c('ui-ctk-icon',{staticClass:"tw-m-auto",attrs:{"name":"search"}})],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }