var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",attrs:{"slim":""}},[_c('form',{staticClass:"new-shipment-address-add tw-flex tw-flex-1 tw-flex-col",attrs:{"disabled":_vm.$wait.is('adding address')},on:{"submit":function($event){$event.preventDefault();return _vm.submitted.apply(null, arguments)}}},[_c('h2',{staticClass:"new-shipment-address-add__title tw-font-normal tw-mb-4"},[_vm._v(" "+_vm._s(_vm.$t(_vm.direction === 'pickup' ? 'new-shipment.titles.add_address.pickup' : 'new-shipment.titles.add_address.delivery'))+" ")]),_c('ValidationProvider',{ref:"address-provider",attrs:{"name":_vm.$t('new-shipment.fields.address'),"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var errors = ref.errors;
return [_c('autocomplete-address-input',{ref:"address",staticClass:"mb-3",attrs:{"direction":_vm.direction,"disabled":_vm.$wait.is('adding address'),"error":invalid ? errors[0] : null,"disabled-countries":_vm.disabledCountries,"country":_vm.country,"name":"address"},on:{"update:country":function($event){_vm.country=$event},"autocomplete":_vm.updateAddress,"reset":_vm.resetAddress,"clear":_vm.cancel,"toggle-country":_vm.toggleCountry},model:{value:(_vm.formData.addressQuery),callback:function ($$v) {_vm.$set(_vm.formData, "addressQuery", $$v)},expression:"formData.addressQuery"}})]}}])}),(!(_vm.hadAddress || _vm.hasPrefilledAddress))?_c('new-shipment-address-available-countries',{class:{
        faded: _vm.isCountrySelectVisible
      },attrs:{"disabled-countries":_vm.disabledCountries},on:{"select-country":_vm.selectCountry}}):_vm._e(),(_vm.hasCompanyField)?_c('ValidationProvider',{attrs:{"name":_vm.$t('new-shipment.fields.company'),"rules":"required|max:255","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
      var validated = ref.validated;
      var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"mb-3",attrs:{"id":"company","label":_vm.$t('new-shipment.labels.company'),"error":invalid && validated,"hint":errors[0],"disabled":_vm.$wait.is('adding address'),"type":"text","name":"company","required":""},model:{value:(_vm.formData.companyName),callback:function ($$v) {_vm.$set(_vm.formData, "companyName", $$v)},expression:"formData.companyName"}})]}}],null,false,2967089615)}):_vm._e(),(_vm.hadAddress || _vm.hasPrefilledAddress)?[_c('fieldset',{staticClass:"tw-mb-4"},[_c('i18n',{staticClass:"tw-text-base",attrs:{"path":"new-shipment.labels.contact_informations","tag":"legend"},scopedSlots:_vm._u([{key:"optional",fn:function(){return [_c('span',{staticClass:"tw-text-xs tw-text-gray-700 tw-italic",domProps:{"textContent":_vm._s(("(" + (_vm.$options.filters.capitalize(_vm.$t('optional'))) + ")"))}})]},proxy:true}],null,false,2693897265)}),_c('div',{staticClass:"tw-flex tw-flex-col lg:tw-flex-row"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('new-shipment.fields.contact.name'),"rules":"max:128","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
      var validated = ref.validated;
      var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"md:tw-w-1/2 tw-mb-3 md:tw-mr-4",attrs:{"id":"contact_name","label":_vm.$t('new-shipment.labels.contact.name'),"error":invalid && validated,"hint":errors[0],"disabled":_vm.$wait.is('adding address'),"type":"text","name":"contact_name","ignore-optional":""},model:{value:(_vm.formData.contact.name),callback:function ($$v) {_vm.$set(_vm.formData.contact, "name", $$v)},expression:"formData.contact.name"}})]}}],null,false,1937661508)}),(_vm.phoneCountry)?[_c('ValidationProvider',{attrs:{"name":_vm.$t('new-shipment.fields.contact.phone'),"rules":("telephone:" + _vm.phoneCountry),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
      var validated = ref.validated;
      var errors = ref.errors;
return [_c('ctk-phone-number',{ref:"contact-phone",staticClass:"md:tw-w-1/2 tw-mb-3",attrs:{"id":"contact_phone","country":_vm.phoneCountry,"error":invalid && validated,"hint":errors[0],"label":_vm.$t('new-shipment.labels.contact.phone'),"disabled":_vm.$wait.is('adding address'),"name":"contact_phone","autocomplete":"tel-national","ignore-optional":""},on:{"update:country":function($event){_vm.phoneCountry=$event}},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})]}}],null,false,2465989615)})]:_vm._e()],2)],1),_c('new-shipment-address-meeting',{ref:"meeting",attrs:{"radio":_vm.formData.meetingRadio},on:{"update:radio":function($event){return _vm.$set(_vm.formData, "meetingRadio", $event)}},model:{value:(_vm.formData.meeting),callback:function ($$v) {_vm.$set(_vm.formData, "meeting", $$v)},expression:"formData.meeting"}}),_c('fieldset',[_c('legend',{staticClass:"tw-text-base"},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('new-shipment.titles.informations'))}}),_c('span',{staticClass:"tw-text-xs tw-text-secondary-text tw-italic tw-ml-1",domProps:{"textContent":_vm._s(("(" + (_vm.$options.filters.capitalize(_vm.$t('optional'))) + ")"))}})]),_c('new-shipment-alert',[_c('h3',{staticClass:"tw-text-sm tw-font-normal tw-text-secondary",domProps:{"textContent":_vm._s(_vm.$t('new-shipment.titles.address_important_infos'))}}),_c('ul',{staticClass:"new-shipment-address-add__important-infos tw-mb-0"},_vm._l((_vm.importantInfos),function(info){return _c('li',{key:info,domProps:{"textContent":_vm._s(_vm.$t(("new-shipment.paragraphs.address." + info)))}})}),0)]),_c('ValidationProvider',{attrs:{"name":_vm.$t('new-shipment.fields.address_important_infos'),"rules":"max:4000","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
      var validated = ref.validated;
      var errors = ref.errors;
return [_c('ctk-input-textarea',{staticClass:"tw-mb-12",attrs:{"id":"comment","label":_vm.$t('new-shipment.labels.address_infos'),"error":invalid && validated,"hint":invalid ? errors[0] : null,"disabled":_vm.$wait.is('adding address'),"type":"text","name":"comment","has-optional-suffix":"","ignore-optional":""},on:{"input":_vm.updateGuard},model:{value:(_vm.formData.comment),callback:function ($$v) {_vm.$set(_vm.formData, "comment", $$v)},expression:"formData.comment"}})]}}],null,false,183743143)})],1),_c('new-shipment-alert',[_c('p',{staticClass:"tw-mb-0",domProps:{"textContent":_vm._s(_vm.$t('new-shipment.paragraphs.address.extra_hours_fees'))}}),_c('p',{staticClass:"tw-mb-0",domProps:{"textContent":_vm._s(_vm.$t('new-shipment.paragraphs.meeting.option'))}})])]:_vm._e(),_c('div',{staticClass:"tw-flex tw-flex-col-reverse 2sm:tw-justify-between 2sm:tw-flex-row new-shipment-address-add__buttons tw-mt-6",class:{
        'is-floating': !(_vm.hadAddress || _vm.hasPrefilledAddress)
      }},[_c('div',{staticClass:"tw-mt-4 2sm:tw-mt-0"},[(_vm.direction === 'delivery' || _vm.hasShipments)?_c('ui-button',{staticClass:"tw-w-full 2sm:tw-w-auto",attrs:{"variant":"link","type":"button"},on:{"click":_vm.back},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('ui-material-icon',{attrs:{"name":"keyboard_arrow_left"}})]},proxy:true}],null,false,3890926157)},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t('back')))+" ")]):_vm._e()],1),(_vm.hadAddress || _vm.hasPrefilledAddress)?_c('ui-button',{attrs:{"loading":_vm.$wait.is('adding address'),"disabled":_vm.$wait.is('adding address'),"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t(("new-shipment.buttons.save_address." + _vm.direction)))+" ")]):_vm._e()],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }