<template>
  <div class="new-shipment-axa tw-flex tw-justify-center tw-items-center">
    <p
      data-test="paragraph"
      class="mb-0"
    >
      <span
        class="tw-font-medium"
        data-test="peace"
      >
        {{ $t('new-shipment.paragraphs.axa_peace') }}
      </span>
      <span
        data-test="assurance"
        v-html="$t('new-shipment.paragraphs.axa_assurance')"
      />
      <a
        :href="tos"
        data-test="learn-more"
        class="tw-text-blue-500"
      >
        {{ $t('new-shipment.buttons.learn_more') }}
      </a>
    </p>
    <picture
      class="ml-3 flex-fixed"
    >
      <source
        srcset="@/assets/img/logo-axa.webp"
        type="image/webp"
      >
      <img
        src="@/assets/img/logo-axa.png"
        alt="Logo Axa"
        width="45"
        height="45"
        data-test="logo"
      >
    </picture>
  </div>
</template>

<script>
  import Config from '@/services/Config'

  /**
   * @module component - NewShipmentAxa
   */
  export default {
    name: 'NewShipmentAxa',
    data () {
      return {
        tos: Config.get('wwwBase') + 'goto/tos'
      }
    }
  }
</script>
