var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-shipment-goods-pallet-row tw-flex tw-flex-col 2sm:tw-flex-row"},[_c('div',{staticClass:"new-shipment-goods-pallet-row__fields tw-flex tw-flex-col 2sm:tw-flex-row",class:[
      _vm.formatData === 'other' && '2sm:tw-flex-1',
      _vm.formatData !== 'other' && '2sm:tw-w-1/2 new-shipment-goods-pallet-row__fields--format' ]},[_c('ValidationProvider',{ref:"format-provider",attrs:{"name":_vm.$t('new-shipment.fields.goods.format'),"rules":'required',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var invalid = ref.invalid;
    var validated = ref.validated;
    var errors = ref.errors;
return [_c('ctk-input-select',{staticClass:"tw-mb-3 2sm:tw-mr-3 2sm:tw-mb-0",class:[
            _vm.formatData === 'other' && '2sm:tw-w-1/4',
            _vm.formatData !== 'other' && '2sm:tw-w-1/2'
          ],attrs:{"id":("format-" + _vm.index),"label":_vm.$t('new-shipment.labels.goods.format'),"items":_vm.formats,"value-to-show":_vm.formatData,"error":invalid && validated || errors && errors.length > 0,"disabled":_vm.$wait.is('requesting quotation'),"name":"format","required":""},on:{"input":_vm.updateFormat},model:{value:(_vm.formatData),callback:function ($$v) {_vm.formatData=$$v},expression:"formatData"}})]}}])}),_c('ValidationProvider',{ref:"quantity-provider",attrs:{"name":_vm.$t('app.labels.load.quantity'),"rules":"required|min_value:1","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var invalid = ref.invalid;
          var validated = ref.validated;
          var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-3 2sm:tw-mb-0",class:[
            _vm.formatData === 'other' && '2sm:tw-w-1/4 2sm:tw-mr-3',
            _vm.formatData !== 'other' && '2sm:tw-w-1/2'
          ],attrs:{"id":("quantity-" + _vm.index),"error":invalid && validated || errors && errors.length > 0,"label":_vm._f("capitalize")(_vm.$t('app.labels.load.quantity')),"disabled":_vm.$wait.is('requesting quotation'),"name":"quantity","type":"tel","required":""},on:{"input":_vm.updateGuard},model:{value:(_vm.quantityData),callback:function ($$v) {_vm.quantityData=$$v},expression:"quantityData"}})]}}])}),(_vm.formatData === 'other')?[_c('ValidationProvider',{ref:"length-provider",attrs:{"name":_vm.$t('new-shipment.fields.goods.length'),"rules":'required|min_value:1|max_value:1320|numeric',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var invalid = ref.invalid;
          var validated = ref.validated;
          var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-4 2sm:tw-mr-3 2sm:tw-w-1/4 2sm:tw-mb-0 tw-flex-1",attrs:{"id":("length-" + _vm.index),"error":invalid && validated || errors && errors.length > 0,"label":_vm.$t('new-shipment.labels.goods.length'),"disabled":_vm.$wait.is('requesting quotation'),"name":"length","type":"tel","suffix":"cm","required":""},on:{"input":_vm.updateGuard},model:{value:(_vm.lengthData),callback:function ($$v) {_vm.lengthData=$$v},expression:"lengthData"}})]}}],null,false,2717096274)}),_c('ValidationProvider',{ref:"width-provider",attrs:{"name":_vm.$t('new-shipment.fields.goods.width'),"rules":'required|min_value:1|max_value:240|numeric',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var invalid = ref.invalid;
          var validated = ref.validated;
          var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-4 2sm:tw-w-1/4 2sm:tw-mb-0 tw-flex-1",attrs:{"id":("width-" + _vm.index),"error":invalid && validated || errors && errors.length > 0,"label":_vm.$t('new-shipment.labels.goods.width'),"disabled":_vm.$wait.is('requesting quotation'),"name":"width","type":"tel","suffix":"cm","required":""},on:{"input":_vm.updateGuard},model:{value:(_vm.widthData),callback:function ($$v) {_vm.widthData=$$v},expression:"widthData"}})]}}],null,false,3607940043)})]:_vm._e()],2),(_vm.removable)?_c('UiButton',{staticClass:"!tw-ml-1 !tw-min-w-0",class:[
      _vm.formatData !== 'other' && '2sm:tw--ml-3'
    ],attrs:{"title":_vm.$t('new-shipment.buttons.load.delete'),"type":"button","variant":"link","size":"sm"},on:{"click":_vm.remove},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('ui-ctk-icon',{staticClass:"tw-hidden 2sm:tw-inline-block !md:tw-mr-0",attrs:{"name":"close"}})]},proxy:true}],null,false,3202716716)},[_c('span',{staticClass:"2sm:tw-hidden"},[_vm._v(" "+_vm._s(_vm.$t('new-shipment.buttons.load.delete'))+" ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }