<template>
  <div
    ref="listbox"
    :aria-label="$tc('app.labels.saved_cards', items.length)"
    :disabled="$attrs.disabled"
    :aria-disabled="$attrs.disabled"
    :tabindex="$attrs.disabled ? -1 : 0"
    role="listbox"
    class="stripe-card-select"
  >
    <stripe-card-item
      v-for="item in items"
      :id="`card-item-${item.source_id}`"
      :key="item.source_id"
      :type="item.card_brand"
      :holder-name="item.card_holder"
      :last-four="item.last_four_digits"
      :expires-at="item.expiration_date"
      :active="selectValue === item.source_id"
      :aria-selected="selectValue === item.source_id"
      :disabled="$attrs.disabled"
      tabindex="-1"
      role="option"
      class="tw-w-full"
      @click.native="selectValue = item.source_id"
    />
  </div>
</template>

<script>
  import { ref } from '@vue/composition-api'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'
  import useListBoxAria from '@/composables/useListBoxAria'
  import StripeCardItem from '@/components/Stripe/StripeCardItem/index.vue'

  /**
   * @module component - StripeCardSelect
   * @param {Array<any>} items
   */
  export default {
    name: 'StripeCardSelect',
    inheritAttrs: false,
    components: {
      StripeCardItem
    },
    props: {
      value: {
        type: String,
        default: null
      },
      items: {
        type: Array,
        required: true
      }
    },
    setup (props) {
      const listbox = ref(null)

      useListBoxAria(listbox)
      const { state: selectValue } = useModelGetterSetter(props, 'value')

      return {
        listbox,
        selectValue
      }
    },
    data () {
      return {
        active: null,
        hasFocus: false
      }
    }
  }
</script>

<style lang="scss" scoped>
.stripe-card-select .stripe-card-item:not(:last-child) {
  margin-bottom: 0.75rem;
}
</style>
