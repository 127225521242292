<template>
  <div class="price-total tw-flex tw-flex-col tw-items-end">
    <price-chip
      class="tw-text-base tw-ml-auto"
    >
      {{ priceExcluded }}
    </price-chip>
    <span
      v-text="priceIncluded"
      class="price-total__included tw-italic tw-text-blue-500 tw-text-right tw-text-sm"
      data-test="included"
    />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import PriceChip from './_subs/PriceChip/index.vue'

  /**
   * Component used to show the total price with the tax included & excluded
   * @module component - PriceTotal
   * @param {string} priceExcluded
   * @param {string} priceIncluded
   */
  export default defineComponent({
    name: 'PriceTotal',
    components: {
      PriceChip
    },
    props: {
      priceExcluded: {
        type: String,
        required: true
      },
      priceIncluded: {
        type: String,
        required: true
      }
    }
  })
</script>
