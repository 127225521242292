var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-shipment-goods"},[_c('h2',{staticClass:"new-shipment-goods__title tw-font-normal tw-mb-6",domProps:{"textContent":_vm._s(_vm.$t('new-shipment.titles.goods'))}}),_c('ValidationObserver',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitted.apply(null, arguments)}}},[_c('new-shipment-goods-types',{staticClass:"mb-5",on:{"input":_vm.clearFields},model:{value:(_vm.formData.goodType),callback:function ($$v) {_vm.$set(_vm.formData, "goodType", $$v)},expression:"formData.goodType"}}),_c('new-shipment-alert',[(_vm.formData.goodType === 'pallets')?_c('p',{staticClass:"tw-mb-0",domProps:{"textContent":_vm._s(_vm.$t('new-shipment.paragraphs.goods.explanation.multipallets'))}}):_vm._e(),(_vm.formData.goodType === 'custom')?_c('p',{staticClass:"tw-mb-0",domProps:{"textContent":_vm._s(_vm.$t('new-shipment.paragraphs.goods.explanation.custom_pallets'))}}):_vm._e(),(_vm.formData.goodType === 'bulk')?_c('p',{staticClass:"tw-mb-0",domProps:{"textContent":_vm._s(_vm.$t('new-shipment.paragraphs.goods.explanation.bulk_pallets'))}}):_vm._e()]),(_vm.formData.goodType === 'pallets')?[_c('new-shipment-goods-pallet-group',{staticClass:"tw-mb-4"}),_c('new-shipment-goods-pallet-total',{ref:"pallet-total",attrs:{"height":_vm.formData.height,"weight":_vm.formData.weight},on:{"update:height":function($event){return _vm.$set(_vm.formData, "height", $event)},"update:weight":function($event){return _vm.$set(_vm.formData, "weight", $event)}}})]:[_c('div',{staticClass:"tw-flex tw-flex-col 2sm:tw-flex-row"},[_c('ValidationProvider',{ref:"length-provider",attrs:{"name":_vm.$t('new-shipment.fields.goods.length'),"rules":'required|min_value:1|max_value:1320|numeric',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var fieldErrors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-4 2sm:tw-mr-4 2sm:tw-mb-0 tw-flex-1",attrs:{"id":"length","error":invalid && validated || fieldErrors && fieldErrors.length > 0,"label":_vm.$t('new-shipment.labels.goods.length'),"name":"length","type":"tel","suffix":"cm","required":""},on:{"input":_vm.updateGuard},model:{value:(_vm.formData.length),callback:function ($$v) {_vm.$set(_vm.formData, "length", $$v)},expression:"formData.length"}})]}}],null,true)}),_c('ValidationProvider',{ref:"width-provider",attrs:{"name":_vm.$t('new-shipment.labels.goods.width'),"rules":'required|min_value:1|max_value:240|numeric',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var fieldErrors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-4 2sm:tw-mr-4 2sm:tw-mb-0 tw-flex-1",attrs:{"id":"width","error":invalid && validated || fieldErrors && fieldErrors.length > 0,"label":_vm.$t('new-shipment.labels.goods.width'),"name":"width","type":"tel","suffix":"cm","required":""},on:{"input":_vm.updateGuard},model:{value:(_vm.formData.width),callback:function ($$v) {_vm.$set(_vm.formData, "width", $$v)},expression:"formData.width"}})]}}],null,true)}),_c('ValidationProvider',{ref:"height-provider",attrs:{"rules":'required|min_value:1|max_value:270|numeric',"name":_vm.$t('new-shipment.fields.goods.height'),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var fieldErrors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-4 2sm:tw-mr-4 2sm:tw-mb-0 tw-flex-1",attrs:{"id":"height","error":invalid && validated || fieldErrors && fieldErrors.length > 0,"label":_vm.$t('new-shipment.labels.goods.height_total'),"name":"height","type":"tel","suffix":"cm","required":""},on:{"input":_vm.updateGuard},model:{value:(_vm.formData.height),callback:function ($$v) {_vm.$set(_vm.formData, "height", $$v)},expression:"formData.height"}})]}}],null,true)}),_c('ValidationProvider',{ref:"weight-provider",attrs:{"name":_vm.$t('app.labels.load.weight'),"rules":'required|min_value:50|max_value:28000|numeric',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var fieldErrors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-4 tw-flex-1",attrs:{"id":"weight","error":invalid && validated || fieldErrors && fieldErrors.length > 0,"label":_vm.$t('new-shipment.labels.goods.weight_total'),"name":"weight","type":"tel","suffix":"kg","required":""},on:{"input":_vm.updateGuard},model:{value:(_vm.formData.weight),callback:function ($$v) {_vm.$set(_vm.formData, "weight", $$v)},expression:"formData.weight"}})]}}],null,true)})],1)],_c('new-shipment-alert',[_c('p',{staticClass:"tw-mb-0"},[_vm._v(" "+_vm._s(_vm.$t(_vm.formData.goodType === 'pallets' ? 'new-shipment.paragraphs.goods.description_explanation.pallets' : _vm.formData.goodType === 'custom' ? 'new-shipment.paragraphs.goods.description_explanation.custom' : 'new-shipment.paragraphs.goods.description_explanation.bulk'))+" ")]),(_vm.formData.goodType === 'custom')?_c('p',{staticClass:"tw-mb-0 tw-mt-3 tw-p-3 tw-border tw-border-solid tw-border-white tw-rounded"},[_vm._v(" "+_vm._s(_vm.$t('new-shipment.paragraphs.goods.description_explanation.custom.example'))+" ")]):_vm._e()]),_c('ValidationProvider',{ref:"description-provider",attrs:{"name":_vm.$t('new-shipment.fields.goods.description'),"rules":_vm.isDescriptionRequired ? 'required|max:4000' : 'max:4000',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('ctk-input-textarea',{attrs:{"id":"description","error":invalid && validated,"label":_vm.$t('new-shipment.labels.goods.description'),"required":_vm.isDescriptionRequired,"name":"description"},on:{"input":_vm.updateGuard},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})]}}],null,true)}),(_vm.mappedErrors(errors).length > 0)?_c('div',{staticClass:"mt-3"},_vm._l((_vm.mappedErrors(errors)),function(error,k){return _c('div',{key:k,staticClass:"error-banner tw-rounded px-3 py-2 mb-2"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e(),(_vm.formData.goodType === 'pallets' && _vm.getPalletsMpl > 13.2)?_c('div',{staticClass:"error-banner tw-mt-3 tw-rounded px-3 py-2 mb-2"},[_vm._v(" "+_vm._s(_vm.$t('new-shipment.paragraphs.goods.max_linear_meter'))+" ")]):_vm._e(),_c('div',{staticClass:"new-shipment-goods__buttons tw-flex tw-flex-col-reverse 2sm:tw-flex-row 2sm:tw-justify-between tw-mt-6"},[_c('div',{staticClass:"tw-mt-4 2sm:tw-mt-0"},[_c('ui-button',{staticClass:"tw-w-full 2sm:tw-w-auto",attrs:{"variant":"link","type":"button"},on:{"click":_vm.back},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('ui-material-icon',{attrs:{"name":"keyboard_arrow_left"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t('back')))+" ")])],1),_c('ui-button',{attrs:{"loading":_vm.$wait.is('requesting quotation'),"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('new-shipment.buttons.request_quotation'))+" ")])],1)],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }