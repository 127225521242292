<template>
  <div class="new-shipment-sidebar-group-item-address dots-text">
    <div
      class="new-shipment-sidebar-group-item-address__name tw-font-medium dots-text"
      data-test="name"
    >
      {{ address.name }}
    </div>
    <div
      class="new-shipment-sidebar-group-item-address__address tw-text-gray-700 fs-13 dots-text"
      data-test="address"
    >
      {{ address.street_name || address.name }}, {{ address.city }} {{ address.postal_code }}, {{ $t(address.country) }}
    </div>
  </div>
</template>

<script>
  /**
   * @module component - NewShipmentSidebarGroupItemAddress
   * @param {object} address
   */
  export default {
    name: 'NewShipmentSidebarGroupItemAddress',
    props: {
      address: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>

  .new-shipment-sidebar-group-item-address {
    line-height: 1.2em;

    &__name {
      color: $primary-text;
      text-transform: uppercase;
    }
  }

</style>
