<template>
  <div class="new-shipment-informations-expiration-tip">
    <div class="tw-flex tw-justify-between tw-items-center">
      <h3
        class="tw-flex tw-items-center tw-text-blue-500 tw-text-xl tw-mb-2 new-shipment-informations-expiration-tip__title"
      >
        <ui-ctk-icon
          class="new-shipment-informations-expiration-tip__title__icon"
          name="tips"
          data-test="icon"
        /> {{ $t('new-shipment.titles.quotation.tip') }}
      </h3>
      <button
        :title="$t('close') | capitalize"
        class="btn tw-rounded-full tw-m-0 tw-p-0"
        type="button"
        data-test="close"
        @click="$emit('close')"
      >
        <ui-ctk-icon
          class="tw-text-xl"
          name="close"
          data-test="icon"
        />
      </button>
    </div>
    <i18n
      path="new-shipment.paragraphs.quotation.expiration_tip"
      class="tw-leading-tight tw-text-gray-700 tw-text-sm tw-mb-0"
      tag="p"
      data-test="content"
    >
      <template #search_delay>
        <span
          v-text="$t('new-shipment.paragraphs.quotation.expiration_tip_search_delay')"
          class="tw-font-bold"
          data-test="content-delay"
        />
      </template>
    </i18n>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - NewShipmentInformationsExpirationTip
   */
  export default defineComponent({
    name: 'NewShipmentInformationsExpirationTip'
  })
</script>

<style lang="scss" scoped>

  .new-shipment-informations-expiration-tip {
    &__title__icon {
      margin-left: -10px;
    }
  }

</style>
