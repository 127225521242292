<template>
  <div
    :class="[`--${direction}`]"
    class="new-shipment-handling-direction"
  >
    <h3
      class="new-shipment-handling-direction__title tw-font-normal tw-flex tw-items-center"
      data-test="title"
    >
      <ui-ctk-icon
        name="calendar"
        data-test="icon"
      /> {{ $t(direction) | capitalize }}
    </h3>

    <slot />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - NewShipmentHandlingDirection
   * @param {string} direction
   */
  export default defineComponent({
    name: 'NewShipmentHandlingDirection',
    props: {
      direction: {
        type: String,
        required: true
      }
    }
  })
</script>

<style lang="scss" scoped>

  .new-shipment-handling-direction {
    &__title {
      position: relative;
      left: -4px;
      font-size: 16px;

      .ctk-font {
        font-size: 24px;
      }
    }

    &.--pickup .new-shipment-handling-direction__title .ctk-font {
      color: $info;
    }

    &.--delivery .new-shipment-handling-direction__title .ctk-font {
      color: $primary;
    }
  }

</style>
