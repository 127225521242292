<template>
  <ctk-input-select-list
    v-model="selectedItem"
    ref="select-list"
    class="new-shipment-address-not-found-dialog-results tw-w-full tw-m-0 tw-p-0"
    id="predictions"
    :items="formattedResults"
    @mouseover-item="item => $emit('mouseover-item', item)"
    @mouseout-item="item => $emit('mouseout-item', item)"
  >
    <template
      #item="{ item }"
    >
      <div class="tw-flex tw-w-full tw-items-center">
        <ui-ctk-icon
          name="marker"
          data-test="icon"
        />
        <span class="dots-text">
          {{ item.text }}
        </span>
      </div>
    </template>
  </ctk-input-select-list>
</template>

<script>
  import { defineComponent, watch } from '@vue/composition-api'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'
  import useMatomo from '@/composables/useMatomo'
  import CtkInputSelectList from '@/components/CtkInputs/CtkInputSelect/_subs/CtkInputSelectList/index.vue'

  /**
   * @module component - NewShipmentAddressNotFoundDialogResults
   */
  export default defineComponent({
    name: 'NewShipmentAddressNotFoundDialogResults',
    components: {
      CtkInputSelectList
    },
    props: {
      results: {
        type: Array,
        required: true
      },
      value: {
        type: String,
        default: null
      }
    },
    setup (props) {
      const { state: selectedItem } = useModelGetterSetter(props, 'value')
      const matomo = useMatomo()

      watch(selectedItem, () => {
        if (matomo) {
          matomo.trackEvent('Quotations', 'Find Address Pick')
        }
      })

      return {
        selectedItem
      }
    },
    computed: {
      /**
       * Returns a list of predictions with custom formatted data
       * @function formattedResults
       * @returns {Array<any>} results
       */
      formattedResults () {
        return this.results
          // @ts-ignore
          .filter((/** @type {import('@/models/AddressComponent/index').default} */ result) => !!result.component)
          .map((/** @type {import('@/models/AddressComponent/index').default} */ result) => ({
            ...result,
            value: result.component.place_id,
            text: result.component.formatted_address
          }))
          .slice(0, 10)
      }
    }
  })
</script>

<style lang="scss">

  .new-shipment-address-not-found-dialog-results .ctk-input-select-list__item {
    border-bottom: 1px solid $divider;
  }

  .new-shipment-address-not-found-dialog-results .ctk-input-select-list__item:not(.selected) {
    .icon-ctk-marker {
      color: #A4A4A4;
    }

    &:hover .icon-ctk-marker,
    &.hovered .icon-ctk-marker {
      color: $info;
    }
  }

</style>
