<template>
  <div
    class="shipment-payment-form"
  >
    <ValidationObserver
      ref="observer"
      slim
    >
      <form
        :disabled="$wait.is(loader) || expired || (hasCardsSelect && !formData.sourceId)"
        class="tw-w-full"
        data-test="form"
        @submit.prevent="submitted"
      >
        <tabs-bar
          v-if="getPaymentSources.length > 0"
          v-model="currentTab"
          :tabs="tabs"
          class="tw-mb-6"
          data-test="tabs"
        />

        <stripe-card-select
          v-if="hasCardsSelect"
          v-model="formData.sourceId"
          :items="getPaymentSources"
          :disabled="$wait.is(loader) || expired"
          class="tw-mb-8"
          data-test="cards"
          @input="selectCard"
        />

        <fieldset
          v-if="getPaymentSources.length === 0 || (getPaymentSources.length > 0 && currentTab === 1)"
          :class="{
            'tw-opacity-75': expired
          }"
          class="stripe-group tw-mb-6"
          data-test="stripe-group"
        >
          <legend
            class="tw-text-sm tw-font-medium tw-text-gray-700"
            v-text="$t('app.labels.cc_informations')"
            data-test="legend"
          />

          <ValidationProvider
            :name="$t('app.fields.cc_holder')"
            rules="required|max:255"
            slim
          >
            <template slot-scope="{ errors, invalid, validated }">
              <ctk-input-text
                id="cardHolder"
                v-model="formData.cardHolder"
                name="cardHolder"
                type="text"
                :error="!$wait.is(loader) && invalid && validated"
                :hint="errors && errors[0]"
                :disabled="$wait.is(loader) || expired"
                :label="$t('app.labels.cc_holder')"
                :class="{
                  'tw-opacity-75': expired
                }"
                data-test="cardHolder"
                class="tw-mb-6"
                required
                autocomplete="name"
              />
            </template>
          </ValidationProvider>

          <stripe-element-group
            :stripe="stripe"
            data-test="stripe-element-group"
          >
            <template
              slot-scope="{ stripeElements }"
            >
              <stripe-card-number
                ref="card-number"
                :disabled="$wait.is(loader) || expired"
                :stripe-elements="stripeElements"
              />
              <div class="tw-flex">
                <stripe-card-expiry
                  :disabled="$wait.is(loader) || expired"
                  :stripe-elements="stripeElements"
                  class="tw-w-1/2"
                />
                <stripe-card-cvc
                  :disabled="$wait.is(loader) || expired"
                  :stripe-elements="stripeElements"
                  class="tw-w-1/2"
                />
              </div>
            </template>
          </stripe-element-group>

          <b-form-checkbox
            :disabled="$wait.is(loader) || expired"
            v-model="formData.saveCard"
            class="tw-text-secondary-text tw-mt-4"
            data-test="save-later"
          >
            <div class="tw-inline tw--mt-1">
              <span
                v-text="$t('app.labels.save_card')"
              />

              <ui-material-icon
                :title="$t('app.paragraphs.save_card')"
                v-b-tooltip.hover
                class="tw-text-base tw-align-middle tw-ml-1"
                name="info"
                data-test="info"
              />
            </div>
          </b-form-checkbox>
        </fieldset>

        <!-- Notice section -->
        <slot name="notice" />

        <ui-button
          :class="{
            'tw-opacity-75': expired
          }"
          :disabled="$wait.is(loader) || expired || (hasCardsSelect && !formData.sourceId)"
          :loading="$wait.is(loader)"
          type="submit"
          class="tw-w-full tw-mb-2"
          variant="primary"
          data-test="pay"
        >
          <template #right-icon>
            <ui-material-icon
              name="lock"
              data-test="icon"
            />
          </template>

          <i18n
            :path="buttonLabel"
            tag="span"
            data-test="content"
          >
            <template #amount>
              <span
                class="tw-font-medium"
                v-text="$options.filters.currency(amount, currency, $i18n.locale)"
              />
            </template>
          </i18n>
        </ui-button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { TabsBar } from '@/components/CtkTabsLayout'
  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'
  import StripeElementGroup from '@/components/Stripe/StripeElementGroup/index.vue'
  import StripeCardNumber from '@/components/Stripe/StripeCardNumber/index.vue'
  import StripeCardExpiry from '@/components/Stripe/StripeCardExpiry/index.vue'
  import StripeCardCvc from '@/components/Stripe/StripeCardCvc/index.vue'
  import StripeCardSelect from '@/components/Stripe/StripeCardSelect/index.vue'

  /**
   * Generic component for the payment form only.
   *
   * @module component - ShipmentPaymentForm
   * @param {string} buttonLabel - Wording shown in the "pay" button. Should be the translation key.
   * @param {string} loader - The loader key to be used
   * that's line-through
   * @param {number} amount - Amount to be paid
   * @param {string} currency - Currency used for initialAmount and amount
   * @param {boolean} [expired=false]
   */
  export default {
    name: 'ShipmentPaymentForm',
    components: {
      CtkInputText,
      StripeElementGroup,
      StripeCardNumber,
      StripeCardExpiry,
      StripeCardCvc,
      StripeCardSelect,
      TabsBar
    },
    props: {
      stripe: {
        type: Object,
        default: null
      },
      amount: {
        type: Number,
        required: true
      },
      currency: {
        type: String,
        required: true
      },
      loader: {
        type: String,
        required: true
      },
      buttonLabel: {
        type: String,
        required: true
      },
      expired: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        currentTab: 0,
        formData: {
          sourceId: null,
          cardHolder: null,
          saveCard: false
        }
      }
    },
    watch: {
      currentTab () {
        /**
         * Reset the form data whenever the user changes tabs.
         */
        this.formData = {
          sourceId: null,
          cardHolder: null,
          saveCard: false
        }

        this.setDefaultPaymentSource()
      }
    },
    mounted () {
      this.setDefaultPaymentSource()

      if (this.hasCardsSelect && this.$matomo) {
        this.$matomo.trackEvent('Payment', 'Payment Sources', 'Displayed', this.getPaymentSources.length)
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getPaymentSources'
      ]),
      /**
       * @function tabs
       * @returns {Array<{label: any}>}
       */
      tabs () {
        return [
          { label: this.$tc('app.labels.saved_cards', this.getPaymentSources.length) },
          { label: this.$t('app.labels.new_card') }
        ]
      },
      /**
       * @function hasCardsSelect
       * @returns {boolean}
       */
      hasCardsSelect () {
        return this.getPaymentSources.length > 0 && this.currentTab === 0
      }
    },
    methods: {
      /**
       * Set the default source id for the first item if there is a
       * single card available. Otherwise ignore and let the user
       * select it by himself.
       * @function setDefaultPaymentSource
       */
      setDefaultPaymentSource () {
        if (this.getPaymentSources.length === 1) {
          this.formData.sourceId = this.getPaymentSources[0].source_id
        }
      },
      /**
       * @function selectCard
       */
      selectCard () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Payment', 'Payment Sources', 'Clicked')
        }
      },
      /**
       * Called whenever the form is submitted
       * @function submitted
       */
      submitted () {
        if (this.$wait.is(this.loader)) return
        if (this.expired) return

        this.$refs.observer.validate()
          .then((/** @type {boolean} */ valid) => {
            if (!valid) {
              return
            }

            const { cardHolder, sourceId, saveCard } = this.formData

            if (saveCard && this.$matomo) {
              this.$matomo.trackEvent('Payment', 'Payment Form', 'Submitted With Save Card')
            }

            if (this.hasCardsSelect) {
              if (!this.formData.sourceId) {
                this.$wait.end(this.loader)
                return
              }

              if (this.$matomo) {
                this.$matomo.trackEvent('Payment', 'Payment Form', 'Submitted With Saved Card')
              }

              this.$emit('submitted', {
                cardHolder,
                sourceId,
                saveCard
              })
            } else {
              const cardElement = this.$refs['card-number'].$refs['stripe-element'].stripeElement

              this.$emit('submitted', {
                cardHolder,
                saveCard,
                cardElement
              })
            }
          })
      }
    }
  }
</script>

<style lang="scss">
.shipment-payment-form .stripe-group .stripe-card-number {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-bottom: -1px;
}
.shipment-payment-form .stripe-group .stripe-card-expiry {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-right: -1px;
}
.shipment-payment-form .stripe-group .stripe-card-cvc {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  width: calc(50% + 1px);
}
</style>
