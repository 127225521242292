<template>
  <component
    :is="!!to ? 'router-link' : 'div'"
    :to="to"
    :class="{
      disabled: $attrs.disabled
    }"
    :event="$attrs.disabled ? '' : 'click'"
    :title="!!to ? name : null"
    class="new-shipment-sidebar-group-item tw-flex tw-items-center"
  >
    <div
      class="new-shipment-sidebar-group-item__icon mr-2"
      data-test="icon"
    >
      <slot name="icon" />
    </div>
    <slot>
      <span
        data-test="name"
      >
        {{ name }}
      </span>
    </slot>
  </component>
</template>

<script>
  /**
   * @module component - NewShipmentSidebarGroupItem
   * @param {string} name
   * @param {object} [to=null] - Specify the destination of the link. If not defined, it will
   * be a simple div.
   */
  export default {
    name: 'NewShipmentSidebarGroupItem',
    props: {
      name: {
        type: String,
        required: true
      },
      to: {
        type: Object,
        default: null
      }
    }
  }
</script>

<style lang="scss" scoped>

  .new-shipment-sidebar-group-item {
    border: 1px solid $divider;
    min-height: 44px;
    color: $secondary-text;
    text-decoration: none;
    padding: 4px 0;

    &:hover,
    &.disabled {
      background-color: rgba(black, 0.03);
    }

    &__icon {
      width: 25px;
    }

    &.router-link-exact-active {
      border: 1px solid $info;
      color: $primary-text;
    }

    &.disabled {
      opacity: 0.7;
      filter: grayscale(1);
      cursor: not-allowed;
    }
  }

</style>
