<template>
  <div class="new-shipment-template-list-item tw-flex tw-flex-col md:tw-flex-row tw-p-4 tw-rounded">
    <div class="tw-flex tw-flex-col md:tw-flex-row tw-full md:tw-w-1/2 tw-mb-4 md:tw-mb-0">
      <new-shipment-template-list-item-address
        :direction="'pickup'"
        :shipment="shipment"
        class="tw-mb-4 md:tw-mb-0 md:tw-w-1/2"
        data-test="pickup"
      />
      <new-shipment-template-list-item-address
        :direction="'delivery'"
        :shipment="shipment"
        class="md:tw-w-1/2"
        data-test="delivery"
      />
    </div>
    <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-w-1/2 tw-justify-between tw-items-center">
      <ctk-goods-infos-load
        :load="shipment.load"
        class="tw-flex-1 tw-w-full tw-mb-4 md:tw-mb-0"
        data-test="load"
      />
      <ui-button
        variant="info"
        size="sm"
        type="button"
        class="tw-w-full md:tw-w-auto"
        data-test="select-button"
        @click="$emit('click')"
      >
        {{ $t('app.buttons.select') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
  import CtkGoodsInfosLoad from '@/components/CtkGoodsInfos/_subs/CtkGoodsInfosLoad/index.vue'
  import NewShipmentTemplateListItemAddress from './_subs/NewShipmentTemplateListItemAddress/index.vue'

  /**
   * @module component - NewShipmentTemplateListItem
   * @param {object} shipment
   */
  export default {
    name: 'NewShipmentTemplateListItem',
    components: {
      CtkGoodsInfosLoad,
      NewShipmentTemplateListItemAddress
    },
    props: {
      shipment: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>

  .new-shipment-template-list-item {
    background-color: #F9F9F9;
    border: 1px solid #F2F2F2;

    &:hover {
      background-color: #F2F2F2;
    }
  }

</style>
