<template>
  <div class="new-shipment-handling-tail-lift">
    <label
      data-test="label"
    >
      {{ $t('new-shipment.labels.tail-lift') }}
    </label>
    <div
      class="tw-flex new-shipment-handling-tail-lift__buttons"
      data-test="buttons"
    >
      <new-shipment-handling-button
        :icon="'hatch2'"
        :title="$t('no') | capitalize"
        :active="selectedValue === 'no'"
        :class="{
          'has-error': !!error
        }"
        data-test="no"
        class="mr-3 tw-flex-1"
        @click.native="selectedValue = 'no'"
      />
      <new-shipment-handling-button
        :icon="'hatch'"
        :title="`${$options.filters.capitalize($t('yes'))} **`"
        :active="selectedValue === 'yes'"
        :class="{
          'has-error': !!error
        }"
        data-test="yes"
        class="tw-flex-1"
        @click.native="selectedValue = 'yes'"
      />
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  import NewShipmentHandlingButton from './../../../NewShipmentHandlingButton/index.vue'

  /**
   * @module component - NewShipmentHandlingTailLift
   * @param {string} direction
   * @param {string} [error=null]
   */
  export default defineComponent({
    name: 'NewShipmentHandlingTailLift',
    props: {
      value: {
        type: String,
        default: null
      },
      direction: {
        type: String,
        required: true
      },
      error: {
        type: String,
        default: null
      }
    },
    components: {
      NewShipmentHandlingButton
    },
    setup (props) {
      const { state: selectedValue } = useModelGetterSetter(props, 'value')

      return {
        selectedValue
      }
    }
  })
</script>

<style lang="scss" scoped>

  .new-shipment-handling-tail-lift {
    .new-shipment-handling-button {
      width: 50%;

      &.has-error {
        border: 1px solid $danger;
      }
    }

    @media only screen and (max-width: $breakpoint-mobile-s) {
      &__buttons {
        flex-direction: column;

        .new-shipment-handling-button {
          width: 100%;

          &:first-child {
            margin-bottom: 16px;
          }
        }
      }
    }
  }

</style>
