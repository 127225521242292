<template>
  <div class="new-shipment-goods-pallet-group">
    <div class="new-shipment-goods-pallet-group__rows tw-mb-4">
      <new-shipment-goods-pallet-row
        v-for="(pallet, k) in getPallets"
        :key="k"
        :index="k"
        :quantity="pallet.quantity"
        :format="pallet.format"
        :length="pallet.length"
        :width="pallet.width"
        :removable="getPallets.length > 1"
        :class="[
          getPallets.length === 1 && 'new-shipment-goods-pallet-row--single'
        ]"
        class="tw-mb-2"
        @remove="removePallet(k)"
      />
    </div>
    <div class="tw-flex tw-justify-start tw-items-center">
      <UiButton
        type="button"
        variant="link"
        data-test="add-button"
        @click="add"
      >
        <template #left-icon>
          <ui-material-icon
            name="add_circle"
            data-test="icon"
          />
        </template>
        <div
          v-text="$t('new-shipment.buttons.load.add')"
        />
      </UiButton>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import Hotjar from '@/plugins/VueHotjar'

  import NewShipmentGoodsPalletRow from './../NewShipmentGoodsPalletRow/index.vue'

  /**
   * @module component - NewShipmentGoodsPalletGroup
   */
  export default {
    name: 'NewShipmentGoodsPalletGroup',
    components: {
      NewShipmentGoodsPalletRow
    },
    computed: {
      ...mapGetters('shipments/new-shipment', [
        'getPallets'
      ])
    },
    methods: {
      ...mapActions('shipments/new-shipment', [
        'addPallet',
        'removePallet'
      ]),
      add () {
        this.addPallet()
        Hotjar.tag('Shipments with multi-pallets')
      }
    }
  }
</script>

<style lang="scss">
.new-shipment-goods-pallet-group__rows .new-shipment-goods-pallet-row:not(:first-child) {
  padding-top: 0.5rem;
  border-top: 1px solid $light-gray;
}
@media (min-width: 770px) {
  .new-shipment-goods-pallet-group__rows .new-shipment-goods-pallet-row .new-shipment-goods-pallet-row__fields--format {
    padding-right: 0.75rem;
  }
}
</style>
