<template>
  <div class="new-shipment-sidebar">
    <h2
      class="new-shipment-sidebar__title tw-font-normal mb-4"
      data-test="title"
    >
      {{ $t('new-shipment.titles.your_quote') }}
    </h2>
    <div class="new-shipment-sidebar__navigation-groups">
      <new-shipment-sidebar-group
        data-test="address"
        class="tw-mb-8"
      >
        <new-shipment-sidebar-group-item
          v-for="direction in ['pickup', 'delivery']"
          :key="direction"
          :name="$t(`new-shipment.titles.address.${direction}`)"
          :to="{
            name: 'NewShipmentAddress',
            params: {
              direction
            }
          }"
          :disabled="direction === 'delivery' && !isPickupAddressCompleted"
          :data-test="direction"
        >
          <template #icon>
            <ui-ctk-icon
              :class="[
                direction === 'pickup' ? 'tw-text-blue-500' : 'tw-text-green-500'
              ]"
              name="marker"
              data-test="icon"
            />
          </template>
          <new-shipment-sidebar-group-item-address
            v-if="getAddress(direction) && getAddress(direction).city"
            :address="getAddress(direction)"
          />
        </new-shipment-sidebar-group-item>
      </new-shipment-sidebar-group>
      <new-shipment-sidebar-group
        class="tw-mb-8"
        data-test="goods"
      >
        <new-shipment-sidebar-group-item
          :name="$t('new-shipment.titles.goods_summary')"
          :to="{
            name: 'NewShipmentGoods'
          }"
          :disabled="!isAddressesCompleted"
        >
          <template #icon>
            <ui-ctk-icon
              name="dimension"
              class="tw-text-blue-500"
              data-test="icon"
            />
          </template>
          <shipment-load-summary
            v-if="!!getLoad.type && !!getLoad.height && !!getLoad.weight"
            :load="getLoad"
            :pallets="getLoad.pallets"
          />
        </new-shipment-sidebar-group-item>
      </new-shipment-sidebar-group>
      <new-shipment-sidebar-group
        :to="{
          name: 'NewShipmentHandling'
        }"
        :disabled="!isGoodsCompleted"
        class="tw-mb-8"
        data-test="handling"
      >
        <new-shipment-sidebar-group-item
          v-for="direction in ['pickup', 'delivery']"
          :key="direction"
          :name="$t(`new-shipment.titles.handling.${direction}`)"
          :data-test="direction"
        >
          <template #icon>
            <ui-ctk-icon
              :class="[
                direction === 'pickup' ? 'tw-text-blue-500' : 'tw-text-green-500'
              ]"
              name="hatch"
              data-test="icon"
            />
          </template>
          <new-shipment-sidebar-group-item-handling
            v-if="getHandling(direction).driver"
            :direction="direction"
            :driver="getHandling(direction).driver"
            :tail-lift="getHandling(direction).tailLift"
          />
        </new-shipment-sidebar-group-item>
      </new-shipment-sidebar-group>
      <new-shipment-sidebar-group
        :to="{
          name: 'NewShipmentDates'
        }"
        :disabled="!isHandlingsCompleted"
        data-test="dates"
        class="tw-mb-8"
      >
        <new-shipment-sidebar-group-item
          v-for="direction in ['pickup', 'delivery']"
          :key="direction"
          :name="$t(`new-shipment.titles.dates_summary.${direction}`)"
          :data-test="direction"
        >
          <template #icon>
            <ui-ctk-icon
              :class="[
                direction === 'pickup' ? 'tw-text-blue-500' : 'tw-text-green-500'
              ]"
              name="calendar"
              data-test="icon"
            />
          </template>
          <new-shipment-sidebar-group-item-date
            v-if="getDate(direction)"
            :direction="direction"
            :flexible="isFlexible(direction)"
            :date="getDate(direction)"
            :timeslot="getTimeslot(direction)"
          />
        </new-shipment-sidebar-group-item>
      </new-shipment-sidebar-group>
      <new-shipment-sidebar-group
        :class="{
          'tw-mb-8': !(priceSurge && $route.name === 'NewShipmentInformations')
        }"
        class="tw-hidden md:tw-flex"
        data-test="quotation"
      >
        <new-shipment-sidebar-group-item
          :name="$t('new-shipment.titles.quotation')"
          :to="{
            name: 'NewShipmentQuotation'
          }"
          :disabled="!(getQuotation && isDatesCompleted)"
        >
          <template #icon>
            <img
              src="@/assets/img/icons/quotation/budget.svg"
              alt=""
              width="16"
              class="tw-ml-2"
              data-test="icon"
            >
          </template>

          <div class="tw-flex-1 tw-flex tw-justify-between tw-items-center tw-pr-2 tw-py-1">
            <div
              v-if="!(getQuotation && getQuotationSelectedPrice && isDatesCompleted)"
              class="tw-font-medium"
              v-text="$t('new-shipment.titles.quotation')"
              data-test="label"
            />
            <div
              class="tw-flex tw-flex-col"
              v-else
            >
              <div
                v-text="$t('new-shipment.titles.quotation_summary_price_type')"
                class="fs-13 tw-text-gray-700"
                data-test="price-label"
              />
              <div
                v-text="offers[getQuotationSelectedPrice.type]"
                class="tw-text-sm tw-text-secondary tw-font-medium"
                data-test="price-type"
              />
            </div>

            <price-total
              v-if="getQuotation && getQuotationSelectedPrice && isDatesCompleted"
              :price-included="`${$t('price_incl_tax', {
                price: $options.filters.currency(getQuotationSelectedPrice.total_including_vat, getQuotationSelectedPrice.currency, $i18n.locale)
              })}${priceSurge ? '*' : ''}`"
              :price-excluded="`${$t('price_excl_tax', {
                price: $options.filters.currency(getQuotationSelectedPrice.total, getQuotationSelectedPrice.currency, $i18n.locale)
              })}${priceSurge ? '*' : ''}`"
              data-test="total"
            />
          </div>
        </new-shipment-sidebar-group-item>
      </new-shipment-sidebar-group>
      <p
        v-if="priceSurge && $route.name === 'NewShipmentInformations'"
        data-test="price-surge-paragraph"
        class="tw-text-blue-500 fs-13 tw-italic tw-mb-8 tw-mt-2"
        v-text="`* ${$t(priceSurge === 'emergency'
          ? 'new-shipment.paragraphs.quotation.price_surge.emergency'
          : 'new-shipment.paragraphs.quotation.price_surge')}`"
      />

      <new-shipment-sidebar-group
        class="tw-hidden md:tw-flex"
        data-test="informations"
      >
        <new-shipment-sidebar-group-item
          :name="$t('new-shipment.titles.informations')"
          :to="{
            name: 'NewShipmentInformations'
          }"
          :disabled="!(isQuotationCompleted)"
        >
          <template #icon>
            <img
              src="@/assets/img/icons/quotation/list.svg"
              alt=""
              width="16"
              class="tw-ml-2"
              data-test="icon"
            >
          </template>
        </new-shipment-sidebar-group-item>
      </new-shipment-sidebar-group>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import { offers } from '@/../config/chronoinfos'
  import NewShipmentSidebarGroup from './_subs/NewShipmentSidebarGroup/index.vue'
  import NewShipmentSidebarGroupItem from './_subs/NewShipmentSidebarGroup/_subs/NewShipmentSidebarGroupItem/index.vue'
  import NewShipmentSidebarGroupItemAddress from './_subs/NewShipmentSidebarGroup/_subs/NewShipmentSidebarGroupItem/_subs/NewShipmentSidebarGroupItemAddress/index.vue'
  import NewShipmentSidebarGroupItemDate from './_subs/NewShipmentSidebarGroup/_subs/NewShipmentSidebarGroupItem/_subs/NewShipmentSidebarGroupItemDate/index.vue'
  import NewShipmentSidebarGroupItemHandling from './_subs/NewShipmentSidebarGroup/_subs/NewShipmentSidebarGroupItem/_subs/NewShipmentSidebarGroupItemHandling/index.vue'
  import ShipmentLoadSummary from '@/views/Shippers/components/ShipmentLoadSummary/index.vue'
  import PriceTotal from '@/components/CtkPricing/_subs/PriceTotal/index.vue'

  /**
   * @module component - NewShipmentSidebar
   */
  export default defineComponent({
    name: 'NewShipmentSidebar',
    components: {
      NewShipmentSidebarGroup,
      NewShipmentSidebarGroupItem,
      NewShipmentSidebarGroupItemAddress,
      NewShipmentSidebarGroupItemDate,
      NewShipmentSidebarGroupItemHandling,
      ShipmentLoadSummary,
      PriceTotal
    },
    mounted () {
      this.updateArrowPosition()
    },
    watch: {
      $route: function () {
        this.updateArrowPosition()
      }
    },
    data () {
      return {
        offers
      }
    },
    computed: {
      ...mapGetters('shipments/new-shipment', [
        'getPickupAddress',
        'getDeliveryAddress',
        'getPickupDate',
        'getDeliveryDate',
        'getPickupTimeslot',
        'getDeliveryTimeslot',
        'getPickupHandlingDriver',
        'getDeliveryHandlingDriver',
        'getPickupHandlingTailLift',
        'getDeliveryHandlingTailLift',
        'getLoad',
        'isPickupFlexible',
        'isDeliveryFlexible',
        'isAddressesCompleted',
        'isDatesCompleted',
        'isHandlingsCompleted',
        'isGoodsCompleted',
        'isQuotationCompleted',
        'getQuotation',
        'getQuotationSelectedPrice',
        'getGuard'
      ]),
      /**
       * @function getAddress
       * @returns {string} address
       */
      getAddress () {
        return direction => direction === 'pickup'
          ? this.getPickupAddress
          : this.getDeliveryAddress
      },
      /**
       * @function getDate
       * @returns {string} date
       */
      getDate () {
        return direction => direction === 'pickup'
          ? this.getPickupDate
          : this.getDeliveryDate
      },
      /**
       * @function getTimeslot
       * @returns {string} date
       */
      getTimeslot () {
        return direction => direction === 'pickup'
          ? this.getPickupTimeslot
          : this.getDeliveryTimeslot
      },
      /**
       * @function getHandling
       * @returns {object} handling
       */
      getHandling () {
        return (direction) => {
          const driver = direction === 'pickup'
            ? this.getPickupHandlingDriver
            : this.getDeliveryHandlingDriver

          const tailLift = direction === 'pickup'
            ? this.getPickupHandlingTailLift
            : this.getDeliveryHandlingTailLift

          return {
            driver,
            tailLift
          }
        }
      },
      /**
       * @function isFlexible
       * @returns {boolean} flexible
       */
      isFlexible () {
        return direction => direction === 'pickup'
          ? this.isPickupFlexible
          : this.isDeliveryFlexible
      },
      /**
       * @function isPickupAddressCompleted
       * @returns {boolean} completed
       */
      isPickupAddressCompleted () {
        return this.getGuard.pickup_address
      },
      /**
       * Returns the price surge associated with the selected quotation price
       * @function priceSurge
       * @returns {boolean}
       */
      priceSurge () {
        const price = this.getQuotationSelectedPrice
        return price && price.price_surge
      }
    },
    methods: {
      updateArrowPosition () {
        this.$nextTick(() => {
          const activeLink = document.querySelector('.new-shipment-sidebar:not(.new-shipment-sidebar__embedded) .new-shipment-sidebar__navigation-groups .router-link-active')
          if (activeLink) {
            const { top, height } = activeLink.getBoundingClientRect()
            this.$emit('update-arrow', {
              top,
              height
            })
          } else {
            this.$emit('update-arrow', {
              top: 16,
              height: 10
            })
          }
        })
      }
    }
  })
</script>

<style lang="scss" scoped>

  .new-shipment-sidebar {
    position: relative;
    padding: 30px 40px;
    padding-bottom: 180px;

    &__title {
      position: relative;
      font-size: 20px;

      &::after {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 220px;
        height: 1px;
        background-color: $divider;
      }
    }

    &__navigation-groups {
      z-index: 1;

      .new-shipment-sidebar-group {
        .ctk-font {
          font-size: 25px;
          margin-left: 5px;
        }
      }
    }

    &__embedded {
      .new-shipment-sidebar__quotation-price {
        display: none;
      }
    }

    &:not(.new-shipment-sidebar__embedded)::after {
      position: absolute;
      content: '';
      background-image: url('~@/assets/img/illustrations/form-sidebar.svg');
      background-size: cover;
      background-position: bottom;
      background-repeat: no-repeat;
      width: 100%;
      bottom: 32px;
      left: 0;
      height: 180px;
      pointer-events: none;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      padding: 24px 16px;
      padding-bottom: 180px;

      &__embedded {
        padding: 0;
      }
    }
  }

</style>
