<template>
  <ui-fat-radio-group
    v-model="selectedType"
    :items="items"
    class="new-shipment-dates-types 2sm:tw-flex tw-flex-1"
  >
    <template slot-scope="{ item, active, keydown }">
      <new-shipment-types-item
        :title="item.title"
        :active="active"
        :data-test="item.value"
        class="tw-w-full tw-mb-4 2sm:tw-mb-0 2sm:tw-w-1/2"
        v-matomo="{
          click: {
            category: 'Quotations',
            action: item.value === 'tailored'
              ? 'Date Step Choose Tailored'
              : 'Date Step Choose Optimal'
          }
        }"
        @keydown.native="keydown"
        @click.native="selectedType = item.value"
      >
        <div
          class="new-shipment-dates-types__title tw-font-bold tw-text-sm"
          v-text="item.title"
          data-test="title"
        />
        <div
          class="new-shipment-dates-types__subtitle tw-text-xs"
          v-text="item.subtitle"
          data-test="subtitle"
        />
      </new-shipment-types-item>
    </template>
  </ui-fat-radio-group>
</template>

<script>
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  import UiFatRadioGroup from '@/components/UI/FatRadioGroup/index.vue'
  import NewShipmentTypesItem from '@/views/Shippers/NewShipment/components/NewShipmentTypesItem/index.vue'

  /**
   * @module component - NewShipmentDateTypes
   */
  export default {
    name: 'NewShipmentDateTypes',
    components: {
      UiFatRadioGroup,
      NewShipmentTypesItem
    },
    props: {
      value: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        items: [
          {
            title: this.$t('new-shipment.titles.dates.tailored'),
            subtitle: this.$t('new-shipment.paragraphs.dates.tailored'),
            value: 'tailored'
          },
          {
            title: this.$t('new-shipment.titles.dates.optimal'),
            subtitle: this.$t('new-shipment.paragraphs.dates.optimal'),
            value: 'optimal'
          }
        ]
      }
    },
    setup (props) {
      const { state: selectedType } = useModelGetterSetter(props, 'value')

      return {
        selectedType
      }
    }
  }
</script>

<style lang="scss" scoped>

  .new-shipment-dates-types {
    .new-shipment-types-item:first-child {
      margin-right: 1rem;
    }

    .new-shipment-types-item:not(.active) .new-shipment-dates-types__title {
      color: $info;
    }
  }

</style>
